import styled from "styled-components";

export const ApplicationCompletionOverlayContainer = styled.div`
  position: fixed;
  inset: 0;
  pointer-events: none;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 500;

  * {
    pointer-events: auto;
  }
`;

export const ApplicationCompletionOverlayCard = styled.div`
  cursor: default;
  user-select: none;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 1.5rem;
  width: 32rem;
  min-width: 32rem;
  max-width: 32rem;
  height: max-content;

  border: 1.5px solid #dfdcef;
  border-radius: 1rem;
  background-color: #ffffff;
  overflow: hidden;
`;
