export const UPLOAD_TYPES = {
  APPLICATION: "APPLICATION",
  BANNER: "BANNER",
  MARKET: "MARKET",
  MERCHANT: "MERCHANT",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  PRODUCT: "PRODUCT",
  PURCHASE: "PURCHASE",
  RESTRICTION_NOTICE: "RESTRICTION_NOTICE",
};
