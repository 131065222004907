import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setFetchFeatureSystemsFailed,
  setFetchFeatureSystemFailed,
  setUpdateFeatureSystemFailed,
  setUpdateFeatureSystemSuccess,
} from "../../store/feature-system/feature-system.action";
import {
  getFetchFeatureSystemsFailed,
  getFetchFeatureSystemFailed,
  getUpdateFeatureSystemFailed,
  getUpdateFeatureSystemSuccess,
} from "../../store/feature-system/feature-system.selector";

const FeatureSystemPopup = () => {
  const dispatch = useDispatch();

  const updateFeatureSystemSuccess = useSelector(getUpdateFeatureSystemSuccess);

  const fetchFeatureSystemsFailed = useSelector(getFetchFeatureSystemsFailed);
  const fetchFeatureSystemFailed = useSelector(getFetchFeatureSystemFailed);
  const updateFeatureSystemFailed = useSelector(getUpdateFeatureSystemFailed);

  useEffect(() => {
    if (updateFeatureSystemSuccess !== null) {
      showSuccessMessage(updateFeatureSystemSuccess);
      dispatch(setIsModalOpen(false));
      dispatch(setUpdateFeatureSystemSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFeatureSystemSuccess]);

  useEffect(() => {
    if (
      fetchFeatureSystemsFailed !== null ||
      fetchFeatureSystemFailed !== null ||
      updateFeatureSystemFailed !== null
    ) {
      showErrorMessage(
        fetchFeatureSystemsFailed ??
          fetchFeatureSystemFailed ??
          updateFeatureSystemFailed
      );

      if (fetchFeatureSystemsFailed !== null)
        dispatch(setFetchFeatureSystemsFailed(null));
      if (fetchFeatureSystemFailed !== null)
        dispatch(setFetchFeatureSystemFailed(null));
      if (updateFeatureSystemFailed !== null)
        dispatch(setUpdateFeatureSystemFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchFeatureSystemsFailed,
    fetchFeatureSystemFailed,
    updateFeatureSystemFailed,
  ]);

  return <></>;
};

export default FeatureSystemPopup;
