import { useDispatch } from "react-redux";

import { clearStorageKeys } from "../utils/storage.utils";

import { resetAdvertisementReducer } from "../store/advertisement/advertisement.action";
import { resetAnnouncementReducer } from "../store/announcement/announcement.action";
import { resetAppReducer } from "../store/application/application.action";
import { resetAttendanceReducer } from "../store/attendance/attendance.action";
import { resetAuthReducer } from "../store/authentication/authentication.action";
import { resetBannerReducer } from "../store/banner/banner.action";
import { resetBannerTargetReducer } from "../store/banner-target/banner-target.action";
import { resetBranchReducer } from "../store/branch/branch.action";
import { resetCardReducer } from "../store/card/card.action";
import { resetComponentReducer } from "../store/component/component.action";
import { resetCountryReducer } from "../store/country/country.action";
import { resetCustomerReducer } from "../store/customer/customer.action";
import { resetDashboardReducer } from "../store/dashboard/dashboard.action";
import { resetDiscountReducer } from "../store/discount/discount.action";
import { resetEventReducer } from "../store/event/event.action";
import { resetExportReducer } from "../store/export/export.action";
import { resetExtendPeriodReducer } from "../store/extend-period/extend-period.action";
import { resetFeatureSubscriptionReducer } from "../store/feature-subscription/feature-subscription.action";
import { resetFeatureSystemReducer } from "../store/feature-system/feature-system.action";
import { resetFormCustomerReducer } from "../store/form-customer/form-customer.action";
import { resetFormOrderReducer } from "../store/form-order/form-order.action";
import { resetGatewayReducer } from "../store/gateway/gateway.action";
import { resetBranchReducer as resetHeaderBranchReducer } from "../store/header-branch/header-branch.action";
import { resetIngredientReducer } from "../store/ingredient/ingredient.action";
import { resetIngredientHistoryReducer } from "../store/ingredient-history/ingredient-history.action";
import { resetIntegrationReducer } from "../store/integration/integration.action";
import { resetKitchenGroupReducer } from "../store/kitchen-group/kitchen-group.action";
import { resetMarketReducer } from "../store/market/market.action";
import { resetMerchantReducer } from "../store/merchant/merchant.action";
import { resetMsegatReducer } from "../store/msegat/msegat.action";
import { resetOrderReducer } from "../store/order/order.action";
import { resetOrderProductReducer } from "../store/order-product/order-product.action";
import { resetPaymentDeviceReducer } from "../store/payment-device/payment-device.action";
import { resetPaymentMethodReducer } from "../store/payment-method/payment-method.action";
import { resetPaymentMethodSettingReducer } from "../store/payment-method-setting/payment-method-setting.action";
import { resetPermissionReducer } from "../store/permission/permission.action";
import { resetPermissionAdminReducer } from "../store/permission-admin/permission-admin.action";
import { resetPermissionMerchantReducer } from "../store/permission-merchant/permission-merchant.action";
import { resetPermissionSubscriberReducer } from "../store/permission-subscriber/permission-subscriber.action";
import { resetPrinterReducer } from "../store/printer/printer.action";
import { resetPrinterTypeReducer } from "../store/printer-type/printer-type.action";
import { resetProductCategoryReducer } from "../store/product-category/product-category.action";
import { resetProductCategorySortReducer } from "../store/product-category-sort/product-category-sort.action";
import { resetProductModifierReducer } from "../store/product-modifier/product-modifier.action";
import { resetProductReducer } from "../store/product/product.action";
import { resetRecipeReducer } from "../store/recipe/recipe.action";
import { resetRenewalAdminReducer } from "../store/renewal-admin/renewal-admin.action";
import { resetReportReducer } from "../store/report/report.action";
import { resetReportAttendanceReducer } from "../store/report-attendance/report-attendance.action";
import { resetRestockRequestReducer } from "../store/restock-request/restock-request.action";
import { resetRestrictionNoticeReducer } from "../store/restriction-notice/restriction-notice.action";
import { resetScheduleReducer } from "../store/schedule/schedule.action";
import { resetSectorReducer } from "../store/sector/sector.action";
import { resetBranchReducer as resetSelectBranchReducer } from "../store/select-branch/select-branch.action";
import { resetCashierReducer as resetSelectCashierReducer } from "../store/select-cashier/select-cashier.action";
import { resetCountryReducer as resetSelectCountryReducer } from "../store/select-country/select-country.action";
import { resetCustomerReducer as resetSelectCustomerReducer } from "../store/select-customer/select-customer.action";
import { resetFeatureSystemReducer as resetSelectFeatureSystemReducer } from "../store/select-feature-system/select-feature-system.action";
import { resetIngredientReducer as resetSelectIngredientReducer } from "../store/select-ingredient/select-ingredient.action";
import { resetMarketReducer as resetSelectMarketReducer } from "../store/select-market/select-market.action";
import { resetPaymentMethodReducer as resetSelectPaymentMethodReducer } from "../store/select-payment-method/select-payment-method.action";
import { resetPermissionAdminReducer as resetSelectPermissionAdminReducer } from "../store/select-permission-admin/select-permission-admin.action";
import { resetPermissionMerchantReducer as resetSelectPermissionMerchantReducer } from "../store/select-permission-merchant/select-permission-merchant.action";
import { resetPermissionSubscriberReducer as resetSelectPermissionSubscriberReducer } from "../store/select-permission-subscriber/select-permission-subscriber.action";
import { resetProductReducer as resetSelectProductReducer } from "../store/select-product/select-product.action";
import { resetProductCategoryReducer as resetSelectProductCategoryReducer } from "../store/select-product-category/select-product-category.action";
import { resetSubscriberReducer as resetSelectSubscriberReducer } from "../store/select-subscriber/select-subscriber.action";
import { resetSettingReducer } from "../store/setting/setting.action";
import { resetStateSubscriptionReducer } from "../store/state-subscription/state-subscription.action";
import { resetSummaryReducer } from "../store/summary/summary.action";
import { resetTableReducer } from "../store/table/table.action";
import { resetTaxReducer } from "../store/tax/tax.action";
import { resetTermConditionReducer } from "../store/term-condition/term-condition.action";
import { resetTopUpReducer } from "../store/top-up/top-up.action";
import { resetUserAdminReducer } from "../store/user-admin/user-admin.action";
import { resetUserCustomerReducer } from "../store/user-customer/user-customer.action";
import { resetUserDriverReducer } from "../store/user-driver/user-driver.action";
import { resetUserMerchantReducer } from "../store/user-merchant/user-merchant.action";
import { resetUserSubscriberReducer } from "../store/user-subscriber/user-subscriber.action";
import { resetUserSupplierReducer } from "../store/user-supplier/user-supplier.action";
import { resetVerificationReducer } from "../store/verification/verification.action";
import { resetWathqReducer } from "../store/wathq/wathq.action";
import { resetWhatsappReducer } from "../store/whatsapp/whatsapp.action";

export const useResetReducer = () => {
  const dispatch = useDispatch();

  const handleResetReducer = () => {
    dispatch(resetAdvertisementReducer());
    dispatch(resetAnnouncementReducer());
    dispatch(resetAppReducer());
    dispatch(resetAttendanceReducer());
    dispatch(resetAuthReducer());
    dispatch(resetBannerReducer());
    dispatch(resetBannerTargetReducer());
    dispatch(resetBranchReducer());
    dispatch(resetCardReducer());
    dispatch(resetComponentReducer());
    dispatch(resetCountryReducer());
    dispatch(resetCustomerReducer());
    dispatch(resetDashboardReducer());
    dispatch(resetDiscountReducer());
    dispatch(resetEventReducer());
    dispatch(resetExportReducer());
    dispatch(resetExtendPeriodReducer());
    dispatch(resetFeatureSubscriptionReducer());
    dispatch(resetFeatureSystemReducer());
    dispatch(resetFormCustomerReducer());
    dispatch(resetFormOrderReducer());
    dispatch(resetGatewayReducer());
    dispatch(resetHeaderBranchReducer());
    dispatch(resetIngredientReducer());
    dispatch(resetIngredientHistoryReducer());
    dispatch(resetIntegrationReducer());
    dispatch(resetKitchenGroupReducer());
    dispatch(resetMarketReducer());
    dispatch(resetMerchantReducer());
    dispatch(resetMsegatReducer());
    dispatch(resetOrderReducer());
    dispatch(resetOrderProductReducer());
    dispatch(resetPaymentDeviceReducer());
    dispatch(resetPaymentMethodReducer());
    dispatch(resetPaymentMethodSettingReducer());
    dispatch(resetPermissionReducer());
    dispatch(resetPermissionAdminReducer());
    dispatch(resetPermissionMerchantReducer());
    dispatch(resetPermissionSubscriberReducer());
    dispatch(resetPrinterReducer());
    dispatch(resetPrinterTypeReducer());
    dispatch(resetProductCategoryReducer());
    dispatch(resetProductCategorySortReducer());
    dispatch(resetProductModifierReducer());
    dispatch(resetProductReducer());
    dispatch(resetRecipeReducer());
    dispatch(resetRecipeReducer());
    dispatch(resetRenewalAdminReducer());
    dispatch(resetReportAttendanceReducer());
    dispatch(resetRestockRequestReducer());
    dispatch(resetRestrictionNoticeReducer());
    dispatch(resetScheduleReducer());
    dispatch(resetSectorReducer());
    dispatch(resetSelectBranchReducer());
    dispatch(resetSelectCashierReducer());
    dispatch(resetSelectCountryReducer());
    dispatch(resetSelectCustomerReducer());
    dispatch(resetSelectFeatureSystemReducer());
    dispatch(resetSelectIngredientReducer());
    dispatch(resetSelectMarketReducer());
    dispatch(resetSelectPaymentMethodReducer());
    dispatch(resetSelectPermissionAdminReducer());
    dispatch(resetSelectPermissionMerchantReducer());
    dispatch(resetSelectPermissionSubscriberReducer());
    dispatch(resetSelectProductReducer());
    dispatch(resetSelectProductCategoryReducer());
    dispatch(resetSelectSubscriberReducer());
    dispatch(resetSettingReducer());
    dispatch(resetStateSubscriptionReducer());
    dispatch(resetSummaryReducer());
    dispatch(resetTableReducer());
    dispatch(resetTaxReducer());
    dispatch(resetTermConditionReducer());
    dispatch(resetTopUpReducer());
    dispatch(resetUserAdminReducer());
    dispatch(resetUserCustomerReducer());
    dispatch(resetUserDriverReducer());
    dispatch(resetUserMerchantReducer());
    dispatch(resetUserSubscriberReducer());
    dispatch(resetUserSupplierReducer());
    dispatch(resetVerificationReducer());
    dispatch(resetWathqReducer());
    dispatch(resetWhatsappReducer());

    clearStorageKeys();
  };

  return handleResetReducer;
};
