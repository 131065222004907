const FEATURE_SYSTEM_ACTION_TYPES = {
  RESET_FEATURE_SYSTEM_REDUCER: "feature-system/RESET_FEATURE_SYSTEM_REDUCER",

  SET_FEATURE_SYSTEMS: "feature-system/SET_FEATURE_SYSTEMS",
  SET_FEATURE_SYSTEM: "feature-system/SET_FEATURE_SYSTEM",

  SET_IS_FEATURE_SYSTEMS_HAS_MORE:
    "feature-system/SET_IS_FEATURE_SYSTEMS_HAS_MORE",

  SET_FETCH_FEATURE_SYSTEMS_SEARCH:
    "feature-system/SET_FETCH_FEATURE_SYSTEMS_SEARCH",
  SET_FETCH_FEATURE_SYSTEMS_SORT:
    "feature-system/SET_FETCH_FEATURE_SYSTEMS_SORT",
  SET_FETCH_FEATURE_SYSTEMS_KEY_BY:
    "feature-system/SET_FETCH_FEATURE_SYSTEMS_KEY_BY",
  SET_FETCH_FEATURE_SYSTEMS_PAGE:
    "feature-system/SET_FETCH_FEATURE_SYSTEMS_PAGE",
  SET_FETCH_FEATURE_SYSTEMS_PER_PAGE:
    "feature-system/SET_FETCH_FEATURE_SYSTEMS_PER_PAGE",
  SET_FETCH_FEATURE_SYSTEMS_INCLUDES:
    "feature-system/SET_FETCH_FEATURE_SYSTEMS_INCLUDES",
  SET_FETCH_FEATURE_SYSTEMS_LOADING:
    "feature-system/SET_FETCH_FEATURE_SYSTEMS_LOADING",
  SET_FETCH_FEATURE_SYSTEMS_SUCCESS:
    "feature-system/SET_FETCH_FEATURE_SYSTEMS_SUCCESS",
  SET_FETCH_FEATURE_SYSTEMS_FAILED:
    "feature-system/SET_FETCH_FEATURE_SYSTEMS_FAILED",

  SET_FETCH_FEATURE_SYSTEM_LOADING:
    "feature-system/SET_FETCH_FEATURE_SYSTEM_LOADING",
  SET_FETCH_FEATURE_SYSTEM_SUCCESS:
    "feature-system/SET_FETCH_FEATURE_SYSTEM_SUCCESS",
  SET_FETCH_FEATURE_SYSTEM_FAILED:
    "feature-system/SET_FETCH_FEATURE_SYSTEM_FAILED",

  SET_UPDATE_FEATURE_SYSTEM_LOADING:
    "feature-system/SET_UPDATE_FEATURE_SYSTEM_LOADING",
  SET_UPDATE_FEATURE_SYSTEM_SUCCESS:
    "feature-system/SET_UPDATE_FEATURE_SYSTEM_SUCCESS",
  SET_UPDATE_FEATURE_SYSTEM_FAILED:
    "feature-system/SET_UPDATE_FEATURE_SYSTEM_FAILED",

  APPEND_FEATURE_SYSTEMS: "feature-system/APPEND_FEATURE_SYSTEMS",

  SET_IS_FETCH_FEATURE_SYSTEMS_HITTED:
    "feature-system/SET_IS_FETCH_FEATURE_SYSTEMS_HITTED",
  SET_IS_FETCH_FEATURE_SYSTEM_HITTED:
    "feature-system/SET_IS_FETCH_FEATURE_SYSTEM_HITTED",
  SET_IS_UPDATE_FEATURE_SYSTEM_HITTED:
    "feature-system/SET_IS_UPDATE_FEATURE_SYSTEM_HITTED",

  FETCH_FEATURE_SYSTEMS_START: "feature-system/FETCH_FEATURE_SYSTEMS_START",
  FETCH_FEATURE_SYSTEM_START: "feature-system/FETCH_FEATURE_SYSTEM_START",
  UPDATE_FEATURE_SYSTEM_START: "feature-system/UPDATE_FEATURE_SYSTEM_START",
};

export default FEATURE_SYSTEM_ACTION_TYPES;
