import apiService from "./api";

export const getFeatures = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/features/subscription", params }))
    .data;
export const getFeature = async (featureId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/features/subscription/${featureId}`,
    })
  ).data;
export const createFeature = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/features/subscription",
      request,
    })
  ).data;
export const updateFeature = async (featureId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/features/subscription/${featureId}`,
      request,
    })
  ).data;
export const deleteFeature = async (featureId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/features/subscription/${featureId}`,
    })
  ).data;
