import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { parseToBoolean } from "../../utils/parser.utils";
import { getPageDetails } from "../../utils/permission-subscriber.utils";
import { clearIntegrationCompletionType } from "../../utils/storage.utils";
import { isEmpty } from "../../utils/validation.utils";

import { getFaiCompletionInfo } from "../../constants/integration-completion.constant";
import { PERMISSION_SUBSCRIBER_PAGES } from "../../constants/permission-subscriber.constant";

import {
  getCurrentMarketId,
  getIsMarketAdministrator,
  getIsRightToLeft,
  getMasterPermitPages,
  getSecondPermissionId,
  getSecondPermitPages,
} from "../../store/global/global.selector";
import {
  setCurrentModal,
  setIsModalOpen,
} from "../../store/component/component.action";

import Shimmer, {
  SHIMMER_RADIUS,
} from "../../components/shimmer/shimmer.component";
import { modalName as faiSettingsModal } from "../application-fai-settings/application-fai-settings.widget";

import {
  ApplicationCompletionOverlayItemContainer,
  ApplicationCompletionOverlayItemIcon,
  ApplicationCompletionOverlayItemInfo,
  ApplicationCompletionOverlayItemLabel,
  ApplicationCompletionOverlayItemLink,
  ApplicationCompletionOverlayListContainer,
} from "./application-completion-overlay-list.style";

import { LinkSquare02Icon } from "hugeicons-react";
import { ReactComponent as ConfirmSvg } from "../../assets/icons/stroke/Confirm.svg";

export const ApplicationCompletionOverlayItemLoading = () => {
  return (
    <ApplicationCompletionOverlayItemContainer>
      <Shimmer
        radius={SHIMMER_RADIUS.CIRCLE}
        width="1.5rem"
        minWidth="1.5rem"
        maxWidth="1.5rem"
        height="1.5rem"
        minHeight="1.5rem"
        maxHeight="1.5rem"
        marginRight="0.25rem"
      />
      <Shimmer
        radius={SHIMMER_RADIUS.PX12}
        width="164px"
        maxWidth="100%"
        height="18px"
      />
      <Shimmer
        radius={SHIMMER_RADIUS.PX12}
        width="84px"
        minWidth="84px"
        maxWidth="84px"
        height="34px"
      />
      <Shimmer
        radius={SHIMMER_RADIUS.PX8}
        width="1.75rem"
        minWidth="1.75rem"
        maxWidth="1.75rem"
        height="1.75rem"
        minHeight="1.75rem"
        maxHeight="1.75rem"
        marginLeft="auto"
      />
    </ApplicationCompletionOverlayItemContainer>
  );
};

export const ApplicationCompletionOverlayItemData = ({ completion }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const isMarketAdministrator = useSelector(getIsMarketAdministrator);
  const secondPermissionId = useSelector(getSecondPermissionId);
  const masterPermitPages = useSelector(getMasterPermitPages);
  const secondPermitPages = useSelector(getSecondPermitPages);
  const currentMarketId = useSelector(getCurrentMarketId);

  const [pageUrlPath, setPageUrlPath] = useState(null);

  const { status_key, is_configured, is_required } = completion ?? {};
  const { permission_key, label } = getFaiCompletionInfo(status_key) ?? {};
  const pageDetails = getPageDetails(permission_key);

  const handleApplicationModal = () => {
    if (permission_key !== PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION) return;

    dispatch(setCurrentModal(faiSettingsModal));
    dispatch(setIsModalOpen(true));

    clearIntegrationCompletionType();
  };

  useEffect(() => {
    let pageUrlPath = null;

    if (isMarketAdministrator) {
      pageUrlPath =
        isEmpty(secondPermissionId) ||
        secondPermitPages.includes(permission_key)
          ? `/subscriber/${currentMarketId}${pageDetails.route}`
          : null;
    } else {
      pageUrlPath = masterPermitPages.includes(permission_key)
        ? pageDetails.route
        : null;
    }

    setPageUrlPath(pageUrlPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMarketAdministrator,
    secondPermissionId,
    masterPermitPages,
    secondPermitPages,
    currentMarketId,
  ]);

  return (
    <ApplicationCompletionOverlayItemContainer>
      <ApplicationCompletionOverlayItemIcon
        isActive={parseToBoolean(is_configured)}
      >
        <ConfirmSvg />
      </ApplicationCompletionOverlayItemIcon>
      <ApplicationCompletionOverlayItemInfo>
        {!isEmpty(label) && i18n.exists(label) ? label : t("No information")}
      </ApplicationCompletionOverlayItemInfo>
      <ApplicationCompletionOverlayItemLabel
        isRequired={parseToBoolean(is_required)}
      >
        {parseToBoolean(is_required) ? t("Required") : t("Optional")}
      </ApplicationCompletionOverlayItemLabel>
      {!parseToBoolean(is_configured) && !isEmpty(pageUrlPath) && (
        <ApplicationCompletionOverlayItemLink
          to={pageUrlPath}
          onClick={handleApplicationModal}
        >
          <LinkSquare02Icon />
        </ApplicationCompletionOverlayItemLink>
      )}
    </ApplicationCompletionOverlayItemContainer>
  );
};

const ApplicationCompletionOverlayList = ({
  completions = [],
  isLoading = false,
}) => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  return (
    <ApplicationCompletionOverlayListContainer isRightToLeft={isRightToLeft}>
      {isLoading
        ? Array.from(Array(4).keys()).map((index) => (
            <ApplicationCompletionOverlayItemLoading key={index} />
          ))
        : completions.map((completion, index) => (
            <ApplicationCompletionOverlayItemData
              key={index}
              completion={completion}
            />
          ))}
    </ApplicationCompletionOverlayListContainer>
  );
};

export default ApplicationCompletionOverlayList;
