import INTEGRATION_ACTION_TYPES from "./integration.type";
import { createAction } from "../../utils/store.utils";

export const setIsModalAutoClose = (isModalAutoClose) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_MODAL_AUTO_CLOSE,
    isModalAutoClose
  );

export const setIntegrations = (integrations) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_INTEGRATIONS, integrations);
export const setIntegration = (integration) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_INTEGRATION, integration);
export const setQoyodStatus = (qoyodStatus) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_QOYOD_STATUS, qoyodStatus);
export const setQoyodSync = (qoyodSync) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_QOYOD_SYNC, qoyodSync);
export const setGrubtechStatus = (grubtechStatus) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_GRUBTECH_STATUS, grubtechStatus);
export const setGrubtechSync = (grubtechSync) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_GRUBTECH_SYNC, grubtechSync);
export const setBallurhStatus = (ballurhStatus) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_BALLURH_STATUS, ballurhStatus);
export const setBallurhGenerate = (ballurhGenerate) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_BALLURH_GENERATE, ballurhGenerate);
export const setBallurhSync = (ballurhSync) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_BALLURH_SYNC, ballurhSync);
export const setZatcaSetup = (zatcaSetup) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_ZATCA_SETUP, zatcaSetup);
export const setZatcaRegister = (zatcaRegister) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_ZATCA_REGISTER, zatcaRegister);
export const setZatcaStatus = (zatcaStatus) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_ZATCA_STATUS, zatcaStatus);
export const setZatcaSync = (zatcaSync) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_ZATCA_SYNC, zatcaSync);
export const setFaiRegister = (faiRegister) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_FAI_REGISTER, faiRegister);
export const setFaiRegisterStatus = (faiRegisterStatus) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FAI_REGISTER_STATUS,
    faiRegisterStatus
  );
export const setFaiStatus = (faiStatus) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_FAI_STATUS, faiStatus);
export const setFaiSync = (faiSync) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_FAI_SYNC, faiSync);

export const setIsIntegrationsHasMore = (isIntegrationsHasMore) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_INTEGRATIONS_HAS_MORE,
    isIntegrationsHasMore
  );

export const setFetchIntegrationsSort = (fetchIntegrationsSort) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_SORT,
    fetchIntegrationsSort
  );
export const setFetchIntegrationsKeyBy = (fetchIntegrationsKeyBy) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_KEY_BY,
    fetchIntegrationsKeyBy
  );
export const setFetchIntegrationsPage = (fetchIntegrationsPage) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_PAGE,
    fetchIntegrationsPage
  );
export const setFetchIntegrationsPerPage = (fetchIntegrationsPerPage) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_PER_PAGE,
    fetchIntegrationsPerPage
  );
export const setFetchIntegrationsIncludes = (fetchIntegrationsIncludes) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_INCLUDES,
    fetchIntegrationsIncludes
  );
export const setFetchIntegrationsFilterMarketId = (
  fetchIntegrationsFilterMarketId
) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_FILTER_MARKET_ID,
    fetchIntegrationsFilterMarketId
  );
export const setFetchIntegrationsFilterBranchId = (
  fetchIntegrationsFilterBranchId
) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_FILTER_BRANCH_ID,
    fetchIntegrationsFilterBranchId
  );
export const setFetchIntegrationsFilterKeys = (fetchIntegrationsFilterKeys) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_FILTER_KEYS,
    fetchIntegrationsFilterKeys
  );
export const setFetchIntegrationsLoading = (fetchIntegrationsLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_LOADING,
    fetchIntegrationsLoading
  );
export const setFetchIntegrationsSuccess = (fetchIntegrationsSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_SUCCESS,
    fetchIntegrationsSuccess
  );
export const setFetchIntegrationsFailed = (fetchIntegrationsFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATIONS_FAILED,
    fetchIntegrationsFailed
  );

export const setFetchIntegrationLoading = (fetchIntegrationLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATION_LOADING,
    fetchIntegrationLoading
  );
export const setFetchIntegrationSuccess = (fetchIntegrationSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATION_SUCCESS,
    fetchIntegrationSuccess
  );
export const setFetchIntegrationFailed = (fetchIntegrationFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_INTEGRATION_FAILED,
    fetchIntegrationFailed
  );

export const setCreateOrUpdateIntegrationLoading = (
  createOrUpdateIntegrationLoading
) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_CREATE_OR_UPDATE_INTEGRATION_LOADING,
    createOrUpdateIntegrationLoading
  );
export const setCreateOrUpdateIntegrationSuccess = (
  createOrUpdateIntegrationSuccess
) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_CREATE_OR_UPDATE_INTEGRATION_SUCCESS,
    createOrUpdateIntegrationSuccess
  );
export const setCreateOrUpdateIntegrationFailed = (
  createOrUpdateIntegrationFailed
) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_CREATE_OR_UPDATE_INTEGRATION_FAILED,
    createOrUpdateIntegrationFailed
  );

export const setFetchQoyodStatusLoading = (fetchQoyodStatusLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_QOYOD_STATUS_LOADING,
    fetchQoyodStatusLoading
  );
export const setFetchQoyodStatusSuccess = (fetchQoyodStatusSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_QOYOD_STATUS_SUCCESS,
    fetchQoyodStatusSuccess
  );
export const setFetchQoyodStatusFailed = (fetchQoyodStatusFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_QOYOD_STATUS_FAILED,
    fetchQoyodStatusFailed
  );

export const setSyncQoyodLoading = (syncQoyodLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_QOYOD_LOADING,
    syncQoyodLoading
  );
export const setSyncQoyodSuccess = (syncQoyodSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_QOYOD_SUCCESS,
    syncQoyodSuccess
  );
export const setSyncQoyodFailed = (syncQoyodFailed) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_SYNC_QOYOD_FAILED, syncQoyodFailed);

export const setFetchGrubtechStatusLoading = (fetchGrubtechStatusLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_GRUBTECH_STATUS_LOADING,
    fetchGrubtechStatusLoading
  );
export const setFetchGrubtechStatusSuccess = (fetchGrubtechStatusSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_GRUBTECH_STATUS_SUCCESS,
    fetchGrubtechStatusSuccess
  );
export const setFetchGrubtechStatusFailed = (fetchGrubtechStatusFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_GRUBTECH_STATUS_FAILED,
    fetchGrubtechStatusFailed
  );

export const setSyncGrubtechLoading = (syncGrubtechLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_GRUBTECH_LOADING,
    syncGrubtechLoading
  );
export const setSyncGrubtechSuccess = (syncGrubtechSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_GRUBTECH_SUCCESS,
    syncGrubtechSuccess
  );
export const setSyncGrubtechFailed = (syncGrubtechFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_GRUBTECH_FAILED,
    syncGrubtechFailed
  );

export const setFetchBallurhStatusLoading = (fetchBallurhStatusLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_BALLURH_STATUS_LOADING,
    fetchBallurhStatusLoading
  );
export const setFetchBallurhStatusSuccess = (fetchBallurhStatusSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_BALLURH_STATUS_SUCCESS,
    fetchBallurhStatusSuccess
  );
export const setFetchBallurhStatusFailed = (fetchBallurhStatusFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_BALLURH_STATUS_FAILED,
    fetchBallurhStatusFailed
  );

export const setGenerateBallurhLoading = (generateBallurhLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_GENERATE_BALLURH_LOADING,
    generateBallurhLoading
  );
export const setGenerateBallurhSuccess = (generateBallurhSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_GENERATE_BALLURH_SUCCESS,
    generateBallurhSuccess
  );
export const setGenerateBallurhFailed = (generateBallurhFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_GENERATE_BALLURH_FAILED,
    generateBallurhFailed
  );

export const setSyncBallurhLoading = (syncBallurhLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_BALLURH_LOADING,
    syncBallurhLoading
  );
export const setSyncBallurhSuccess = (syncBallurhSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_BALLURH_SUCCESS,
    syncBallurhSuccess
  );
export const setSyncBallurhFailed = (syncBallurhFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_BALLURH_FAILED,
    syncBallurhFailed
  );

export const setSetupZatcaLoading = (setupZatcaLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SETUP_ZATCA_LOADING,
    setupZatcaLoading
  );
export const setSetupZatcaSuccess = (setupZatcaSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SETUP_ZATCA_SUCCESS,
    setupZatcaSuccess
  );
export const setSetupZatcaFailed = (setupZatcaFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SETUP_ZATCA_FAILED,
    setupZatcaFailed
  );

export const setRegisterZatcaLoading = (registerZatcaLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_REGISTER_ZATCA_LOADING,
    registerZatcaLoading
  );
export const setRegisterZatcaSuccess = (registerZatcaSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_REGISTER_ZATCA_SUCCESS,
    registerZatcaSuccess
  );
export const setRegisterZatcaFailed = (registerZatcaFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_REGISTER_ZATCA_FAILED,
    registerZatcaFailed
  );

export const setFetchZatcaStatusLoading = (fetchZatcaStatusLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_ZATCA_STATUS_LOADING,
    fetchZatcaStatusLoading
  );
export const setFetchZatcaStatusSuccess = (fetchZatcaStatusSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_ZATCA_STATUS_SUCCESS,
    fetchZatcaStatusSuccess
  );
export const setFetchZatcaStatusFailed = (fetchZatcaStatusFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_ZATCA_STATUS_FAILED,
    fetchZatcaStatusFailed
  );

export const setSyncZatcaLoading = (syncZatcaLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_ZATCA_LOADING,
    syncZatcaLoading
  );
export const setSyncZatcaSuccess = (syncZatcaSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_SYNC_ZATCA_SUCCESS,
    syncZatcaSuccess
  );
export const setSyncZatcaFailed = (syncZatcaFailed) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_SYNC_ZATCA_FAILED, syncZatcaFailed);

export const setSetupFaiLoading = (setupFaiLoading) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_SETUP_FAI_LOADING, setupFaiLoading);
export const setSetupFaiSuccess = (setupFaiSuccess) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_SETUP_FAI_SUCCESS, setupFaiSuccess);
export const setSetupFaiFailed = (setupFaiFailed) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_SETUP_FAI_FAILED, setupFaiFailed);

export const setRegisterFaiLoading = (registerFaiLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_REGISTER_FAI_LOADING,
    registerFaiLoading
  );
export const setRegisterFaiSuccess = (registerFaiSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_REGISTER_FAI_SUCCESS,
    registerFaiSuccess
  );
export const setRegisterFaiFailed = (registerFaiFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_REGISTER_FAI_FAILED,
    registerFaiFailed
  );

export const setFetchRegisterFaiStatusLoading = (
  fetchRegisterFaiStatusLoading
) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_REGISTER_FAI_STATUS_LOADING,
    fetchRegisterFaiStatusLoading
  );
export const setFetchRegisterFaiStatusSuccess = (
  fetchRegisterFaiStatusSuccess
) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_REGISTER_FAI_STATUS_SUCCESS,
    fetchRegisterFaiStatusSuccess
  );
export const setFetchRegisterFaiStatusFailed = (fetchRegisterFaiStatusFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_REGISTER_FAI_STATUS_FAILED,
    fetchRegisterFaiStatusFailed
  );

export const setFetchFaiStatusLoading = (fetchFaiStatusLoading) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_FAI_STATUS_LOADING,
    fetchFaiStatusLoading
  );
export const setFetchFaiStatusSuccess = (fetchFaiStatusSuccess) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_FAI_STATUS_SUCCESS,
    fetchFaiStatusSuccess
  );
export const setFetchFaiStatusFailed = (fetchFaiStatusFailed) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_FETCH_FAI_STATUS_FAILED,
    fetchFaiStatusFailed
  );

export const setSyncFaiLoading = (syncFaiLoading) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_SYNC_FAI_LOADING, syncFaiLoading);
export const setSyncFaiSuccess = (syncFaiSuccess) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_SYNC_FAI_SUCCESS, syncFaiSuccess);
export const setSyncFaiFailed = (syncFaiFailed) =>
  createAction(INTEGRATION_ACTION_TYPES.SET_SYNC_FAI_FAILED, syncFaiFailed);

export const appendIntegrations = (integrations) =>
  createAction(INTEGRATION_ACTION_TYPES.APPEND_INTEGRATIONS, integrations);

export const fetchIntegrationsStart = () =>
  createAction(INTEGRATION_ACTION_TYPES.FETCH_INTEGRATIONS_START);
export const fetchIntegrationStart = (integrationId) =>
  createAction(INTEGRATION_ACTION_TYPES.FETCH_INTEGRATION_START, integrationId);
export const createOrUpdateIntegrationStart = (request) =>
  createAction(
    INTEGRATION_ACTION_TYPES.CREATE_OR_UPDATE_INTEGRATION_START,
    request
  );
export const fetchQoyodStatusStart = (parameters) =>
  createAction(INTEGRATION_ACTION_TYPES.FETCH_QOYOD_STATUS_START, parameters);
export const syncQoyodStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.SYNC_QOYOD_START, request);
export const fetchGrubtechStatusStart = (parameters) =>
  createAction(
    INTEGRATION_ACTION_TYPES.FETCH_GRUBTECH_STATUS_START,
    parameters
  );
export const syncGrubtechStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.SYNC_GRUBTECH_START, request);
export const fetchBallurhStatusStart = (parameters) =>
  createAction(INTEGRATION_ACTION_TYPES.FETCH_BALLURH_STATUS_START, parameters);
export const generateBallurhStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.GENERATE_BALLURH_START, request);
export const syncBallurhStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.SYNC_BALLURH_START, request);
export const setupZatcaStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.SETUP_ZATCA_START, request);
export const registerZatcaStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.REGISTER_ZATCA_START, request);
export const fetchZatcaStatusStart = (parameters) =>
  createAction(INTEGRATION_ACTION_TYPES.FETCH_ZATCA_STATUS_START, parameters);
export const syncZatcaStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.SYNC_ZATCA_START, request);
export const setupFaiStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.SETUP_FAI_START, request);
export const registerFaiStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.REGISTER_FAI_START, request);
export const fetchRegisterFaiStatusStart = (parameters) =>
  createAction(
    INTEGRATION_ACTION_TYPES.FETCH_REGISTER_FAI_STATUS_START,
    parameters
  );
export const fetchFaiStatusStart = (parameters) =>
  createAction(INTEGRATION_ACTION_TYPES.FETCH_FAI_STATUS_START, parameters);
export const syncFaiStart = (request) =>
  createAction(INTEGRATION_ACTION_TYPES.SYNC_FAI_START, request);

export const setIsFetchIntegrationsHitted = (isFetchIntegrationsHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_FETCH_INTEGRATIONS_HITTED,
    isFetchIntegrationsHitted
  );
export const setIsFetchIntegrationHitted = (isFetchIntegrationHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_FETCH_INTEGRATION_HITTED,
    isFetchIntegrationHitted
  );
export const setIsCreateOrUpdateIntegrationHitted = (
  isCreateOrUpdateIntegrationHitted
) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_CREATE_OR_UPDATE_INTEGRATION_HITTED,
    isCreateOrUpdateIntegrationHitted
  );
export const setIsFetchQoyodStatusHitted = (isFetchQoyodStatusHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_FETCH_QOYOD_STATUS_HITTED,
    isFetchQoyodStatusHitted
  );
export const setIsSyncQoyodHitted = (isSyncQoyodHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_SYNC_QOYOD_HITTED,
    isSyncQoyodHitted
  );
export const setIsFetchGrubtechStatusHitted = (isFetchGrubtechStatusHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_FETCH_GRUBTECH_STATUS_HITTED,
    isFetchGrubtechStatusHitted
  );
export const setIsSyncGrubtechHitted = (isSyncGrubtechHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_SYNC_GRUBTECH_HITTED,
    isSyncGrubtechHitted
  );
export const setIsFetchBallurhStatusHitted = (isFetchBallurhStatusHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_FETCH_BALLURH_STATUS_HITTED,
    isFetchBallurhStatusHitted
  );
export const setIsGenerateBallurhHitted = (isGenerateBallurhHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_GENERATE_BALLURH_HITTED,
    isGenerateBallurhHitted
  );
export const setIsSyncBallurhHitted = (isSyncBallurhHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_SYNC_BALLURH_HITTED,
    isSyncBallurhHitted
  );
export const setIsSetupZatcaHitted = (isSetupZatcaHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_SETUP_ZATCA_HITTED,
    isSetupZatcaHitted
  );
export const setIsRegisterZatcaHitted = (isRegisterZatcaHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_REGISTER_ZATCA_HITTED,
    isRegisterZatcaHitted
  );
export const setIsFetchZatcaStatusHitted = (isFetchZatcaStatusHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_FETCH_ZATCA_STATUS_HITTED,
    isFetchZatcaStatusHitted
  );
export const setIsSyncZatcaHitted = (isSyncZatcaHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_SYNC_ZATCA_HITTED,
    isSyncZatcaHitted
  );
export const setIsSetupFaiHitted = (isSetupFaiHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_SETUP_FAI_HITTED,
    isSetupFaiHitted
  );
export const setIsRegisterFaiHitted = (isRegisterFaiHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_REGISTER_FAI_HITTED,
    isRegisterFaiHitted
  );
export const setIsFetchRegisterFaiStatusHitted = (
  isFetchRegisterFaiStatusHitted
) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_FETCH_REGISTER_FAI_STATUS_HITTED,
    isFetchRegisterFaiStatusHitted
  );
export const setIsFetchFaiStatusHitted = (isFetchFaiStatusHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_FETCH_FAI_STATUS_HITTED,
    isFetchFaiStatusHitted
  );
export const setIsSyncFaiHitted = (isSyncFaiHitted) =>
  createAction(
    INTEGRATION_ACTION_TYPES.SET_IS_SYNC_FAI_HITTED,
    isSyncFaiHitted
  );

export const resetIntegrationReducer = () =>
  createAction(INTEGRATION_ACTION_TYPES.RESET_INTEGRATION_REDUCER);
