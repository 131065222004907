const SELECT_FEATURE_SYSTEM_ACTION_TYPES = {
  RESET_SELECT_FEATURE_SYSTEM_REDUCER:
    "select-feature-system/RESET_SELECT_FEATURE_SYSTEM_REDUCER",

  SET_FEATURE_SYSTEMS: "select-feature-system/SET_FEATURE_SYSTEMS",
  SET_SEARCH_FEATURE_SYSTEMS:
    "select-feature-system/SET_SEARCH_FEATURE_SYSTEMS",

  APPEND_FEATURE_SYSTEMS: "select-feature-system/APPEND_FEATURE_SYSTEMS",
  APPEND_SEARCH_FEATURE_SYSTEMS:
    "select-feature-system/APPEND_SEARCH_FEATURE_SYSTEMS",

  SET_IS_FEATURE_SYSTEMS_HAS_MORE:
    "select-feature-system/SET_IS_FEATURE_SYSTEMS_HAS_MORE",
  SET_IS_SEARCH_FEATURE_SYSTEMS_HAS_MORE:
    "select-feature-system/SET_IS_SEARCH_FEATURE_SYSTEMS_HAS_MORE",

  SET_FETCH_FEATURE_SYSTEMS_PARAMS:
    "select-feature-system/SET_FETCH_FEATURE_SYSTEMS_PARAMS",
  SET_FETCH_FEATURE_SYSTEMS_LOADING:
    "select-feature-system/SET_FETCH_FEATURE_SYSTEMS_LOADING",
  SET_FETCH_FEATURE_SYSTEMS_SUCCESS:
    "select-feature-system/SET_FETCH_FEATURE_SYSTEMS_SUCCESS",
  SET_FETCH_FEATURE_SYSTEMS_FAILED:
    "select-feature-system/SET_FETCH_FEATURE_SYSTEMS_FAILED",

  SET_SEARCH_FEATURE_SYSTEMS_PARAMS:
    "select-feature-system/SET_SEARCH_FEATURE_SYSTEMS_PARAMS",
  SET_SEARCH_FEATURE_SYSTEMS_LOADING:
    "select-feature-system/SET_SEARCH_FEATURE_SYSTEMS_LOADING",
  SET_SEARCH_FEATURE_SYSTEMS_SUCCESS:
    "select-feature-system/SET_SEARCH_FEATURE_SYSTEMS_SUCCESS",
  SET_SEARCH_FEATURE_SYSTEMS_FAILED:
    "select-feature-system/SET_SEARCH_FEATURE_SYSTEMS_FAILED",

  SET_APPEND_FEATURE_SYSTEMS_PARAMS:
    "select-feature-system/SET_APPEND_FEATURE_SYSTEMS_PARAMS",
  SET_APPEND_FEATURE_SYSTEMS_LOADING:
    "select-feature-system/SET_APPEND_FEATURE_SYSTEMS_LOADING",
  SET_APPEND_FEATURE_SYSTEMS_SUCCESS:
    "select-feature-system/SET_APPEND_FEATURE_SYSTEMS_SUCCESS",
  SET_APPEND_FEATURE_SYSTEMS_FAILED:
    "select-feature-system/SET_APPEND_FEATURE_SYSTEMS_FAILED",

  SET_IS_FETCH_FEATURE_SYSTEMS_HITTED:
    "select-feature-system/SET_IS_FETCH_FEATURE_SYSTEMS_HITTED",
  SET_IS_SEARCH_FEATURE_SYSTEMS_HITTED:
    "select-feature-system/SET_IS_SEARCH_FEATURE_SYSTEMS_HITTED",
  SET_IS_APPEND_FEATURE_SYSTEMS_HITTED:
    "select-feature-system/SET_IS_APPEND_FEATURE_SYSTEMS_HITTED",

  FETCH_FEATURE_SYSTEMS_START:
    "select-feature-system/FETCH_FEATURE_SYSTEMS_START",
  SEARCH_FEATURE_SYSTEMS_START:
    "select-feature-system/SEARCH_FEATURE_SYSTEMS_START",
  APPEND_FEATURE_SYSTEMS_START:
    "select-feature-system/APPEND_FEATURE_SYSTEMS_START",
};

export default SELECT_FEATURE_SYSTEM_ACTION_TYPES;
