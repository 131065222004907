import SELECT_FEATURE_SYSTEM_ACTION_TYPES from "./select-feature-system.type";

const SELECT_FEATURE_SYSTEM_INITIAL_STATE = {
  featureSystems: {},
  searchFeatureSystems: {},

  isFeatureSystemsHasMore: true,
  isSearchFeatureSystemsHasMore: true,

  fetchFeatureSystemsParams: {},
  fetchFeatureSystemsLoading: false,
  fetchFeatureSystemsSuccess: null,
  fetchFeatureSystemsFailed: null,

  searchFeatureSystemsParams: {},
  searchFeatureSystemsLoading: false,
  searchFeatureSystemsSuccess: null,
  searchFeatureSystemsFailed: null,

  appendFeatureSystemsParams: {},
  appendFeatureSystemsLoading: false,
  appendFeatureSystemsSuccess: null,
  appendFeatureSystemsFailed: null,

  isFetchFeatureSystemsHitted: false,
  isSearchFeatureSystemsHitted: false,
  isAppendFeatureSystemsHitted: false,
};

export const selectFeatureSystemReducer = (
  state = SELECT_FEATURE_SYSTEM_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FEATURE_SYSTEMS:
      return { ...state, featureSystems: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS:
      return { ...state, searchFeatureSystems: payload };

    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FEATURE_SYSTEMS_HAS_MORE:
      return { ...state, isFeatureSystemsHasMore: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_SEARCH_FEATURE_SYSTEMS_HAS_MORE:
      return { ...state, isSearchFeatureSystemsHasMore: payload };

    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_PARAMS:
      return { ...state, fetchFeatureSystemsParams: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_LOADING:
      return { ...state, fetchFeatureSystemsLoading: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_SUCCESS:
      return { ...state, fetchFeatureSystemsSuccess: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_FAILED:
      return { ...state, fetchFeatureSystemsFailed: payload };

    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS_PARAMS:
      return { ...state, searchFeatureSystemsParams: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS_LOADING:
      return { ...state, searchFeatureSystemsLoading: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS_SUCCESS:
      return { ...state, searchFeatureSystemsSuccess: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS_FAILED:
      return { ...state, searchFeatureSystemsFailed: payload };

    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_APPEND_FEATURE_SYSTEMS_PARAMS:
      return { ...state, appendFeatureSystemsParams: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_APPEND_FEATURE_SYSTEMS_LOADING:
      return { ...state, appendFeatureSystemsLoading: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_APPEND_FEATURE_SYSTEMS_SUCCESS:
      return { ...state, appendFeatureSystemsSuccess: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_APPEND_FEATURE_SYSTEMS_FAILED:
      return { ...state, appendFeatureSystemsFailed: payload };

    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FETCH_FEATURE_SYSTEMS_HITTED:
      return { ...state, isFetchFeatureSystemsHitted: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_SEARCH_FEATURE_SYSTEMS_HITTED:
      return { ...state, isSearchFeatureSystemsHitted: payload };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_APPEND_FEATURE_SYSTEMS_HITTED:
      return { ...state, isAppendFeatureSystemsHitted: payload };

    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.APPEND_FEATURE_SYSTEMS:
      return {
        ...state,
        featureSystems: { ...state.featureSystems, ...payload },
      };
    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.APPEND_SEARCH_FEATURE_SYSTEMS:
      return {
        ...state,
        searchFeatureSystems: { ...state.searchFeatureSystems, ...payload },
      };

    case SELECT_FEATURE_SYSTEM_ACTION_TYPES.RESET_SELECT_FEATURE_SYSTEM_REDUCER:
      return SELECT_FEATURE_SYSTEM_INITIAL_STATE;
    default:
      return state;
  }
};
