import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getUserSubscribers } from "../../api/user-subscriber.api";

import SELECT_SUBSCRIBER_ACTION_TYPES from "./select-subscriber.type";
import {
  appendSubscribers,
  appendSearchSubscribers,
  setAppendSubscribersFailed,
  setAppendSubscribersLoading,
  setAppendSubscribersSuccess,
  setFetchSubscribersFailed,
  setFetchSubscribersLoading,
  setFetchSubscribersSuccess,
  setIsAppendSubscribersHitted,
  setIsFetchSubscribersHitted,
  setIsSubscribersHasMore,
  setIsSearchSubscribersHasMore,
  setIsSearchSubscribersHitted,
  setSubscribers,
  setSearchSubscribers,
  setSearchSubscribersFailed,
  setSearchSubscribersLoading,
  setSearchSubscribersSuccess,
} from "./select-subscriber.action";
import {
  getAppendSubscribersParams,
  getFetchSubscribersParams,
  getSearchSubscribersParams,
} from "./select-subscriber.selector";

export function* _getFetchSubscribers() {
  try {
    const fetchSubscribersParams = yield select(getFetchSubscribersParams);
    const parameters = getParamsWithDefault(fetchSubscribersParams);

    yield put(setFetchSubscribersLoading(true));

    const {
      meta: { message },
      data: users,
    } = yield call(getUserSubscribers, parameters);

    yield put(setIsFetchSubscribersHitted(true));
    yield put(setIsSubscribersHasMore(Object.keys(users).length > 0));

    if (parameters.page > 1) {
      yield put(appendSubscribers(users));
    } else {
      yield put(setSubscribers(users));
    }

    yield put(setFetchSubscribersSuccess(message));
    yield put(setFetchSubscribersLoading(false));
  } catch (error) {
    yield put(setFetchSubscribersFailed(error));
    yield put(setFetchSubscribersLoading(false));
  }
}
export function* _getSearchSubscribers() {
  try {
    const searchSubscribersParams = yield select(getSearchSubscribersParams);
    const parameters = getParamsWithDefault(searchSubscribersParams);

    yield put(setSearchSubscribersLoading(true));

    const {
      meta: { message },
      data: users,
    } = yield call(getUserSubscribers, parameters);

    yield put(setIsSearchSubscribersHitted(true));
    yield put(setIsSearchSubscribersHasMore(Object.keys(users).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchSubscribers(users));
    } else {
      yield put(setSearchSubscribers(users));
    }

    yield put(setSearchSubscribersSuccess(message));
    yield put(setSearchSubscribersLoading(false));
  } catch (error) {
    yield put(setSearchSubscribersFailed(error));
    yield put(setSearchSubscribersLoading(false));
  }
}
export function* _getAppendSubscribers() {
  try {
    const appendSubscribersParams = yield select(getAppendSubscribersParams);
    const parameters = getParamsWithDefault(appendSubscribersParams);

    yield put(setAppendSubscribersLoading(true));

    const {
      meta: { message },
      data: users,
    } = yield call(getUserSubscribers, parameters);

    yield put(setIsAppendSubscribersHitted(true));
    yield put(appendSubscribers(users));

    yield put(setAppendSubscribersSuccess(message));
    yield put(setAppendSubscribersLoading(false));
  } catch (error) {
    yield put(setAppendSubscribersFailed(error));
    yield put(setAppendSubscribersLoading(false));
  }
}

export function* onFetchSubscribersStart() {
  yield takeLatest(
    SELECT_SUBSCRIBER_ACTION_TYPES.FETCH_SUBSCRIBERS_START,
    _getFetchSubscribers
  );
}
export function* onSearchSubscribersStart() {
  yield takeLatest(
    SELECT_SUBSCRIBER_ACTION_TYPES.SEARCH_SUBSCRIBERS_START,
    _getSearchSubscribers
  );
}
export function* onAppendSubscribersStart() {
  yield takeLatest(
    SELECT_SUBSCRIBER_ACTION_TYPES.APPEND_SUBSCRIBERS_START,
    _getAppendSubscribers
  );
}

export function* selectSubscriberSaga() {
  yield all([
    call(onFetchSubscribersStart),
    call(onSearchSubscribersStart),
    call(onAppendSubscribersStart),
  ]);
}
