import FEATURE_SYSTEM_ACTION_TYPES from "./feature-system.type";

export const FEATURE_SYSTEM_INITIAL_STATE = {
  featureSystems: [],
  featureSystem: null,

  isFeatureSystemsHasMore: true,

  fetchFeatureSystemsSearch: null,
  fetchFeatureSystemsSort: null,
  fetchFeatureSystemsKeyBy: null,
  fetchFeatureSystemsPage: 1,
  fetchFeatureSystemsPerPage: null,
  fetchFeatureSystemsIncludes: null,
  fetchFeatureSystemsLoading: false,
  fetchFeatureSystemsSuccess: null,
  fetchFeatureSystemsFailed: null,

  fetchFeatureSystemLoading: false,
  fetchFeatureSystemSuccess: null,
  fetchFeatureSystemFailed: null,

  updateFeatureSystemLoading: false,
  updateFeatureSystemSuccess: null,
  updateFeatureSystemFailed: null,

  isFetchFeatureSystemsHitted: false,
  isFetchFeatureSystemHitted: false,
  isUpdateFeatureSystemHitted: false,
};

export const featureSystemReducer = (
  state = FEATURE_SYSTEM_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FEATURE_SYSTEMS:
      return { ...state, featureSystems: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FEATURE_SYSTEM:
      return { ...state, featureSystem: payload };

    case FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FEATURE_SYSTEMS_HAS_MORE:
      return { ...state, isFeatureSystemsHasMore: payload };

    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_SEARCH:
      return { ...state, fetchFeatureSystemsSearch: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_SORT:
      return { ...state, fetchFeatureSystemsSort: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_KEY_BY:
      return { ...state, fetchFeatureSystemsKeyBy: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_PAGE:
      return { ...state, fetchFeatureSystemsPage: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_PER_PAGE:
      return { ...state, fetchFeatureSystemsPerPage: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_INCLUDES:
      return { ...state, fetchFeatureSystemsIncludes: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_LOADING:
      return { ...state, fetchFeatureSystemsLoading: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_SUCCESS:
      return { ...state, fetchFeatureSystemsSuccess: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_FAILED:
      return { ...state, fetchFeatureSystemsFailed: payload };

    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEM_LOADING:
      return { ...state, fetchFeatureSystemLoading: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEM_SUCCESS:
      return { ...state, fetchFeatureSystemSuccess: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEM_FAILED:
      return { ...state, fetchFeatureSystemFailed: payload };

    case FEATURE_SYSTEM_ACTION_TYPES.SET_UPDATE_FEATURE_SYSTEM_LOADING:
      return { ...state, updateFeatureSystemLoading: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_UPDATE_FEATURE_SYSTEM_SUCCESS:
      return { ...state, updateFeatureSystemSuccess: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_UPDATE_FEATURE_SYSTEM_FAILED:
      return { ...state, updateFeatureSystemFailed: payload };

    case FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FETCH_FEATURE_SYSTEMS_HITTED:
      return { ...state, isFetchFeatureSystemsHitted: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FETCH_FEATURE_SYSTEM_HITTED:
      return { ...state, isFetchFeatureSystemHitted: payload };
    case FEATURE_SYSTEM_ACTION_TYPES.SET_IS_UPDATE_FEATURE_SYSTEM_HITTED:
      return { ...state, isUpdateFeatureSystemHitted: payload };

    case FEATURE_SYSTEM_ACTION_TYPES.APPEND_FEATURE_SYSTEMS:
      return {
        ...state,
        featureSystems: [...state.featureSystems, ...payload],
      };

    case FEATURE_SYSTEM_ACTION_TYPES.RESET_FEATURE_SYSTEM_REDUCER:
      return FEATURE_SYSTEM_INITIAL_STATE;
    default:
      return state;
  }
};
