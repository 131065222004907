import { forwardRef } from "react";

import {
  ResponsiveColStyled,
  ResponsiveContainer,
  ResponsiveRowStyled,
} from "./responsive.style";

const Responsive = ({ children }) => {
  return <ResponsiveContainer>{children}</ResponsiveContainer>;
};

export const RESPONSIVE_ROW_ALIGNS = {
  START: "START",
  CENTER: "CENTER",
  END: "END",
};

const ResponsiveRow = (
  { rowVerAlign, rowHorAlign, rowGap = "1.5rem", colGap, children, ...props },
  ref
) => {
  return (
    <ResponsiveRowStyled
      ref={ref}
      rowVerAlign={rowVerAlign}
      rowHorAlign={rowHorAlign}
      rowGap={rowGap}
      colGap={colGap}
      {...props}
    >
      {children}
    </ResponsiveRowStyled>
  );
};

export const RESPONSIVE_COLUMN_ALIGNS = {
  START: "START",
  CENTER: "CENTER",
  END: "END",
};

const ResponsiveCol = (
  {
    colVerAlign,
    colHorAlign,
    rowGap,
    colGap,
    xs,
    sm,
    md,
    lg,
    xl,
    xl2,
    xl3,
    xl4,
    children,
    ...props
  },
  ref
) => {
  return (
    <ResponsiveColStyled
      ref={ref}
      colVerAlign={colVerAlign}
      colHorAlign={colHorAlign}
      rowGap={rowGap}
      colGap={colGap}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      xl2={xl2}
      xl3={xl3}
      xl4={xl4}
      {...props}
    >
      {children}
    </ResponsiveColStyled>
  );
};

Responsive.Row = forwardRef(ResponsiveRow);
Responsive.Col = forwardRef(ResponsiveCol);

export default Responsive;
