import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getValidFiles } from "../../utils/store.utils";
import { isEmpty } from "../../utils/validation.utils";

import { UPLOAD_TYPES } from "../../constants/upload.constant";

import {
  getRestrictionNotices,
  getRestrictionNotice,
  createRestrictionNotice,
  updateRestrictionNotice,
  deleteRestrictionNotice,
} from "../../api/restriction-notice.api";
import { uploadFile } from "../../api/upload.api";

import RESTRICTION_NOTICE_ACTION_TYPES from "./restriction-notice.type";
import {
  appendRestrictionNotices,
  setRestrictionNotices,
  setRestrictionNotice,
  setCreateRestrictionNoticeFailed,
  setCreateRestrictionNoticeLoading,
  setCreateRestrictionNoticeSuccess,
  setDeleteRestrictionNoticeFailed,
  setDeleteRestrictionNoticeLoading,
  setDeleteRestrictionNoticeSuccess,
  setFetchRestrictionNoticesFailed,
  setFetchRestrictionNoticesLoading,
  setFetchRestrictionNoticesPage,
  setFetchRestrictionNoticesSuccess,
  setFetchRestrictionNoticeFailed,
  setFetchRestrictionNoticeLoading,
  setFetchRestrictionNoticeSuccess,
  setIsRestrictionNoticesHasMore,
  setIsCreateRestrictionNoticeHitted,
  setIsDeleteRestrictionNoticeHitted,
  setIsFetchRestrictionNoticesHitted,
  setIsFetchRestrictionNoticeHitted,
  setIsUpdateRestrictionNoticeHitted,
  setUpdateRestrictionNoticeFailed,
  setUpdateRestrictionNoticeLoading,
  setUpdateRestrictionNoticeSuccess,
} from "./restriction-notice.action";
import {
  getFetchRestrictionNoticesIncludes,
  getFetchRestrictionNoticesKeyBy,
  getFetchRestrictionNoticesPage,
  getFetchRestrictionNoticesPerPage,
  getFetchRestrictionNoticesSearch,
  getFetchRestrictionNoticesSort,
  getIsFetchRestrictionNoticesHitted,
} from "./restriction-notice.selector";
import {
  setFetchFeatureSystemsParams,
  setSearchFeatureSystems,
  setFeatureSystems,
} from "../select-feature-system/select-feature-system.action";
import {
  getFetchFeatureSystemsParams,
  getIsFetchFeatureSystemsHitted,
} from "../select-feature-system/select-feature-system.selector";
import { _getFetchFeatureSystems } from "../select-feature-system/select-feature-system.saga";

export function* _getRestrictionNotices() {
  try {
    yield put(setFetchRestrictionNoticesLoading(true));

    const search = yield select(getFetchRestrictionNoticesSearch);
    const sort = yield select(getFetchRestrictionNoticesSort);
    const key_by = yield select(getFetchRestrictionNoticesKeyBy);
    const page = yield select(getFetchRestrictionNoticesPage);
    const per_page = yield select(getFetchRestrictionNoticesPerPage);
    const includes = yield select(getFetchRestrictionNoticesIncludes);

    const parameters = { search, sort, key_by, page, per_page, includes };

    const {
      meta: { message },
      data: { data: restrictionNotices },
    } = yield call(getRestrictionNotices, parameters);

    yield put(setIsFetchRestrictionNoticesHitted(true));
    yield put(setIsRestrictionNoticesHasMore(restrictionNotices.length > 0));

    if (page > 1) {
      yield put(appendRestrictionNotices(restrictionNotices));
    } else {
      yield put(setRestrictionNotices(restrictionNotices));
    }

    yield put(setFetchRestrictionNoticesSuccess(message));
    yield put(setFetchRestrictionNoticesLoading(false));
  } catch (error) {
    yield put(setFetchRestrictionNoticesFailed(error));
    yield put(setFetchRestrictionNoticesLoading(false));
  }
}
export function* _getRestrictionNotice({ payload: restrictionNoticeId }) {
  try {
    yield put(setFetchRestrictionNoticeLoading(true));

    const {
      meta: { message },
      data: restrictionNotice,
    } = yield call(getRestrictionNotice, restrictionNoticeId);

    yield put(setIsFetchRestrictionNoticeHitted(true));
    yield put(setRestrictionNotice(restrictionNotice));

    yield put(setFetchRestrictionNoticeSuccess(message));
    yield put(setFetchRestrictionNoticeLoading(false));
  } catch (error) {
    yield put(setFetchRestrictionNoticeFailed(error));
    yield put(setFetchRestrictionNoticeLoading(false));
  }
}
export function* _createRestrictionNotice({ payload: request }) {
  try {
    yield put(setCreateRestrictionNoticeLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.cover_img)) {
      const files = getValidFiles([payload.cover_img]);

      if (!isEmpty(files)) {
        const request = {
          type: UPLOAD_TYPES.RESTRICTION_NOTICE,
          file: files[0],
        };
        const { data: imageUrl } = yield call(uploadFile, request);

        payload.cover_img = imageUrl;
      }
    }

    const {
      meta: { message },
    } = yield call(createRestrictionNotice, payload);

    yield put(setIsCreateRestrictionNoticeHitted(true));

    const isFetchRestrictionNoticesHitted = yield select(
      getIsFetchRestrictionNoticesHitted
    );
    const isFetchFeatureSystemsHitted = yield select(
      getIsFetchFeatureSystemsHitted
    );
    const fetchFeatureSystemsParams = yield select(
      getFetchFeatureSystemsParams
    );

    if (isFetchRestrictionNoticesHitted) {
      yield put(setFetchRestrictionNoticesPage(1));
      yield call(_getRestrictionNotices);
    }
    if (isFetchFeatureSystemsHitted) {
      const params = fetchFeatureSystemsParams;
      params.page = 1;
      params.per_page = 20;

      yield put(setFeatureSystems({}));
      yield put(setSearchFeatureSystems({}));
      yield put(setFetchFeatureSystemsParams(params));
      yield call(_getFetchFeatureSystems);
    }

    yield put(setCreateRestrictionNoticeSuccess(message));
    yield put(setCreateRestrictionNoticeLoading(false));
  } catch (error) {
    yield put(setCreateRestrictionNoticeFailed(error));
    yield put(setCreateRestrictionNoticeLoading(false));
  }
}
export function* _updateRestrictionNotice({
  payload: { restrictionNoticeId, request },
}) {
  try {
    yield put(setUpdateRestrictionNoticeLoading(true));

    const payload = { ...request };
    if (!isEmpty(payload?.cover_img)) {
      const files = getValidFiles([payload.cover_img]);

      if (!isEmpty(files)) {
        const request = {
          type: UPLOAD_TYPES.RESTRICTION_NOTICE,
          file: files[0],
        };
        const { data: imageUrl } = yield call(uploadFile, request);

        payload.cover_img = imageUrl;
      }
    }

    const {
      meta: { message },
    } = yield call(updateRestrictionNotice, restrictionNoticeId, payload);

    yield put(setIsUpdateRestrictionNoticeHitted(true));

    const isFetchRestrictionNoticesHitted = yield select(
      getIsFetchRestrictionNoticesHitted
    );
    const isFetchFeatureSystemsHitted = yield select(
      getIsFetchFeatureSystemsHitted
    );
    const fetchFeatureSystemsParams = yield select(
      getFetchFeatureSystemsParams
    );

    if (isFetchRestrictionNoticesHitted) {
      yield put(setFetchRestrictionNoticesPage(1));
      yield call(_getRestrictionNotices);
    }
    if (isFetchFeatureSystemsHitted) {
      const params = fetchFeatureSystemsParams;
      params.page = 1;
      params.per_page = 20;

      yield put(setFeatureSystems({}));
      yield put(setSearchFeatureSystems({}));
      yield put(setFetchFeatureSystemsParams(params));
      yield call(_getFetchFeatureSystems);
    }

    yield put(setUpdateRestrictionNoticeSuccess(message));
    yield put(setUpdateRestrictionNoticeLoading(false));
  } catch (error) {
    yield put(setUpdateRestrictionNoticeFailed(error));
    yield put(setUpdateRestrictionNoticeLoading(false));
  }
}
export function* _deleteRestrictionNotice({ payload: restrictionNoticeId }) {
  try {
    yield put(setDeleteRestrictionNoticeLoading(true));

    const {
      meta: { message },
    } = yield call(deleteRestrictionNotice, restrictionNoticeId);

    yield put(setIsDeleteRestrictionNoticeHitted(true));

    const isFetchRestrictionNoticesHitted = yield select(
      getIsFetchRestrictionNoticesHitted
    );
    const isFetchFeatureSystemsHitted = yield select(
      getIsFetchFeatureSystemsHitted
    );
    const fetchFeatureSystemsParams = yield select(
      getFetchFeatureSystemsParams
    );

    if (isFetchRestrictionNoticesHitted) {
      yield put(setFetchRestrictionNoticesPage(1));
      yield call(_getRestrictionNotices);
    }
    if (isFetchFeatureSystemsHitted) {
      const params = fetchFeatureSystemsParams;
      params.page = 1;
      params.per_page = 20;

      yield put(setFeatureSystems({}));
      yield put(setSearchFeatureSystems({}));
      yield put(setFetchFeatureSystemsParams(params));
      yield call(_getFetchFeatureSystems);
    }

    yield put(setDeleteRestrictionNoticeSuccess(message));
    yield put(setDeleteRestrictionNoticeLoading(false));
  } catch (error) {
    yield put(setDeleteRestrictionNoticeFailed(error));
    yield put(setDeleteRestrictionNoticeLoading(false));
  }
}

export function* onFetchRestrictionNoticesStart() {
  yield takeLatest(
    RESTRICTION_NOTICE_ACTION_TYPES.FETCH_RESTRICTION_NOTICES_START,
    _getRestrictionNotices
  );
}
export function* onFetchRestrictionNoticeStart() {
  yield takeLatest(
    RESTRICTION_NOTICE_ACTION_TYPES.FETCH_RESTRICTION_NOTICE_START,
    _getRestrictionNotice
  );
}
export function* onCreateRestrictionNoticeStart() {
  yield takeLatest(
    RESTRICTION_NOTICE_ACTION_TYPES.CREATE_RESTRICTION_NOTICE_START,
    _createRestrictionNotice
  );
}
export function* onUpdateRestrictionNoticeStart() {
  yield takeLatest(
    RESTRICTION_NOTICE_ACTION_TYPES.UPDATE_RESTRICTION_NOTICE_START,
    _updateRestrictionNotice
  );
}
export function* onDeleteRestrictionNoticeStart() {
  yield takeLatest(
    RESTRICTION_NOTICE_ACTION_TYPES.DELETE_RESTRICTION_NOTICE_START,
    _deleteRestrictionNotice
  );
}

export function* restrictionNoticeSaga() {
  yield all([
    call(onFetchRestrictionNoticesStart),
    call(onFetchRestrictionNoticeStart),
    call(onCreateRestrictionNoticeStart),
    call(onUpdateRestrictionNoticeStart),
    call(onDeleteRestrictionNoticeStart),
  ]);
}
