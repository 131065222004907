import slugify from "slugify";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { findIntegration } from "../../utils/integration.utils";
import { parseToBoolean } from "../../utils/parser.utils";
import { showErrorMessage } from "../../utils/popup.utils";
import {
  getValidationErrors,
  isEmpty,
  rule,
} from "../../utils/validation.utils";

import { INTEGRATION_KEYS } from "../../constants/integration.constant";
import { INTEGRATION_FAI_COMPLETIONS } from "../../constants/integration-completion.constant";

import {
  getCurrentMarket,
  getCurrentMarketId,
} from "../../store/global/global.selector";
import { appendCurrentDialog } from "../../store/component/component.action";
import {
  getCurrentModal,
  getIsModalOpen,
} from "../../store/component/component.selector";
import {
  fetchRegisterFaiStatusStart,
  setupFaiStart,
} from "../../store/integration/integration.action";
import {
  getFaiRegisterStatus,
  getFetchIntegrationsLoading,
  getFetchRegisterFaiStatusLoading,
  getIntegrations,
  getIsFetchRegisterFaiStatusHitted,
  getSetupFaiLoading,
  getSetupFaiSuccess,
} from "../../store/integration/integration.selector";

import Modal from "../../components/modal/modal.component";
import Responsive from "../../components/responsive/responsive.component";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import FormLabel, {
  FORM_LABEL_COLORS,
} from "../../components/form-label/form-label.component";
import FormInput, {
  FORM_INPUT_SIZES,
  FORM_INPUT_TYPES,
} from "../../components/form-input/form-input.component";
import ApplicationCompletionTrack from "../application-completion-track/application-completion-track.widget";
import ApplicationFaiSequence from "../application-fai-sequence/application-fai-sequence.widget";
import FormSlugInstruction from "../form-slug-instruction/form-slug-instruction.widget";
import { dialogKey as faiConnectDialog } from "../application-fai-connect/application-fai-connect.widget";

import { Settings01Icon, Sorting19Icon } from "hugeicons-react";

import { ReactComponent as TaxSvg } from "../../assets/icons/PageTax.svg";
import { ReactComponent as ConfirmSvg } from "../../assets/icons/stroke/Confirm.svg";
import { ReactComponent as EditSvg } from "../../assets/icons/Edit3.svg";
import { ReactComponent as CancelSvg } from "../../assets/icons/stroke/Cancel.svg";

export const modalName = "APPLICATION_FAI_SETTINGS";

const FORM_STATES = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  SETUP: "SETUP",
};

const defaultValues = {
  company_slug: "",
  invoice_method: "",
  street_en: "",
  street_ar: "",
  building_number: "",
  city: "",
  postal_code: "",
  district_en: "",
  district_ar: "",
  short_address: "",
  secondary_number: "",
  sales_invoice_prefix: "",
  sales_invoice_start: "",
  return_sales_invoice_prefix: "",
  return_sales_invoice_start: "",
};

const ApplicationFaiSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentModal = useSelector(getCurrentModal);
  const isModalOpen = useSelector(getIsModalOpen);

  const currentMarketId = useSelector(getCurrentMarketId);
  const currentMarket = useSelector(getCurrentMarket);

  const isFetchRegisterFaiStatusHitted = useSelector(
    getIsFetchRegisterFaiStatusHitted
  );
  const fetchRegisterFaiStatusLoading = useSelector(
    getFetchRegisterFaiStatusLoading
  );
  const faiRegisterStatus = useSelector(getFaiRegisterStatus);

  const setupFaiLoading = useSelector(getSetupFaiLoading);
  const setupFaiSuccess = useSelector(getSetupFaiSuccess);

  const fetchIntegrationsLoading = useSelector(getFetchIntegrationsLoading);
  const integrations = useSelector(getIntegrations);

  const isConfigured = useRef(false);
  const isSetupReady = useRef(false);
  const isRegistered = useRef(false);

  const [completions, setCompletions] = useState([]);
  const [progressCount, setProgressCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);

  const [formState, setFormState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [integration, setIntegration] = useState(defaultValues);
  const [inputValues, setInputValues] = useState(defaultValues);

  const handleEditConfiguration = () =>
    setFormState((formState) =>
      formState === FORM_STATES.VIEW ? FORM_STATES.EDIT : FORM_STATES.VIEW
    );
  const handleSequenceChange = (invoiceMethod) => () => {
    if (formState === FORM_STATES.VIEW) return;
    setInputValues((inputValues) => ({
      ...inputValues,
      invoice_method: invoiceMethod,
    }));
  };

  const handleInputChange = ({ name, value }) =>
    setInputValues({ ...inputValues, [name]: value });

  const handleFormSubmit = () => {
    const {
      company_slug,
      invoice_method,
      street_en,
      street_ar,
      building_number,
      city,
      postal_code,
      district_en,
      district_ar,
      short_address,
      secondary_number,
      sales_invoice_prefix,
      sales_invoice_start,
      return_sales_invoice_prefix,
      return_sales_invoice_start,
    } = inputValues;

    const rules = {
      business_slug: [rule.required(), rule.slug()],
      invoice_method: [rule.required()],
      street_arabic: [rule.required()],
      building_number: [rule.required()],
      city: [rule.required()],
      postal_code: [rule.required()],
      district_arabic: [rule.required()],
      short_address: [rule.required()],
      secondary_number: [rule.required()],
      sales_prefix: invoice_method === 2 ? [rule.required()] : [],
      sales_prefix_start_number: invoice_method === 2 ? [rule.required()] : [],
      return_prefix: invoice_method === 2 ? [rule.required()] : [],
      return_prefix_start_number: invoice_method === 2 ? [rule.required()] : [],
    };
    const datas = {
      business_slug: company_slug,
      sales_prefix: sales_invoice_prefix,
      sales_prefix_start_number: sales_invoice_start,
      return_prefix: return_sales_invoice_prefix,
      return_prefix_start_number: return_sales_invoice_start,
      street_arabic: street_ar,
      district_arabic: district_ar,
      invoice_method,
      building_number,
      city,
      postal_code,
      short_address,
      secondary_number,
    };

    const errors = getValidationErrors(datas, rules);

    if (errors.length > 0) {
      showErrorMessage({ data: errors });
      return;
    }

    dispatch(
      setupFaiStart({
        market_id: currentMarketId,
        market: { slug: company_slug },
        location: {
          street_en,
          street_ar,
          building_number,
          city,
          postal_code,
          district_en,
          district_ar,
          short_address,
          secondary_number,
        },
        integration: {
          invoice_method,
          sales_invoice_prefix,
          return_sales_invoice_prefix,
          sales_invoice_start,
          return_sales_invoice_start,
        },
      })
    );
  };

  const handleRegisterStatus = () => {
    if (
      currentModal === modalName &&
      isModalOpen &&
      !isFetchRegisterFaiStatusHitted &&
      !fetchRegisterFaiStatusLoading
    ) {
      dispatch(fetchRegisterFaiStatusStart({ market_id: currentMarketId }));
    }
  };
  const handleIntegrationSetup = () => {
    const methodParams = {
      key: INTEGRATION_KEYS.FAI_INVOICE_METHOD,
      market_id: currentMarketId,
    };
    const salesPrefixParams = {
      key: INTEGRATION_KEYS.FAI_SALES_INVOICE_PREFIX,
      market_id: currentMarketId,
    };
    const returnPrefixParams = {
      key: INTEGRATION_KEYS.FAI_RETURN_SALES_INVOICE_PREFIX,
      market_id: currentMarketId,
    };
    const salesStartParams = {
      key: INTEGRATION_KEYS.FAI_SALES_INVOICE_START,
      market_id: currentMarketId,
    };
    const returnStartParams = {
      key: INTEGRATION_KEYS.FAI_RETURN_SALES_INVOICE_START,
      market_id: currentMarketId,
    };

    const defaultSlug = slugify(currentMarket?.name_en, { lower: true });

    const location = currentMarket?.location;
    const method = findIntegration(integrations, methodParams);
    const salesPrefix = findIntegration(integrations, salesPrefixParams);
    const returnPrefix = findIntegration(integrations, returnPrefixParams);
    const salesStart = findIntegration(integrations, salesStartParams);
    const returnStart = findIntegration(integrations, returnStartParams);

    const integration = { ...defaultValues };

    let filledCount = 0;
    if (!isEmpty(currentMarket?.slug)) {
      integration.company_slug = currentMarket.slug;
      filledCount++;
    } else if (!isEmpty(defaultSlug)) {
      integration.company_slug = defaultSlug;
    }
    if (!isEmpty(method?.value)) {
      integration.invoice_method = method.value;
      filledCount++;
    }
    if (!isEmpty(location?.street_en)) {
      integration.street_en = location.street_en;
      filledCount++;
    }
    if (!isEmpty(location?.street_ar)) {
      integration.street_ar = location.street_ar;
      filledCount++;
    }
    if (!isEmpty(location?.building_number)) {
      integration.building_number = location.building_number;
      filledCount++;
    }
    if (!isEmpty(location?.city)) {
      integration.city = location.city;
      filledCount++;
    }
    if (!isEmpty(location?.postal_code)) {
      integration.postal_code = location.postal_code;
      filledCount++;
    }
    if (!isEmpty(location?.district_en)) {
      integration.district_en = location.district_en;
      filledCount++;
    }
    if (!isEmpty(location?.district_ar)) {
      integration.district_ar = location.district_ar;
      filledCount++;
    }
    if (!isEmpty(location?.short_address)) {
      integration.short_address = location.short_address;
      filledCount++;
    }
    if (!isEmpty(location?.secondary_number)) {
      integration.secondary_number = location.secondary_number;
      filledCount++;
    }
    if (!isEmpty(salesPrefix?.value)) {
      integration.sales_invoice_prefix = salesPrefix.value;
      filledCount++;
    }
    if (!isEmpty(returnPrefix?.value)) {
      integration.sales_invoice_start = returnPrefix.value;
      filledCount++;
    }
    if (!isEmpty(salesStart?.value)) {
      integration.return_sales_invoice_prefix = salesStart.value;
      filledCount++;
    }
    if (!isEmpty(returnStart?.value)) {
      integration.return_sales_invoice_start = returnStart.value;
      filledCount++;
    }

    setIntegration(integration);
    setFormState(filledCount > 0 ? FORM_STATES.VIEW : FORM_STATES.SETUP);
  };
  const handleIntegrationConnect = () => {
    if (
      !isRegistered.current &&
      isSetupReady.current &&
      progressCount === completeCount &&
      progressCount > 0
    ) {
      dispatch(appendCurrentDialog(faiConnectDialog));
    }
  };

  useEffect(() => {
    const statusTimeout = setTimeout(handleRegisterStatus, 500);
    return () => clearTimeout(statusTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    const faiCrnStatus =
      faiRegisterStatus?.[INTEGRATION_FAI_COMPLETIONS.FAI_BUSINESS_CRN];
    const faiTaxStatus =
      faiRegisterStatus?.[INTEGRATION_FAI_COMPLETIONS.FAI_BUSINESS_TAX];
    const faiLogoStatus =
      faiRegisterStatus?.[INTEGRATION_FAI_COMPLETIONS.FAI_BUSINESS_LOGO];

    const completions = [];
    if (!isEmpty(faiCrnStatus)) completions.push(faiCrnStatus);
    if (!isEmpty(faiTaxStatus)) completions.push(faiTaxStatus);
    if (!isEmpty(faiLogoStatus)) completions.push(faiLogoStatus);

    let progressCount = 0;
    let completeCount = 0;

    for (const completion of completions) {
      const { is_configured, is_required } = completion ?? {};

      if (!parseToBoolean(is_required)) continue;
      if (parseToBoolean(is_configured)) progressCount++;
      completeCount++;
    }

    setCompletions(completions);
    setProgressCount(progressCount);
    setCompleteCount(completeCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faiRegisterStatus]);

  useEffect(() => {
    if (currentModal === modalName && isModalOpen && !isConfigured.current) {
      isConfigured.current = true;
      handleIntegrationSetup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    if (!isEmpty(currentMarketId) && integrations.length > 0) {
      const companyIdParams = {
        key: INTEGRATION_KEYS.FAI_COMPANY_ID,
        marketId: currentMarketId,
      };

      const companyId = findIntegration(integrations, companyIdParams);

      isRegistered.current = !isEmpty(companyId?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations, currentMarketId]);

  useEffect(() => {
    if (
      isConfigured.current &&
      !isEmpty(currentMarketId) &&
      integrations.length > 0
    ) {
      handleIntegrationSetup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations, currentMarketId, currentMarket]);

  useEffect(() => {
    setInputValues(integration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration, formState]);

  useEffect(() => {
    setIsLoading(fetchIntegrationsLoading || setupFaiLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchIntegrationsLoading, setupFaiLoading]);

  useEffect(() => {
    if (!isEmpty(setupFaiSuccess)) {
      isSetupReady.current = true;
      setFormState(FORM_STATES.VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupFaiSuccess]);

  useEffect(() => {
    const connectTimeout = setTimeout(handleIntegrationConnect, 500);
    return () => clearTimeout(connectTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations, currentMarketId, progressCount, completeCount]);

  return (
    <Modal
      name={modalName}
      icon={<TaxSvg />}
      title={t("Fai Settings")}
      description={t("Please fill in the following fields")}
    >
      <Responsive>
        <Responsive.Row rowGap="1rem">
          {progressCount === completions.length && progressCount > 0 && (
            <Responsive.Col>
              <ApplicationCompletionTrack
                completions={completions}
                progressCount={progressCount}
                completeCount={completeCount}
                isLoading={
                  !isFetchRegisterFaiStatusHitted &&
                  fetchRegisterFaiStatusLoading
                }
              />
            </Responsive.Col>
          )}
          <Responsive.Col>
            <FormSlugInstruction />
          </Responsive.Col>
          <Responsive.Col>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Business Slug")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="company_slug"
              placeholder={t("Enter business slug")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.company_slug
                  : inputValues.company_slug
              }
              isDisabled={FORM_STATES.VIEW}
              isSolid={FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Street :language", { language: "English" })}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="street_en"
              placeholder={t("Enter street")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.street_en
                  : inputValues.street_en
              }
              isDisabled={formState === FORM_STATES.VIEW}
              isSolid={formState === FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Street :language", { language: "Arabic" })}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="street_ar"
              placeholder={t("Enter street")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.street_ar
                  : inputValues.street_ar
              }
              isDisabled={formState === FORM_STATES.VIEW}
              isSolid={formState === FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Building Number")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.NUMBER}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="building_number"
              placeholder={t("Enter building number")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.building_number
                  : inputValues.building_number
              }
              isDisabled={formState === FORM_STATES.VIEW}
              isSolid={formState === FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Postal Code")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.NUMBER}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="postal_code"
              placeholder={t("Enter postal code")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.postal_code
                  : inputValues.postal_code
              }
              isDisabled={formState === FORM_STATES.VIEW}
              isSolid={formState === FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("City")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="city"
              placeholder={t("Enter city")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.city
                  : inputValues.city
              }
              isDisabled={formState === FORM_STATES.VIEW}
              isSolid={formState === FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("District :language", { language: "English" })}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="district_en"
              placeholder={t("Enter district")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.district_en
                  : inputValues.district_en
              }
              isDisabled={formState === FORM_STATES.VIEW}
              isSolid={formState === FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("District :language", { language: "Arabic" })}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="district_ar"
              placeholder={t("Enter district")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.district_ar
                  : inputValues.district_ar
              }
              isDisabled={formState === FORM_STATES.VIEW}
              isSolid={formState === FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Short Address")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="short_address"
              placeholder={t("Enter short address")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.short_address
                  : inputValues.short_address
              }
              isDisabled={formState === FORM_STATES.VIEW}
              isSolid={formState === FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col sm={6}>
            <FormInput
              label={
                <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                  {t("Secondary Number")}
                </FormLabel>
              }
              inputType={FORM_INPUT_TYPES.TEXT}
              inputSize={FORM_INPUT_SIZES.NORMAL}
              name="secondary_number"
              placeholder={t("Enter secondary number")}
              onChange={handleInputChange}
              value={
                formState === FORM_STATES.VIEW
                  ? integration.secondary_number
                  : inputValues.secondary_number
              }
              isDisabled={formState === FORM_STATES.VIEW}
              isSolid={formState === FORM_STATES.VIEW}
            />
          </Responsive.Col>
          <Responsive.Col>
            <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
              {t("Invoice Method")}
            </FormLabel>
            <Responsive.Row rowGap="1rem">
              <Responsive.Col sm={6}>
                <ApplicationFaiSequence
                  icon={<Settings01Icon />}
                  name={t("Non-Sequential")}
                  info={t(
                    "Invoice numbers are manually entered by the user through the UI."
                  )}
                  isActive={
                    (formState === FORM_STATES.VIEW
                      ? integration.invoice_method
                      : inputValues.invoice_method) === 1
                  }
                  onClick={handleSequenceChange(1)}
                  isSolid={formState === FORM_STATES.VIEW}
                />
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <ApplicationFaiSequence
                  icon={<Sorting19Icon />}
                  name={t("Sequential")}
                  info={t(
                    "Invoices are generated with system-assigned, sequential invoice numbers."
                  )}
                  isActive={
                    (formState === FORM_STATES.VIEW
                      ? integration.invoice_method
                      : inputValues.invoice_method) === 2
                  }
                  onClick={handleSequenceChange(2)}
                  isSolid={formState === FORM_STATES.VIEW}
                />
              </Responsive.Col>
            </Responsive.Row>
          </Responsive.Col>
          {(formState === FORM_STATES.VIEW
            ? integration.invoice_method
            : inputValues.invoice_method) === 2 && (
            <>
              <Responsive.Col sm={6}>
                <FormInput
                  label={
                    <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                      {t("Sales Prefix")}
                    </FormLabel>
                  }
                  inputType={FORM_INPUT_TYPES.TEXT}
                  inputSize={FORM_INPUT_SIZES.NORMAL}
                  name="sales_invoice_prefix"
                  placeholder={t("Enter sales prefix")}
                  onChange={handleInputChange}
                  value={
                    formState === FORM_STATES.VIEW
                      ? integration.sales_invoice_prefix
                      : inputValues.sales_invoice_prefix
                  }
                  isDisabled={formState === FORM_STATES.VIEW}
                  isSolid={formState === FORM_STATES.VIEW}
                />
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <FormInput
                  label={
                    <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                      {t("Sales Prefix Start Number")}
                    </FormLabel>
                  }
                  inputType={FORM_INPUT_TYPES.TEXT}
                  inputSize={FORM_INPUT_SIZES.NORMAL}
                  name="sales_invoice_start"
                  placeholder={t("Enter sales prefix start number")}
                  onChange={handleInputChange}
                  value={
                    formState === FORM_STATES.VIEW
                      ? integration.sales_invoice_start
                      : inputValues.sales_invoice_start
                  }
                  isDisabled={formState === FORM_STATES.VIEW}
                  isSolid={formState === FORM_STATES.VIEW}
                />
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <FormInput
                  label={
                    <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                      {t("Return Prefix")}
                    </FormLabel>
                  }
                  inputType={FORM_INPUT_TYPES.TEXT}
                  inputSize={FORM_INPUT_SIZES.NORMAL}
                  name="return_sales_invoice_prefix"
                  placeholder={t("Enter return prefix")}
                  onChange={handleInputChange}
                  value={
                    formState === FORM_STATES.VIEW
                      ? integration.return_sales_invoice_prefix
                      : inputValues.return_sales_invoice_prefix
                  }
                  isDisabled={formState === FORM_STATES.VIEW}
                  isSolid={formState === FORM_STATES.VIEW}
                />
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <FormInput
                  label={
                    <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                      {t("Return Prefix Start Number")}
                    </FormLabel>
                  }
                  inputType={FORM_INPUT_TYPES.TEXT}
                  inputSize={FORM_INPUT_SIZES.NORMAL}
                  name="return_sales_invoice_start"
                  placeholder={t("Enter return prefix start number")}
                  onChange={handleInputChange}
                  value={
                    formState === FORM_STATES.VIEW
                      ? integration.return_sales_invoice_start
                      : inputValues.return_sales_invoice_start
                  }
                  isDisabled={formState === FORM_STATES.VIEW}
                  isSolid={formState === FORM_STATES.VIEW}
                />
              </Responsive.Col>
            </>
          )}
        </Responsive.Row>
        <Responsive.Row rowGap="1rem">
          <Responsive.Col></Responsive.Col>
          <Responsive.Col></Responsive.Col>
          {formState === FORM_STATES.SETUP ? (
            <Responsive.Col>
              <Button
                type="button"
                buttonColor={BUTTON_COLORS.PRIMARY}
                buttonSize={BUTTON_SIZES.NORMAL}
                buttonType={BUTTON_TYPES.TEXT}
                onClick={handleFormSubmit}
                isLoading={isLoading}
              >
                {t("Save Configuration")}
              </Button>
            </Responsive.Col>
          ) : formState === FORM_STATES.EDIT ? (
            <>
              <Responsive.Col sm={6}>
                <Button
                  type="button"
                  buttonColor={BUTTON_COLORS.PRIMARY}
                  buttonSize={BUTTON_SIZES.NORMAL}
                  buttonType={BUTTON_TYPES.TEXT_ICON}
                  prefixIcon={<ConfirmSvg />}
                  onClick={handleFormSubmit}
                  isLoading={isLoading}
                  isFullWidth
                >
                  {t("Save Changes")}
                </Button>
              </Responsive.Col>
              <Responsive.Col sm={6}>
                <Button
                  type="button"
                  buttonColor={BUTTON_COLORS.SECONDARY}
                  buttonSize={BUTTON_SIZES.NORMAL}
                  buttonType={BUTTON_TYPES.TEXT_ICON}
                  prefixIcon={<CancelSvg />}
                  onClick={handleEditConfiguration}
                  isLoading={isLoading}
                  isFullWidth
                >
                  {t("Cancel")}
                </Button>
              </Responsive.Col>
            </>
          ) : (
            <Responsive.Col>
              <Button
                type="button"
                buttonColor={BUTTON_COLORS.LIGHT_PRIMARY}
                buttonSize={BUTTON_SIZES.NORMAL}
                buttonType={BUTTON_TYPES.TEXT_ICON}
                prefixIcon={<EditSvg />}
                onClick={handleEditConfiguration}
                isLoading={isLoading}
              >
                {t("Edit Configuration")}
              </Button>
            </Responsive.Col>
          )}
        </Responsive.Row>
      </Responsive>
    </Modal>
  );
};

export default ApplicationFaiSettings;
