import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendFeatureSystemsFailed,
  setFetchFeatureSystemsFailed,
  setSearchFeatureSystemsFailed,
} from "../../store/select-feature-system/select-feature-system.action";
import {
  getAppendFeatureSystemsFailed,
  getFetchFeatureSystemsFailed,
  getSearchFeatureSystemsFailed,
} from "../../store/select-feature-system/select-feature-system.selector";

const SelectFeatureSystemPopup = () => {
  const dispatch = useDispatch();

  const fetchFeatureSystemsFailed = useSelector(getFetchFeatureSystemsFailed);
  const searchFeatureSystemsFailed = useSelector(getSearchFeatureSystemsFailed);
  const appendFeatureSystemsFailed = useSelector(getAppendFeatureSystemsFailed);

  useEffect(() => {
    if (
      fetchFeatureSystemsFailed !== null ||
      searchFeatureSystemsFailed !== null ||
      appendFeatureSystemsFailed !== null
    ) {
      showErrorMessage(
        fetchFeatureSystemsFailed ??
          searchFeatureSystemsFailed ??
          appendFeatureSystemsFailed
      );

      if (fetchFeatureSystemsFailed !== null)
        dispatch(setFetchFeatureSystemsFailed(null));
      if (searchFeatureSystemsFailed !== null)
        dispatch(setSearchFeatureSystemsFailed(null));
      if (appendFeatureSystemsFailed !== null)
        dispatch(setAppendFeatureSystemsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchFeatureSystemsFailed,
    searchFeatureSystemsFailed,
    appendFeatureSystemsFailed,
  ]);

  return <></>;
};

export default SelectFeatureSystemPopup;
