import { createAction } from "../../utils/store.utils";

import SELECT_PRODUCT_ACTION_TYPES from "./select-product.type";

export const setProducts = (products) =>
  createAction(SELECT_PRODUCT_ACTION_TYPES.SET_PRODUCTS, products);
export const setSearchProducts = (searchProducts) =>
  createAction(SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS, searchProducts);

export const appendProducts = (products) =>
  createAction(SELECT_PRODUCT_ACTION_TYPES.APPEND_PRODUCTS, products);
export const appendSearchProducts = (searchProducts) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.APPEND_SEARCH_PRODUCTS,
    searchProducts
  );

export const setIsProductsHasMore = (isProductsHasMore) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_IS_PRODUCTS_HAS_MORE,
    isProductsHasMore
  );
export const setIsSearchProductsHasMore = (isSearchProductsHasMore) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_IS_SEARCH_PRODUCTS_HAS_MORE,
    isSearchProductsHasMore
  );

export const setFetchProductsParams = (fetchProductsParams) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PARAMS,
    fetchProductsParams
  );
export const setFetchProductsLoading = (fetchProductsLoading) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_LOADING,
    fetchProductsLoading
  );
export const setFetchProductsSuccess = (fetchProductsSuccess) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SUCCESS,
    fetchProductsSuccess
  );
export const setFetchProductsFailed = (fetchProductsFailed) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FAILED,
    fetchProductsFailed
  );

export const setSearchProductsParams = (searchProductsParams) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS_PARAMS,
    searchProductsParams
  );
export const setSearchProductsLoading = (searchProductsLoading) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS_LOADING,
    searchProductsLoading
  );
export const setSearchProductsSuccess = (searchProductsSuccess) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS_SUCCESS,
    searchProductsSuccess
  );
export const setSearchProductsFailed = (searchProductsFailed) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_SEARCH_PRODUCTS_FAILED,
    searchProductsFailed
  );

export const setAppendProductsParams = (appendProductsParams) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_APPEND_PRODUCTS_PARAMS,
    appendProductsParams
  );
export const setAppendProductsLoading = (appendProductsLoading) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_APPEND_PRODUCTS_LOADING,
    appendProductsLoading
  );
export const setAppendProductsSuccess = (appendProductsSuccess) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_APPEND_PRODUCTS_SUCCESS,
    appendProductsSuccess
  );
export const setAppendProductsFailed = (appendProductsFailed) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_APPEND_PRODUCTS_FAILED,
    appendProductsFailed
  );

export const setIsFetchProductsHitted = (isFetchProductsHitted) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_IS_FETCH_PRODUCTS_HITTED,
    isFetchProductsHitted
  );
export const setIsSearchProductsHitted = (isSearchProductsHitted) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_IS_SEARCH_PRODUCTS_HITTED,
    isSearchProductsHitted
  );
export const setIsAppendProductsHitted = (isAppendProductsHitted) =>
  createAction(
    SELECT_PRODUCT_ACTION_TYPES.SET_IS_APPEND_PRODUCTS_HITTED,
    isAppendProductsHitted
  );

export const fetchProductsStart = () =>
  createAction(SELECT_PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START);
export const searchProductsStart = () =>
  createAction(SELECT_PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS_START);
export const appendProductsStart = () =>
  createAction(SELECT_PRODUCT_ACTION_TYPES.APPEND_PRODUCTS_START);

export const resetProductReducer = () =>
  createAction(SELECT_PRODUCT_ACTION_TYPES.RESET_SELECT_PRODUCT_REDUCER);
