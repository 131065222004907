import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getFeatures } from "../../api/feature-system.api";

import SELECT_FEATURE_SYSTEM_ACTION_TYPES from "./select-feature-system.type";
import {
  appendFeatureSystems,
  appendSearchFeatureSystems,
  setAppendFeatureSystemsFailed,
  setAppendFeatureSystemsLoading,
  setAppendFeatureSystemsSuccess,
  setFetchFeatureSystemsFailed,
  setFetchFeatureSystemsLoading,
  setFetchFeatureSystemsSuccess,
  setIsAppendFeatureSystemsHitted,
  setIsFetchFeatureSystemsHitted,
  setIsFeatureSystemsHasMore,
  setIsSearchFeatureSystemsHasMore,
  setIsSearchFeatureSystemsHitted,
  setFeatureSystems,
  setSearchFeatureSystems,
  setSearchFeatureSystemsFailed,
  setSearchFeatureSystemsLoading,
  setSearchFeatureSystemsSuccess,
} from "./select-feature-system.action";
import {
  getAppendFeatureSystemsParams,
  getFetchFeatureSystemsParams,
  getSearchFeatureSystemsParams,
} from "./select-feature-system.selector";

export function* _getFetchFeatureSystems() {
  try {
    const fetchFeatureSystemsParams = yield select(
      getFetchFeatureSystemsParams
    );
    const parameters = getParamsWithDefault(fetchFeatureSystemsParams);

    yield put(setFetchFeatureSystemsLoading(true));

    const {
      meta: { message },
      data: featureSystems,
    } = yield call(getFeatures, parameters);

    yield put(setIsFetchFeatureSystemsHitted(true));
    yield put(
      setIsFeatureSystemsHasMore(Object.keys(featureSystems).length > 0)
    );

    if (parameters.page > 1) {
      yield put(appendFeatureSystems(featureSystems));
    } else {
      yield put(setFeatureSystems(featureSystems));
    }

    yield put(setFetchFeatureSystemsSuccess(message));
    yield put(setFetchFeatureSystemsLoading(false));
  } catch (error) {
    yield put(setFetchFeatureSystemsFailed(error));
    yield put(setFetchFeatureSystemsLoading(false));
  }
}
export function* _getSearchFeatureSystems() {
  try {
    const searchFeatureSystemsParams = yield select(
      getSearchFeatureSystemsParams
    );
    const parameters = getParamsWithDefault(searchFeatureSystemsParams);

    yield put(setSearchFeatureSystemsLoading(true));

    const {
      meta: { message },
      data: featureSystems,
    } = yield call(getFeatures, parameters);

    yield put(setIsSearchFeatureSystemsHitted(true));
    yield put(
      setIsSearchFeatureSystemsHasMore(Object.keys(featureSystems).length > 0)
    );

    if (parameters.page > 1) {
      yield put(appendSearchFeatureSystems(featureSystems));
    } else {
      yield put(setSearchFeatureSystems(featureSystems));
    }

    yield put(setSearchFeatureSystemsSuccess(message));
    yield put(setSearchFeatureSystemsLoading(false));
  } catch (error) {
    yield put(setSearchFeatureSystemsFailed(error));
    yield put(setSearchFeatureSystemsLoading(false));
  }
}
export function* _getAppendFeatureSystems() {
  try {
    const appendFeatureSystemsParams = yield select(
      getAppendFeatureSystemsParams
    );
    const parameters = getParamsWithDefault(appendFeatureSystemsParams);

    yield put(setAppendFeatureSystemsLoading(true));

    const {
      meta: { message },
      data: featureSystems,
    } = yield call(getFeatures, parameters);

    yield put(setIsAppendFeatureSystemsHitted(true));
    yield put(appendFeatureSystems(featureSystems));

    yield put(setAppendFeatureSystemsSuccess(message));
    yield put(setAppendFeatureSystemsLoading(false));
  } catch (error) {
    yield put(setAppendFeatureSystemsFailed(error));
    yield put(setAppendFeatureSystemsLoading(false));
  }
}

export function* onFetchFeatureSystemsStart() {
  yield takeLatest(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.FETCH_FEATURE_SYSTEMS_START,
    _getFetchFeatureSystems
  );
}
export function* onSearchFeatureSystemsStart() {
  yield takeLatest(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SEARCH_FEATURE_SYSTEMS_START,
    _getSearchFeatureSystems
  );
}
export function* onAppendFeatureSystemsStart() {
  yield takeLatest(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.APPEND_FEATURE_SYSTEMS_START,
    _getAppendFeatureSystems
  );
}

export function* selectFeatureSystemSaga() {
  yield all([
    call(onFetchFeatureSystemsStart),
    call(onSearchFeatureSystemsStart),
    call(onAppendFeatureSystemsStart),
  ]);
}
