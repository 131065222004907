import { createSelector } from "reselect";

const integrationSelector = ({ integration }) => integration;

export const getIsModalAutoClose = createSelector(
  [integrationSelector],
  ({ isModalAutoClose }) => isModalAutoClose
);

export const getIntegrations = createSelector(
  [integrationSelector],
  ({ integrations }) => integrations
);
export const getIntegration = createSelector(
  [integrationSelector],
  ({ integration }) => integration
);
export const getQoyodStatus = createSelector(
  [integrationSelector],
  ({ qoyodStatus }) => qoyodStatus
);
export const getQoyodSync = createSelector(
  [integrationSelector],
  ({ qoyodSync }) => qoyodSync
);
export const getGrubtechStatus = createSelector(
  [integrationSelector],
  ({ grubtechStatus }) => grubtechStatus
);
export const getGrubtechSync = createSelector(
  [integrationSelector],
  ({ grubtechSync }) => grubtechSync
);
export const getBallurhStatus = createSelector(
  [integrationSelector],
  ({ ballurhStatus }) => ballurhStatus
);
export const getBallurhGenerate = createSelector(
  [integrationSelector],
  ({ ballurhGenerate }) => ballurhGenerate
);
export const getBallurhSync = createSelector(
  [integrationSelector],
  ({ ballurhSync }) => ballurhSync
);
export const getZatcaSetup = createSelector(
  [integrationSelector],
  ({ zatcaSetup }) => zatcaSetup
);
export const getZatcaRegister = createSelector(
  [integrationSelector],
  ({ zatcaRegister }) => zatcaRegister
);
export const getZatcaStatus = createSelector(
  [integrationSelector],
  ({ zatcaStatus }) => zatcaStatus
);
export const getZatcaSync = createSelector(
  [integrationSelector],
  ({ zatcaSync }) => zatcaSync
);
export const getFaiRegister = createSelector(
  [integrationSelector],
  ({ faiRegister }) => faiRegister
);
export const getFaiRegisterStatus = createSelector(
  [integrationSelector],
  ({ faiRegisterStatus }) => faiRegisterStatus
);
export const getFaiStatus = createSelector(
  [integrationSelector],
  ({ faiStatus }) => faiStatus
);
export const getFaiSync = createSelector(
  [integrationSelector],
  ({ faiSync }) => faiSync
);

export const getIsIntegrationsHasMore = createSelector(
  [integrationSelector],
  ({ isIntegrationsHasMore }) => isIntegrationsHasMore
);

export const getFetchIntegrationsSort = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsSort }) => fetchIntegrationsSort
);
export const getFetchIntegrationsKeyBy = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsKeyBy }) => fetchIntegrationsKeyBy
);
export const getFetchIntegrationsPage = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsPage }) => fetchIntegrationsPage
);
export const getFetchIntegrationsPerPage = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsPerPage }) => fetchIntegrationsPerPage
);
export const getFetchIntegrationsIncludes = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsIncludes }) => fetchIntegrationsIncludes
);
export const getFetchIntegrationsFilterMarketId = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsFilterMarketId }) => fetchIntegrationsFilterMarketId
);
export const getFetchIntegrationsFilterBranchId = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsFilterBranchId }) => fetchIntegrationsFilterBranchId
);
export const getFetchIntegrationsFilterKeys = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsFilterKeys }) => fetchIntegrationsFilterKeys
);
export const getFetchIntegrationsLoading = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsLoading }) => fetchIntegrationsLoading
);
export const getFetchIntegrationsSuccess = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsSuccess }) => fetchIntegrationsSuccess
);
export const getFetchIntegrationsFailed = createSelector(
  [integrationSelector],
  ({ fetchIntegrationsFailed }) => fetchIntegrationsFailed
);

export const getFetchIntegrationLoading = createSelector(
  [integrationSelector],
  ({ fetchIntegrationLoading }) => fetchIntegrationLoading
);
export const getFetchIntegrationSuccess = createSelector(
  [integrationSelector],
  ({ fetchIntegrationSuccess }) => fetchIntegrationSuccess
);
export const getFetchIntegrationFailed = createSelector(
  [integrationSelector],
  ({ fetchIntegrationFailed }) => fetchIntegrationFailed
);

export const getCreateOrUpdateIntegrationLoading = createSelector(
  [integrationSelector],
  ({ createOrUpdateIntegrationLoading }) => createOrUpdateIntegrationLoading
);
export const getCreateOrUpdateIntegrationSuccess = createSelector(
  [integrationSelector],
  ({ createOrUpdateIntegrationSuccess }) => createOrUpdateIntegrationSuccess
);
export const getCreateOrUpdateIntegrationFailed = createSelector(
  [integrationSelector],
  ({ createOrUpdateIntegrationFailed }) => createOrUpdateIntegrationFailed
);

export const getFetchQoyodStatusLoading = createSelector(
  [integrationSelector],
  ({ fetchQoyodStatusLoading }) => fetchQoyodStatusLoading
);
export const getFetchQoyodStatusSuccess = createSelector(
  [integrationSelector],
  ({ fetchQoyodStatusSuccess }) => fetchQoyodStatusSuccess
);
export const getFetchQoyodStatusFailed = createSelector(
  [integrationSelector],
  ({ fetchQoyodStatusFailed }) => fetchQoyodStatusFailed
);

export const getSyncQoyodLoading = createSelector(
  [integrationSelector],
  ({ syncQoyodLoading }) => syncQoyodLoading
);
export const getSyncQoyodSuccess = createSelector(
  [integrationSelector],
  ({ syncQoyodSuccess }) => syncQoyodSuccess
);
export const getSyncQoyodFailed = createSelector(
  [integrationSelector],
  ({ syncQoyodFailed }) => syncQoyodFailed
);

export const getFetchGrubtechStatusLoading = createSelector(
  [integrationSelector],
  ({ fetchGrubtechStatusLoading }) => fetchGrubtechStatusLoading
);
export const getFetchGrubtechStatusSuccess = createSelector(
  [integrationSelector],
  ({ fetchGrubtechStatusSuccess }) => fetchGrubtechStatusSuccess
);
export const getFetchGrubtechStatusFailed = createSelector(
  [integrationSelector],
  ({ fetchGrubtechStatusFailed }) => fetchGrubtechStatusFailed
);

export const getSyncGrubtechLoading = createSelector(
  [integrationSelector],
  ({ syncGrubtechLoading }) => syncGrubtechLoading
);
export const getSyncGrubtechSuccess = createSelector(
  [integrationSelector],
  ({ syncGrubtechSuccess }) => syncGrubtechSuccess
);
export const getSyncGrubtechFailed = createSelector(
  [integrationSelector],
  ({ syncGrubtechFailed }) => syncGrubtechFailed
);

export const getFetchBallurhStatusLoading = createSelector(
  [integrationSelector],
  ({ fetchBallurhStatusLoading }) => fetchBallurhStatusLoading
);
export const getFetchBallurhStatusSuccess = createSelector(
  [integrationSelector],
  ({ fetchBallurhStatusSuccess }) => fetchBallurhStatusSuccess
);
export const getFetchBallurhStatusFailed = createSelector(
  [integrationSelector],
  ({ fetchBallurhStatusFailed }) => fetchBallurhStatusFailed
);

export const getGenerateBallurhLoading = createSelector(
  [integrationSelector],
  ({ generateBallurhLoading }) => generateBallurhLoading
);
export const getGenerateBallurhSuccess = createSelector(
  [integrationSelector],
  ({ generateBallurhSuccess }) => generateBallurhSuccess
);
export const getGenerateBallurhFailed = createSelector(
  [integrationSelector],
  ({ generateBallurhFailed }) => generateBallurhFailed
);

export const getSyncBallurhLoading = createSelector(
  [integrationSelector],
  ({ syncBallurhLoading }) => syncBallurhLoading
);
export const getSyncBallurhSuccess = createSelector(
  [integrationSelector],
  ({ syncBallurhSuccess }) => syncBallurhSuccess
);
export const getSyncBallurhFailed = createSelector(
  [integrationSelector],
  ({ syncBallurhFailed }) => syncBallurhFailed
);

export const getSetupZatcaLoading = createSelector(
  [integrationSelector],
  ({ setupZatcaLoading }) => setupZatcaLoading
);
export const getSetupZatcaSuccess = createSelector(
  [integrationSelector],
  ({ setupZatcaSuccess }) => setupZatcaSuccess
);
export const getSetupZatcaFailed = createSelector(
  [integrationSelector],
  ({ setupZatcaFailed }) => setupZatcaFailed
);

export const getRegisterZatcaLoading = createSelector(
  [integrationSelector],
  ({ registerZatcaLoading }) => registerZatcaLoading
);
export const getRegisterZatcaSuccess = createSelector(
  [integrationSelector],
  ({ registerZatcaSuccess }) => registerZatcaSuccess
);
export const getRegisterZatcaFailed = createSelector(
  [integrationSelector],
  ({ registerZatcaFailed }) => registerZatcaFailed
);

export const getFetchZatcaStatusLoading = createSelector(
  [integrationSelector],
  ({ fetchZatcaStatusLoading }) => fetchZatcaStatusLoading
);
export const getFetchZatcaStatusSuccess = createSelector(
  [integrationSelector],
  ({ fetchZatcaStatusSuccess }) => fetchZatcaStatusSuccess
);
export const getFetchZatcaStatusFailed = createSelector(
  [integrationSelector],
  ({ fetchZatcaStatusFailed }) => fetchZatcaStatusFailed
);

export const getSyncZatcaLoading = createSelector(
  [integrationSelector],
  ({ syncZatcaLoading }) => syncZatcaLoading
);
export const getSyncZatcaSuccess = createSelector(
  [integrationSelector],
  ({ syncZatcaSuccess }) => syncZatcaSuccess
);
export const getSyncZatcaFailed = createSelector(
  [integrationSelector],
  ({ syncZatcaFailed }) => syncZatcaFailed
);

export const getSetupFaiLoading = createSelector(
  [integrationSelector],
  ({ setupFaiLoading }) => setupFaiLoading
);
export const getSetupFaiSuccess = createSelector(
  [integrationSelector],
  ({ setupFaiSuccess }) => setupFaiSuccess
);
export const getSetupFaiFailed = createSelector(
  [integrationSelector],
  ({ setupFaiFailed }) => setupFaiFailed
);

export const getRegisterFaiLoading = createSelector(
  [integrationSelector],
  ({ registerFaiLoading }) => registerFaiLoading
);
export const getRegisterFaiSuccess = createSelector(
  [integrationSelector],
  ({ registerFaiSuccess }) => registerFaiSuccess
);
export const getRegisterFaiFailed = createSelector(
  [integrationSelector],
  ({ registerFaiFailed }) => registerFaiFailed
);

export const getFetchRegisterFaiStatusLoading = createSelector(
  [integrationSelector],
  ({ fetchRegisterFaiStatusLoading }) => fetchRegisterFaiStatusLoading
);
export const getFetchRegisterFaiStatusSuccess = createSelector(
  [integrationSelector],
  ({ fetchRegisterFaiStatusSuccess }) => fetchRegisterFaiStatusSuccess
);
export const getFetchRegisterFaiStatusFailed = createSelector(
  [integrationSelector],
  ({ fetchRegisterFaiStatusFailed }) => fetchRegisterFaiStatusFailed
);

export const getFetchFaiStatusLoading = createSelector(
  [integrationSelector],
  ({ fetchFaiStatusLoading }) => fetchFaiStatusLoading
);
export const getFetchFaiStatusSuccess = createSelector(
  [integrationSelector],
  ({ fetchFaiStatusSuccess }) => fetchFaiStatusSuccess
);
export const getFetchFaiStatusFailed = createSelector(
  [integrationSelector],
  ({ fetchFaiStatusFailed }) => fetchFaiStatusFailed
);

export const getSyncFaiLoading = createSelector(
  [integrationSelector],
  ({ syncFaiLoading }) => syncFaiLoading
);
export const getSyncFaiSuccess = createSelector(
  [integrationSelector],
  ({ syncFaiSuccess }) => syncFaiSuccess
);
export const getSyncFaiFailed = createSelector(
  [integrationSelector],
  ({ syncFaiFailed }) => syncFaiFailed
);

export const getIsFetchIntegrationsHitted = createSelector(
  [integrationSelector],
  ({ isFetchIntegrationsHitted }) => isFetchIntegrationsHitted
);
export const getIsFetchIntegrationHitted = createSelector(
  [integrationSelector],
  ({ isFetchIntegrationHitted }) => isFetchIntegrationHitted
);
export const getIsCreateOrUpdateIntegrationHitted = createSelector(
  [integrationSelector],
  ({ isCreateOrUpdateIntegrationHitted }) => isCreateOrUpdateIntegrationHitted
);
export const getIsFetchQoyodStatusHitted = createSelector(
  [integrationSelector],
  ({ isFetchQoyodStatusHitted }) => isFetchQoyodStatusHitted
);
export const getIsSyncQoyodHitted = createSelector(
  [integrationSelector],
  ({ isSyncQoyodHitted }) => isSyncQoyodHitted
);
export const getIsFetchGrubtechStatusHitted = createSelector(
  [integrationSelector],
  ({ isFetchGrubtechStatusHitted }) => isFetchGrubtechStatusHitted
);
export const getIsSyncGrubtechHitted = createSelector(
  [integrationSelector],
  ({ isSyncGrubtechHitted }) => isSyncGrubtechHitted
);
export const getIsFetchBallurhStatusHitted = createSelector(
  [integrationSelector],
  ({ isFetchBallurhStatusHitted }) => isFetchBallurhStatusHitted
);
export const getIsGenerateBallurhHitted = createSelector(
  [integrationSelector],
  ({ isGenerateBallurhHitted }) => isGenerateBallurhHitted
);
export const getIsSyncBallurhHitted = createSelector(
  [integrationSelector],
  ({ isSyncBallurhHitted }) => isSyncBallurhHitted
);
export const getIsSetupZatcaHitted = createSelector(
  [integrationSelector],
  ({ isSetupZatcaHitted }) => isSetupZatcaHitted
);
export const getIsRegisterZatcaHitted = createSelector(
  [integrationSelector],
  ({ isRegisterZatcaHitted }) => isRegisterZatcaHitted
);
export const getIsFetchZatcaStatusHitted = createSelector(
  [integrationSelector],
  ({ isFetchZatcaStatusHitted }) => isFetchZatcaStatusHitted
);
export const getIsSyncZatcaHitted = createSelector(
  [integrationSelector],
  ({ isSyncZatcaHitted }) => isSyncZatcaHitted
);
export const getIsSetupFaiHitted = createSelector(
  [integrationSelector],
  ({ isSetupFaiHitted }) => isSetupFaiHitted
);
export const getIsRegisterFaiHitted = createSelector(
  [integrationSelector],
  ({ isRegisterFaiHitted }) => isRegisterFaiHitted
);
export const getIsFetchRegisterFaiStatusHitted = createSelector(
  [integrationSelector],
  ({ isFetchRegisterFaiStatusHitted }) => isFetchRegisterFaiStatusHitted
);
export const getIsFetchFaiStatusHitted = createSelector(
  [integrationSelector],
  ({ isFetchFaiStatusHitted }) => isFetchFaiStatusHitted
);
export const getIsSyncFaiHitted = createSelector(
  [integrationSelector],
  ({ isSyncFaiHitted }) => isSyncFaiHitted
);
