import apiService from "./api";

export const getRestrictionNotices = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/restriction-notices", params }))
    .data;
export const getRestrictionNotice = async (restrictionNoticeId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/restriction-notices/${restrictionNoticeId}`,
    })
  ).data;
export const createRestrictionNotice = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/restriction-notices", request }))
    .data;
export const updateRestrictionNotice = async (restrictionNoticeId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/restriction-notices/${restrictionNoticeId}`,
      request,
    })
  ).data;
export const deleteRestrictionNotice = async (restrictionNoticeId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/restriction-notices/${restrictionNoticeId}`,
    })
  ).data;
