const RESTRICTION_NOTICE_ACTION_TYPES = {
  RESET_RESTRICTION_NOTICE_REDUCER:
    "restriction-notice/RESET_RESTRICTION_NOTICE_REDUCER",

  SET_RESTRICTION_NOTICES: "restriction-notice/SET_RESTRICTION_NOTICES",
  SET_RESTRICTION_NOTICE: "restriction-notice/SET_RESTRICTION_NOTICE",

  SET_IS_RESTRICTION_NOTICES_HAS_MORE:
    "restriction-notice/SET_IS_RESTRICTION_NOTICES_HAS_MORE",

  SET_FETCH_RESTRICTION_NOTICES_SEARCH:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICES_SEARCH",
  SET_FETCH_RESTRICTION_NOTICES_SORT:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICES_SORT",
  SET_FETCH_RESTRICTION_NOTICES_KEY_BY:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICES_KEY_BY",
  SET_FETCH_RESTRICTION_NOTICES_PAGE:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICES_PAGE",
  SET_FETCH_RESTRICTION_NOTICES_PER_PAGE:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICES_PER_PAGE",
  SET_FETCH_RESTRICTION_NOTICES_INCLUDES:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICES_INCLUDES",
  SET_FETCH_RESTRICTION_NOTICES_LOADING:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICES_LOADING",
  SET_FETCH_RESTRICTION_NOTICES_SUCCESS:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICES_SUCCESS",
  SET_FETCH_RESTRICTION_NOTICES_FAILED:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICES_FAILED",

  SET_FETCH_RESTRICTION_NOTICE_LOADING:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICE_LOADING",
  SET_FETCH_RESTRICTION_NOTICE_SUCCESS:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICE_SUCCESS",
  SET_FETCH_RESTRICTION_NOTICE_FAILED:
    "restriction-notice/SET_FETCH_RESTRICTION_NOTICE_FAILED",

  SET_CREATE_RESTRICTION_NOTICE_LOADING:
    "restriction-notice/SET_CREATE_RESTRICTION_NOTICE_LOADING",
  SET_CREATE_RESTRICTION_NOTICE_SUCCESS:
    "restriction-notice/SET_CREATE_RESTRICTION_NOTICE_SUCCESS",
  SET_CREATE_RESTRICTION_NOTICE_FAILED:
    "restriction-notice/SET_CREATE_RESTRICTION_NOTICE_FAILED",

  SET_UPDATE_RESTRICTION_NOTICE_LOADING:
    "restriction-notice/SET_UPDATE_RESTRICTION_NOTICE_LOADING",
  SET_UPDATE_RESTRICTION_NOTICE_SUCCESS:
    "restriction-notice/SET_UPDATE_RESTRICTION_NOTICE_SUCCESS",
  SET_UPDATE_RESTRICTION_NOTICE_FAILED:
    "restriction-notice/SET_UPDATE_RESTRICTION_NOTICE_FAILED",

  SET_DELETE_RESTRICTION_NOTICE_LOADING:
    "restriction-notice/SET_DELETE_RESTRICTION_NOTICE_LOADING",
  SET_DELETE_RESTRICTION_NOTICE_SUCCESS:
    "restriction-notice/SET_DELETE_RESTRICTION_NOTICE_SUCCESS",
  SET_DELETE_RESTRICTION_NOTICE_FAILED:
    "restriction-notice/SET_DELETE_RESTRICTION_NOTICE_FAILED",

  APPEND_RESTRICTION_NOTICES: "restriction-notice/APPEND_RESTRICTION_NOTICES",

  SET_IS_FETCH_RESTRICTION_NOTICES_HITTED:
    "restriction-notice/SET_IS_FETCH_RESTRICTION_NOTICES_HITTED",
  SET_IS_FETCH_RESTRICTION_NOTICE_HITTED:
    "restriction-notice/SET_IS_FETCH_RESTRICTION_NOTICE_HITTED",
  SET_IS_CREATE_RESTRICTION_NOTICE_HITTED:
    "restriction-notice/SET_IS_CREATE_RESTRICTION_NOTICE_HITTED",
  SET_IS_UPDATE_RESTRICTION_NOTICE_HITTED:
    "restriction-notice/SET_IS_UPDATE_RESTRICTION_NOTICE_HITTED",
  SET_IS_DELETE_RESTRICTION_NOTICE_HITTED:
    "restriction-notice/SET_IS_DELETE_RESTRICTION_NOTICE_HITTED",

  FETCH_RESTRICTION_NOTICES_START:
    "restriction-notice/FETCH_RESTRICTION_NOTICES_START",
  FETCH_RESTRICTION_NOTICE_START:
    "restriction-notice/FETCH_RESTRICTION_NOTICE_START",
  CREATE_RESTRICTION_NOTICE_START:
    "restriction-notice/CREATE_RESTRICTION_NOTICE_START",
  UPDATE_RESTRICTION_NOTICE_START:
    "restriction-notice/UPDATE_RESTRICTION_NOTICE_START",
  DELETE_RESTRICTION_NOTICE_START:
    "restriction-notice/DELETE_RESTRICTION_NOTICE_START",
};

export default RESTRICTION_NOTICE_ACTION_TYPES;
