import styled from "styled-components";

export const ApplicationConnectOverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ApplicationConnectOverlayHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;

  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
`;

export const ApplicationConnectOverlayBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.5rem;

  padding: 0 1.5rem;
  margin-bottom: 1.75rem;
  text-align: center;
`;

export const ApplicationConnectOverlayFoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.75rem;

  min-height: 4.5rem;
  padding: 0.75rem 1rem;
  border-top: 1.5px solid #e9e9ee;
  background-color: #f9f9f9;
`;

export const ApplicationConnectOverlaySwitch = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 1.5rem;
  min-width: 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  max-height: 1.5rem;

  svg {
    stroke-width: 2.5;

    path,
    polyline,
    line,
    circle {
      &[fill] {
        fill: #9691ac;
      }
      &[stroke] {
        stroke: #9691ac;
      }
    }
  }
`;

export const ApplicationConnectOverlayLogo = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 4.5rem;
  min-width: 4.5rem;
  max-width: 4.5rem;
  height: 4.5rem;
  min-height: 4.5rem;
  max-height: 4.5rem;

  border: 1.5px solid #e9e9ee;
  border-radius: 50%;
  background-color: #f9f9f9;
`;

export const ApplicationConnectOverlayLogoImage = styled.img`
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  max-height: 3rem;

  object-fit: contain;
`;

export const ApplicationConnectOverlayTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;

  span {
    color: #2900ee;
  }
`;

export const ApplicationConnectOverlayDescription = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;

export const ApplicationConnectOverlayStatusContainer = styled.div`
  flex-grow: 1;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.75rem;
  overflow: hidden;
`;

export const ApplicationConnectOverlayIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;

    stroke-width: 2.5;

    path,
    polyline,
    line,
    circle {
      stroke: #008d0e;
      transition: 0.3s all ease;
    }
  }
`;

export const ApplicationConnectOverlayLabel = styled.p`
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 600;
  color: #0e072f;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`;

export const ApplicationConnectOverlayProgressContainer = styled.div`
  flex-grow: 1;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  overflow: hidden;
`;

export const ApplicationConnectOverlayProgressLabel = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #0e072f;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`;
