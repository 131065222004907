import { createSelector } from "reselect";

const selectFeatureSystemSelector = ({ selectFeatureSystem }) =>
  selectFeatureSystem;

export const getFeatureSystems = createSelector(
  [selectFeatureSystemSelector],
  ({ featureSystems }) => featureSystems
);
export const getSearchFeatureSystems = createSelector(
  [selectFeatureSystemSelector],
  ({ searchFeatureSystems }) => searchFeatureSystems
);

export const getIsFeatureSystemsHasMore = createSelector(
  [selectFeatureSystemSelector],
  ({ isFeatureSystemsHasMore }) => isFeatureSystemsHasMore
);
export const getIsSearchFeatureSystemsHasMore = createSelector(
  [selectFeatureSystemSelector],
  ({ isSearchFeatureSystemsHasMore }) => isSearchFeatureSystemsHasMore
);

export const getFetchFeatureSystemsParams = createSelector(
  [selectFeatureSystemSelector],
  ({ fetchFeatureSystemsParams }) => fetchFeatureSystemsParams
);
export const getFetchFeatureSystemsLoading = createSelector(
  [selectFeatureSystemSelector],
  ({ fetchFeatureSystemsLoading }) => fetchFeatureSystemsLoading
);
export const getFetchFeatureSystemsSuccess = createSelector(
  [selectFeatureSystemSelector],
  ({ fetchFeatureSystemsSuccess }) => fetchFeatureSystemsSuccess
);
export const getFetchFeatureSystemsFailed = createSelector(
  [selectFeatureSystemSelector],
  ({ fetchFeatureSystemsFailed }) => fetchFeatureSystemsFailed
);

export const getSearchFeatureSystemsParams = createSelector(
  [selectFeatureSystemSelector],
  ({ searchFeatureSystemsParams }) => searchFeatureSystemsParams
);
export const getSearchFeatureSystemsLoading = createSelector(
  [selectFeatureSystemSelector],
  ({ searchFeatureSystemsLoading }) => searchFeatureSystemsLoading
);
export const getSearchFeatureSystemsSuccess = createSelector(
  [selectFeatureSystemSelector],
  ({ searchFeatureSystemsSuccess }) => searchFeatureSystemsSuccess
);
export const getSearchFeatureSystemsFailed = createSelector(
  [selectFeatureSystemSelector],
  ({ searchFeatureSystemsFailed }) => searchFeatureSystemsFailed
);

export const getAppendFeatureSystemsParams = createSelector(
  [selectFeatureSystemSelector],
  ({ appendFeatureSystemsParams }) => appendFeatureSystemsParams
);
export const getAppendFeatureSystemsLoading = createSelector(
  [selectFeatureSystemSelector],
  ({ appendFeatureSystemsLoading }) => appendFeatureSystemsLoading
);
export const getAppendFeatureSystemsSuccess = createSelector(
  [selectFeatureSystemSelector],
  ({ appendFeatureSystemsSuccess }) => appendFeatureSystemsSuccess
);
export const getAppendFeatureSystemsFailed = createSelector(
  [selectFeatureSystemSelector],
  ({ appendFeatureSystemsFailed }) => appendFeatureSystemsFailed
);

export const getIsFetchFeatureSystemsHitted = createSelector(
  [selectFeatureSystemSelector],
  ({ isFetchFeatureSystemsHitted }) => isFetchFeatureSystemsHitted
);
export const getIsSearchFeatureSystemsHitted = createSelector(
  [selectFeatureSystemSelector],
  ({ isSearchFeatureSystemsHitted }) => isSearchFeatureSystemsHitted
);
export const getIsAppendFeatureSystemsHitted = createSelector(
  [selectFeatureSystemSelector],
  ({ isAppendFeatureSystemsHitted }) => isAppendFeatureSystemsHitted
);
