import AccountPopup from "../account-popup/account-popup.widget";
import AdvertisementPopup from "../advertisement-popup/advertisement-popup.widget";
import AnnouncementPopup from "../announcement-popup/announcement-popup.widget";
import ApplicationPopup from "../application-popup/application-popup.widget";
import AttendancePopup from "../attendance-popup/attendance-popup.widget";
import AuthenticationPopup from "../authentication-popup/authentication-popup.widget";
import BannerPopup from "../banner-popup/banner-popup.widget";
import BannerTargetPopup from "../banner-target-popup/banner-target-popup.widget";
import BranchPopup from "../branch-popup/branch-popup.widget";
import CardPopup from "../card-popup/card-popup.widget";
import CountryPopup from "../country-popup/country-popup.widget";
import CustomerPopup from "../customer-popup/customer-popup.widget";
import DashboardPopup from "../dashboard-popup/dashboard-popup.widget";
import DiscountPopup from "../discount-popup/discount-popup.widget";
import DriverPopup from "../driver-popup/driver-popup.widget";
import ExportPopup from "../export-popup/export-popup.widget";
import ExportExcelPopup from "../export-excel-popup/export-excel-popup.widget";
import ExportPdfPopup from "../export-pdf-popup/export-pdf-popup.widget";
import ExtendPeriodPopup from "../extend-period-popup/extend-period-popup.widget";
import FeatureSubscriptionPopup from "../feature-subscription-popup/feature-subscription-popup.widget";
import FeatureSystemPopup from "../feature-system-popup/feature-system-popup.widget";
import GatewayPopup from "../gateway-popup/gateway-popup.widget";
import GlobalStatePopup from "../global-state-popup/global-state-popup.widget";
import HeaderBranchPopup from "../header-branch-popup/header-branch-popup.widget";
import IngredientPopup from "../ingredient-popup/ingredient-popup.widget";
import IntegrationPopup from "../integration-popup/integration-popup.widget";
import KitchenPopup from "../kitchen-popup/kitchen-popup.widget";
import MarketPopup from "../market-popup/market-popup.widget";
import MerchantPopup from "../merchant-popup/merchant-popup.widget";
import MsegatPopup from "../msegat-popup/msegat-popup.widget";
import OrderPopup from "../order-popup/order-popup.widget";
import PaymentDevicePopup from "../payment-device-popup/payment-device-popup.widget";
import PaymentMethodPopup from "../payment-method-popup/payment-method-popup.widget";
import PaymentMethodSettingPopup from "../payment-method-setting-popup/payment-method-setting-popup.widget";
import PermissionPopupAdmin from "../permission-popup-admin/permission-popup-admin.widget";
import PermissionPopupMerchant from "../permission-popup-merchant/permission-popup-merchant.widget";
import PermissionPopupSubscriber from "../permission-popup-subscriber/permission-popup-subscriber.widget";
import PrinterPopup from "../printer-popup/printer-popup.widget";
import PrinterTypePopup from "../printer-type-popup/printer-type-popup.widget";
import ProductPopup from "../product-popup/product-popup.widget";
import ProductCategoryPopup from "../product-category-popup/product-category-popup.widget";
import ProductCategorySortPopup from "../product-category-sort-popup/product-category-sort-popup.widget";
import ProductModifierPopup from "../product-modifier-popup/product-modifier-popup.widget";
import PurchasePopup from "../purchase-popup/purchase-popup.widget";
import RecipePopup from "../recipe-popup/recipe-popup.widget";
import RenewalAdminPopup from "../renewal-admin-popup/renewal-admin-popup.widget";
import ReportPopup from "../report-popup/report-popup.widget";
import ReportAttendancePopup from "../report-attendance-popup/report-attendance-popup.widget";
import RestockRequestPopup from "../restock-request-popup/restock-request-popup.widget";
import RestrictionNoticePopup from "../restriction-notice-popup/restriction-notice-popup.widget";
import SchedulePopup from "../schedule-popup/schedule-popup.widget";
import SectorPopup from "../sector-popup/sector-popup.widget";
import SelectBranchPopup from "../select-branch-popup/select-branch-popup.widget";
import SelectCashierPopup from "../select-cashier-popup/select-cashier-popup.widget";
import SelectCountryPopup from "../select-country-popup/select-country-popup.widget";
import SelectCustomerPopup from "../select-customer-popup/select-customer-popup.widget";
import SelectFeatureSystemPopup from "../select-feature-system-popup/select-feature-system-popup.widget";
import SelectIngredientPopup from "../select-ingredient-popup/select-ingredient-popup.widget";
import SelectMarketPopup from "../select-market-popup/select-market-popup.widget";
import SelectPaymentMethodPopup from "../select-payment-method-popup/select-payment-method-popup.widget";
import SelectPermissionAdminPopup from "../select-permission-popup/select-permission-admin-popup.widget";
import SelectPermissionMerchantPopup from "../select-permission-popup/select-permission-merchant-popup.widget";
import SelectPermissionSubscriberPopup from "../select-permission-popup/select-permission-subscriber-popup.widget";
import SelectProductPopup from "../select-product-popup/select-product-popup.widget";
import SelectProductCategoryPopup from "../select-product-category-popup/select-product-category-popup.widget";
import SelectSubscriberPopup from "../select-subscriber-popup/select-subscriber-popup.widget";
import SelectSupplierPopup from "../select-supplier-popup/select-supplier-popup.widget";
import SettingPopup from "../setting-popup/setting-popup.widget";
import SubscriptionPopup from "../subscription-popup/subscription-popup.widget";
import SummaryPopup from "../summary-popup/summary-popup.widget";
import TablePopup from "../table-popup/table-popup.widget";
import TaxPopup from "../tax-popup/tax-popup.widget";
import TopUpPopup from "../top-up-popup/top-up-popup.widget";
import UserAdminPopup from "../user-admin-popup/user-admin-popup.widget";
import UserCustomerPopup from "../user-customer-popup/user-customer-popup.widget";
import UserMerchantPopup from "../user-merchant-popup/user-merchant-popup.widget";
import UserSubscriberPopup from "../user-subscriber-popup/user-subscriber-popup.widget";
import VerificationPopup from "../verification-popup/verification-popup.widget";
import WhatsappPopup from "../whatsapp-popup/whatsapp-popup.widget";

const GlobalPopup = () => {
  return (
    <>
      <AccountPopup />
      <UserAdminPopup />
      <UserCustomerPopup />
      <UserMerchantPopup />
      <UserSubscriberPopup />
      <AdvertisementPopup />
      <AnnouncementPopup />
      <ApplicationPopup />
      <AttendancePopup />
      <AuthenticationPopup />
      <BannerPopup />
      <BannerTargetPopup />
      <BranchPopup />
      <CardPopup />
      <CountryPopup />
      <CustomerPopup />
      <DashboardPopup />
      <DiscountPopup />
      <DriverPopup />
      <ExportPopup />
      <ExportExcelPopup />
      <ExportPdfPopup />
      <ExtendPeriodPopup />
      <FeatureSubscriptionPopup />
      <FeatureSystemPopup />
      <GatewayPopup />
      <GlobalStatePopup />
      <HeaderBranchPopup />
      <IngredientPopup />
      <IntegrationPopup />
      <KitchenPopup />
      <MarketPopup />
      <MerchantPopup />
      <MsegatPopup />
      <OrderPopup />
      <PaymentDevicePopup />
      <PaymentMethodPopup />
      <PaymentMethodSettingPopup />
      <PermissionPopupAdmin />
      <PermissionPopupMerchant />
      <PermissionPopupSubscriber />
      <PrinterPopup />
      <PrinterTypePopup />
      <ProductPopup />
      <ProductCategoryPopup />
      <ProductCategorySortPopup />
      <ProductModifierPopup />
      <PurchasePopup />
      <RecipePopup />
      <RenewalAdminPopup />
      <ReportPopup />
      <ReportAttendancePopup />
      <RestockRequestPopup />
      <RestrictionNoticePopup />
      <SchedulePopup />
      <SectorPopup />
      <SelectBranchPopup />
      <SelectCashierPopup />
      <SelectCountryPopup />
      <SelectCustomerPopup />
      <SelectFeatureSystemPopup />
      <SelectIngredientPopup />
      <SelectMarketPopup />
      <SelectPaymentMethodPopup />
      <SelectPermissionAdminPopup />
      <SelectPermissionMerchantPopup />
      <SelectPermissionSubscriberPopup />
      <SelectProductPopup />
      <SelectProductCategoryPopup />
      <SelectSubscriberPopup />
      <SelectSupplierPopup />
      <SettingPopup />
      <SubscriptionPopup />
      <SummaryPopup />
      <TablePopup />
      <TaxPopup />
      <TopUpPopup />
      <VerificationPopup />
      <WhatsappPopup />
    </>
  );
};

export default GlobalPopup;
