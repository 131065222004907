import styled from "styled-components";

export const ModalDialogContainer = styled.div`
  position: fixed;
  top: 1.5rem;
  left: 50%;
  z-index: 500;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 25rem;
  min-width: 25rem;
  max-width: 25rem;

  border: 1.5px solid #dfdcef;
  border-radius: 1rem;
  background-color: #ffffff;
  overflow: hidden;
`;

export const ModalDialogHead = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1rem 0.5rem 1rem;
`;

export const ModalDialogBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ModalDialogClose = styled.button.attrs({
  type: "button",
})`
  cursor: pointer;
  user-select: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  max-height: 2.5rem;

  border: none;
  border-radius: 0.75rem;
  background-color: #f2f2f4;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e6e6e7;
  }

  svg {
    width: 1rem;
    min-width: 1rem;
    max-width: 1rem;
    height: 1rem;
    min-height: 1rem;
    max-height: 1rem;
    stroke-width: 5;

    path,
    polyline,
    line,
    circle {
      stroke: #9691ac;
    }
  }
`;
