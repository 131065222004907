import apiService from "./api";

export const getFeatures = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/features/system", params })).data;
export const getFeature = async (featureKey) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/features/system/${featureKey}`,
    })
  ).data;
export const updateFeature = async (featureKey, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/features/system/${featureKey}`,
      request,
    })
  ).data;
