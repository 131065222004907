import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getIngredients } from "../../api/ingredient.api";

import SELECT_INGREDIENT_ACTION_TYPES from "./select-ingredient.type";
import {
  appendIngredients,
  appendSearchIngredients,
  setAppendIngredientsFailed,
  setAppendIngredientsLoading,
  setAppendIngredientsSuccess,
  setFetchIngredientsFailed,
  setFetchIngredientsLoading,
  setFetchIngredientsSuccess,
  setIsAppendIngredientsHitted,
  setIsFetchIngredientsHitted,
  setIsIngredientsHasMore,
  setIsSearchIngredientsHasMore,
  setIsSearchIngredientsHitted,
  setIngredients,
  setSearchIngredients,
  setSearchIngredientsFailed,
  setSearchIngredientsLoading,
  setSearchIngredientsSuccess,
} from "./select-ingredient.action";
import {
  getAppendIngredientsParams,
  getFetchIngredientsParams,
  getSearchIngredientsParams,
} from "./select-ingredient.selector";

export function* _getFetchIngredients() {
  try {
    const fetchIngredientsParams = yield select(getFetchIngredientsParams);
    const parameters = getParamsWithDefault(fetchIngredientsParams);

    yield put(setFetchIngredientsLoading(true));

    const {
      meta: { message },
      data: ingredients,
    } = yield call(getIngredients, parameters);

    yield put(setIsFetchIngredientsHitted(true));
    yield put(setIsIngredientsHasMore(Object.keys(ingredients).length > 0));

    if (parameters.page > 1) {
      yield put(appendIngredients(ingredients));
    } else {
      yield put(setIngredients(ingredients));
    }

    yield put(setFetchIngredientsSuccess(message));
    yield put(setFetchIngredientsLoading(false));
  } catch (error) {
    yield put(setFetchIngredientsFailed(error));
    yield put(setFetchIngredientsLoading(false));
  }
}
export function* _getSearchIngredients() {
  try {
    const searchIngredientsParams = yield select(getSearchIngredientsParams);
    const parameters = getParamsWithDefault(searchIngredientsParams);

    yield put(setSearchIngredientsLoading(true));

    const {
      meta: { message },
      data: ingredients,
    } = yield call(getIngredients, parameters);

    yield put(setIsSearchIngredientsHitted(true));
    yield put(
      setIsSearchIngredientsHasMore(Object.keys(ingredients).length > 0)
    );

    if (parameters.page > 1) {
      yield put(appendSearchIngredients(ingredients));
    } else {
      yield put(setSearchIngredients(ingredients));
    }

    yield put(setSearchIngredientsSuccess(message));
    yield put(setSearchIngredientsLoading(false));
  } catch (error) {
    yield put(setSearchIngredientsFailed(error));
    yield put(setSearchIngredientsLoading(false));
  }
}
export function* _getAppendIngredients() {
  try {
    const appendIngredientsParams = yield select(getAppendIngredientsParams);
    const parameters = getParamsWithDefault(appendIngredientsParams);

    yield put(setAppendIngredientsLoading(true));

    const {
      meta: { message },
      data: ingredients,
    } = yield call(getIngredients, parameters);

    yield put(setIsAppendIngredientsHitted(true));
    yield put(appendIngredients(ingredients));

    yield put(setAppendIngredientsSuccess(message));
    yield put(setAppendIngredientsLoading(false));
  } catch (error) {
    yield put(setAppendIngredientsFailed(error));
    yield put(setAppendIngredientsLoading(false));
  }
}

export function* onFetchIngredientsStart() {
  yield takeLatest(
    SELECT_INGREDIENT_ACTION_TYPES.FETCH_INGREDIENTS_START,
    _getFetchIngredients
  );
}
export function* onSearchIngredientsStart() {
  yield takeLatest(
    SELECT_INGREDIENT_ACTION_TYPES.SEARCH_INGREDIENTS_START,
    _getSearchIngredients
  );
}
export function* onAppendIngredientsStart() {
  yield takeLatest(
    SELECT_INGREDIENT_ACTION_TYPES.APPEND_INGREDIENTS_START,
    _getAppendIngredients
  );
}

export function* selectIngredientSaga() {
  yield all([
    call(onFetchIngredientsStart),
    call(onSearchIngredientsStart),
    call(onAppendIngredientsStart),
  ]);
}
