import { Link } from "react-router-dom";
import styled from "styled-components";

export const NotFoundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100vw;
  height: 100vh;
  background-color: #f2f2f4;

  z-index: 10000;
`;

export const NotFoundTitle = styled.h2`
  font-size: 3rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 1rem;
`;

export const NotFoundRedirect = styled(Link)`
  font-size: 1rem;
  text-decoration: underline;
  color: #2900ee;
`;
