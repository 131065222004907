import { createAction } from "../../utils/store.utils";

import SELECT_MARKET_ACTION_TYPES from "./select-market.type";

export const setMarkets = (markets) =>
  createAction(SELECT_MARKET_ACTION_TYPES.SET_MARKETS, markets);
export const setSearchMarkets = (searchMarkets) =>
  createAction(SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS, searchMarkets);

export const appendMarkets = (markets) =>
  createAction(SELECT_MARKET_ACTION_TYPES.APPEND_MARKETS, markets);
export const appendSearchMarkets = (searchMarkets) =>
  createAction(SELECT_MARKET_ACTION_TYPES.APPEND_SEARCH_MARKETS, searchMarkets);

export const setIsMarketsHasMore = (isMarketsHasMore) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_IS_MARKETS_HAS_MORE,
    isMarketsHasMore
  );
export const setIsSearchMarketsHasMore = (isSearchMarketsHasMore) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_IS_SEARCH_MARKETS_HAS_MORE,
    isSearchMarketsHasMore
  );

export const setFetchMarketsParams = (fetchMarketsParams) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_FETCH_MARKETS_PARAMS,
    fetchMarketsParams
  );
export const setFetchMarketsLoading = (fetchMarketsLoading) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_FETCH_MARKETS_LOADING,
    fetchMarketsLoading
  );
export const setFetchMarketsSuccess = (fetchMarketsSuccess) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_FETCH_MARKETS_SUCCESS,
    fetchMarketsSuccess
  );
export const setFetchMarketsFailed = (fetchMarketsFailed) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_FETCH_MARKETS_FAILED,
    fetchMarketsFailed
  );

export const setSearchMarketsParams = (searchMarketsParams) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS_PARAMS,
    searchMarketsParams
  );
export const setSearchMarketsLoading = (searchMarketsLoading) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS_LOADING,
    searchMarketsLoading
  );
export const setSearchMarketsSuccess = (searchMarketsSuccess) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS_SUCCESS,
    searchMarketsSuccess
  );
export const setSearchMarketsFailed = (searchMarketsFailed) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_SEARCH_MARKETS_FAILED,
    searchMarketsFailed
  );

export const setAppendMarketsParams = (appendMarketsParams) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_APPEND_MARKETS_PARAMS,
    appendMarketsParams
  );
export const setAppendMarketsLoading = (appendMarketsLoading) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_APPEND_MARKETS_LOADING,
    appendMarketsLoading
  );
export const setAppendMarketsSuccess = (appendMarketsSuccess) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_APPEND_MARKETS_SUCCESS,
    appendMarketsSuccess
  );
export const setAppendMarketsFailed = (appendMarketsFailed) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_APPEND_MARKETS_FAILED,
    appendMarketsFailed
  );

export const setIsFetchMarketsHitted = (isFetchMarketsHitted) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_IS_FETCH_MARKETS_HITTED,
    isFetchMarketsHitted
  );
export const setIsSearchMarketsHitted = (isSearchMarketsHitted) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_IS_SEARCH_MARKETS_HITTED,
    isSearchMarketsHitted
  );
export const setIsAppendMarketsHitted = (isAppendMarketsHitted) =>
  createAction(
    SELECT_MARKET_ACTION_TYPES.SET_IS_APPEND_MARKETS_HITTED,
    isAppendMarketsHitted
  );

export const fetchMarketsStart = () =>
  createAction(SELECT_MARKET_ACTION_TYPES.FETCH_MARKETS_START);
export const searchMarketsStart = () =>
  createAction(SELECT_MARKET_ACTION_TYPES.SEARCH_MARKETS_START);
export const appendMarketsStart = () =>
  createAction(SELECT_MARKET_ACTION_TYPES.APPEND_MARKETS_START);

export const resetMarketReducer = () =>
  createAction(SELECT_MARKET_ACTION_TYPES.RESET_SELECT_MARKET_REDUCER);
