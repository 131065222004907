import { takeLatest, put, all, call, select } from "redux-saga/effects";

import {
  getFeatures,
  getFeature,
  updateFeature,
} from "../../api/feature-system.api";

import FEATURE_SYSTEM_ACTION_TYPES from "./feature-system.type";
import {
  appendFeatureSystems,
  setFeatureSystems,
  setFeatureSystem,
  setFetchFeatureSystemsFailed,
  setFetchFeatureSystemsLoading,
  setFetchFeatureSystemsPage,
  setFetchFeatureSystemsSuccess,
  setFetchFeatureSystemFailed,
  setFetchFeatureSystemLoading,
  setFetchFeatureSystemSuccess,
  setIsFeatureSystemsHasMore,
  setIsFetchFeatureSystemsHitted,
  setIsFetchFeatureSystemHitted,
  setIsUpdateFeatureSystemHitted,
  setUpdateFeatureSystemFailed,
  setUpdateFeatureSystemLoading,
  setUpdateFeatureSystemSuccess,
} from "./feature-system.action";
import {
  getFetchFeatureSystemsIncludes,
  getFetchFeatureSystemsKeyBy,
  getFetchFeatureSystemsPage,
  getFetchFeatureSystemsPerPage,
  getFetchFeatureSystemsSearch,
  getFetchFeatureSystemsSort,
  getIsFetchFeatureSystemsHitted,
} from "./feature-system.selector";

export function* _getFeatureSystems() {
  try {
    yield put(setFetchFeatureSystemsLoading(true));

    const search = yield select(getFetchFeatureSystemsSearch);
    const sort = yield select(getFetchFeatureSystemsSort);
    const key_by = yield select(getFetchFeatureSystemsKeyBy);
    const page = yield select(getFetchFeatureSystemsPage);
    const per_page = yield select(getFetchFeatureSystemsPerPage);
    const includes = yield select(getFetchFeatureSystemsIncludes);

    const parameters = { search, sort, key_by, page, per_page, includes };

    const {
      meta: { message },
      data: { data: featureSystems },
    } = yield call(getFeatures, parameters);

    yield put(setIsFetchFeatureSystemsHitted(true));
    yield put(setIsFeatureSystemsHasMore(featureSystems.length > 0));

    if (page > 1) {
      yield put(appendFeatureSystems(featureSystems));
    } else {
      yield put(setFeatureSystems(featureSystems));
    }

    yield put(setFetchFeatureSystemsSuccess(message));
    yield put(setFetchFeatureSystemsLoading(false));
  } catch (error) {
    yield put(setFetchFeatureSystemsFailed(error));
    yield put(setFetchFeatureSystemsLoading(false));
  }
}
export function* _getFeatureSystem({ payload: featureSystemId }) {
  try {
    yield put(setFetchFeatureSystemLoading(true));

    const {
      meta: { message },
      data: featureSystem,
    } = yield call(getFeature, featureSystemId);

    yield put(setIsFetchFeatureSystemHitted(true));
    yield put(setFeatureSystem(featureSystem));

    yield put(setFetchFeatureSystemSuccess(message));
    yield put(setFetchFeatureSystemLoading(false));
  } catch (error) {
    yield put(setFetchFeatureSystemFailed(error));
    yield put(setFetchFeatureSystemLoading(false));
  }
}
export function* _updateFeatureSystem({
  payload: { featureSystemId, request },
}) {
  try {
    yield put(setUpdateFeatureSystemLoading(true));

    const {
      meta: { message },
    } = yield call(updateFeature, featureSystemId, request);

    yield put(setIsUpdateFeatureSystemHitted(true));

    const isFetchFeatureSystemsHitted = yield select(
      getIsFetchFeatureSystemsHitted
    );

    if (isFetchFeatureSystemsHitted) {
      yield put(setFetchFeatureSystemsPage(1));
      yield call(_getFeatureSystems);
    }

    yield put(setUpdateFeatureSystemSuccess(message));
    yield put(setUpdateFeatureSystemLoading(false));
  } catch (error) {
    yield put(setUpdateFeatureSystemFailed(error));
    yield put(setUpdateFeatureSystemLoading(false));
  }
}

export function* onFetchFeatureSystemsStart() {
  yield takeLatest(
    FEATURE_SYSTEM_ACTION_TYPES.FETCH_FEATURE_SYSTEMS_START,
    _getFeatureSystems
  );
}
export function* onFetchFeatureSystemStart() {
  yield takeLatest(
    FEATURE_SYSTEM_ACTION_TYPES.FETCH_FEATURE_SYSTEM_START,
    _getFeatureSystem
  );
}
export function* onUpdateFeatureSystemStart() {
  yield takeLatest(
    FEATURE_SYSTEM_ACTION_TYPES.UPDATE_FEATURE_SYSTEM_START,
    _updateFeatureSystem
  );
}

export function* featureSystemSaga() {
  yield all([
    call(onFetchFeatureSystemsStart),
    call(onFetchFeatureSystemStart),
    call(onUpdateFeatureSystemStart),
  ]);
}
