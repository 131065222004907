import { createAction } from "../../utils/store.utils";

import SELECT_SUPPLIER_ACTION_TYPES from "./select-supplier.type";

export const setSuppliers = (suppliers) =>
  createAction(SELECT_SUPPLIER_ACTION_TYPES.SET_SUPPLIERS, suppliers);
export const setSearchSuppliers = (searchSuppliers) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS,
    searchSuppliers
  );

export const appendSuppliers = (suppliers) =>
  createAction(SELECT_SUPPLIER_ACTION_TYPES.APPEND_SUPPLIERS, suppliers);
export const appendSearchSuppliers = (searchSuppliers) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.APPEND_SEARCH_SUPPLIERS,
    searchSuppliers
  );

export const setIsSuppliersHasMore = (isSuppliersHasMore) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_IS_SUPPLIERS_HAS_MORE,
    isSuppliersHasMore
  );
export const setIsSearchSuppliersHasMore = (isSearchSuppliersHasMore) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_IS_SEARCH_SUPPLIERS_HAS_MORE,
    isSearchSuppliersHasMore
  );

export const setFetchSuppliersParams = (fetchSuppliersParams) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_PARAMS,
    fetchSuppliersParams
  );
export const setFetchSuppliersLoading = (fetchSuppliersLoading) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_LOADING,
    fetchSuppliersLoading
  );
export const setFetchSuppliersSuccess = (fetchSuppliersSuccess) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_SUCCESS,
    fetchSuppliersSuccess
  );
export const setFetchSuppliersFailed = (fetchSuppliersFailed) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_FETCH_SUPPLIERS_FAILED,
    fetchSuppliersFailed
  );

export const setSearchSuppliersParams = (searchSuppliersParams) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS_PARAMS,
    searchSuppliersParams
  );
export const setSearchSuppliersLoading = (searchSuppliersLoading) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS_LOADING,
    searchSuppliersLoading
  );
export const setSearchSuppliersSuccess = (searchSuppliersSuccess) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS_SUCCESS,
    searchSuppliersSuccess
  );
export const setSearchSuppliersFailed = (searchSuppliersFailed) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_SEARCH_SUPPLIERS_FAILED,
    searchSuppliersFailed
  );

export const setAppendSuppliersParams = (appendSuppliersParams) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_APPEND_SUPPLIERS_PARAMS,
    appendSuppliersParams
  );
export const setAppendSuppliersLoading = (appendSuppliersLoading) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_APPEND_SUPPLIERS_LOADING,
    appendSuppliersLoading
  );
export const setAppendSuppliersSuccess = (appendSuppliersSuccess) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_APPEND_SUPPLIERS_SUCCESS,
    appendSuppliersSuccess
  );
export const setAppendSuppliersFailed = (appendSuppliersFailed) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_APPEND_SUPPLIERS_FAILED,
    appendSuppliersFailed
  );

export const setIsFetchSuppliersHitted = (isFetchSuppliersHitted) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_IS_FETCH_SUPPLIERS_HITTED,
    isFetchSuppliersHitted
  );
export const setIsSearchSuppliersHitted = (isSearchSuppliersHitted) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_IS_SEARCH_SUPPLIERS_HITTED,
    isSearchSuppliersHitted
  );
export const setIsAppendSuppliersHitted = (isAppendSuppliersHitted) =>
  createAction(
    SELECT_SUPPLIER_ACTION_TYPES.SET_IS_APPEND_SUPPLIERS_HITTED,
    isAppendSuppliersHitted
  );

export const fetchSuppliersStart = () =>
  createAction(SELECT_SUPPLIER_ACTION_TYPES.FETCH_SUPPLIERS_START);
export const searchSuppliersStart = () =>
  createAction(SELECT_SUPPLIER_ACTION_TYPES.SEARCH_SUPPLIERS_START);
export const appendSuppliersStart = () =>
  createAction(SELECT_SUPPLIER_ACTION_TYPES.APPEND_SUPPLIERS_START);

export const resetSupplierReducer = () =>
  createAction(SELECT_SUPPLIER_ACTION_TYPES.RESET_SELECT_SUPPLIER_REDUCER);
