import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { findIntegration } from "../../utils/integration.utils";
import { parseToBoolean } from "../../utils/parser.utils";
import { isEmpty } from "../../utils/validation.utils";

import { INTEGRATION_KEYS } from "../../constants/integration.constant";

import { getCurrentMarketId } from "../../store/global/global.selector";
import { removeCurrentDialog } from "../../store/component/component.action";
import { registerFaiStart } from "../../store/integration/integration.action";
import {
  getFaiRegisterStatus,
  getFetchIntegrationsLoading,
  getFetchRegisterFaiStatusLoading,
  getIntegrations,
  getRegisterFaiLoading,
  getRegisterFaiSuccess,
} from "../../store/integration/integration.selector";

import ApplicationConnectOverlay, {
  APPLICATION_CONNECT_STATES,
} from "../application-connect-overlay/application-connect-overlay.widget";

import FaiImage from "../../assets/images/logos/fai.png";
import FlavoursImage from "../../assets/images/logos/flavours-integration.png";

export const dialogKey = "APPLICATION_FAI_CONNECT";

const ApplicationFaiConnect = () => {
  const dispatch = useDispatch();

  const closeTimeout = useRef(null);

  const currentMarketId = useSelector(getCurrentMarketId);

  const fetchIntegrationsLoading = useSelector(getFetchIntegrationsLoading);
  const integrations = useSelector(getIntegrations);

  const registerFaiLoading = useSelector(getRegisterFaiLoading);
  const registerFaiSuccess = useSelector(getRegisterFaiSuccess);

  const fetchRegisterFaiStatusLoading = useSelector(
    getFetchRegisterFaiStatusLoading
  );
  const faiRegisterStatus = useSelector(getFaiRegisterStatus);

  const [connectState, setConnectState] = useState(null);

  const handleApplicationConnect = () =>
    dispatch(registerFaiStart({ market_id: currentMarketId }));
  const handleDialogClose = () => dispatch(removeCurrentDialog(dialogKey));

  useEffect(() => {
    if (registerFaiLoading) {
      setConnectState(APPLICATION_CONNECT_STATES.CONNECTING);
    } else {
      const companyIdParams = {
        key: INTEGRATION_KEYS.FAI_COMPANY_ID,
        marketId: currentMarketId,
      };
      const companyId = findIntegration(integrations, companyIdParams);

      if (!isEmpty(companyId?.value)) {
        setConnectState(APPLICATION_CONNECT_STATES.CONNECTED);
      } else {
        const completions = !isEmpty(faiRegisterStatus)
          ? Object.values(faiRegisterStatus)
          : [];

        let progressCount = 0;
        let completeCount = 0;

        for (const completion of completions) {
          const { is_configured, is_required } = completion ?? {};

          if (!parseToBoolean(is_required)) continue;
          if (parseToBoolean(is_configured)) progressCount++;
          completeCount++;
        }

        if (progressCount === completeCount && progressCount > 0) {
          setConnectState(APPLICATION_CONNECT_STATES.READY);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations, faiRegisterStatus, registerFaiLoading]);

  useEffect(() => {
    if (registerFaiSuccess !== null) {
      closeTimeout.current = setTimeout(handleDialogClose, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerFaiSuccess]);

  useEffect(() => {
    if (!isEmpty(closeTimeout.current)) {
      clearTimeout(closeTimeout.current);
      closeTimeout.current = setTimeout(handleDialogClose, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchIntegrationsLoading,
    registerFaiLoading,
    fetchRegisterFaiStatusLoading,
  ]);

  return (
    <ApplicationConnectOverlay
      dialogKey={dialogKey}
      connectState={connectState}
      logoPrev={FlavoursImage}
      logoNext={FaiImage}
      name="Fai"
      description="Aut id facere placeat ut provident neque ad consequuntur."
      handleApplicationConnect={handleApplicationConnect}
      isLoading={
        fetchIntegrationsLoading ||
        registerFaiLoading ||
        fetchRegisterFaiStatusLoading
      }
    />
  );
};

export default ApplicationFaiConnect;
