import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getCountries } from "../../api/country.api";

import SELECT_COUNTRY_ACTION_TYPES from "./select-country.type";
import {
  appendCountries,
  appendSearchCountries,
  setAppendCountriesFailed,
  setAppendCountriesLoading,
  setAppendCountriesSuccess,
  setFetchCountriesFailed,
  setFetchCountriesLoading,
  setFetchCountriesSuccess,
  setIsAppendCountriesHitted,
  setIsFetchCountriesHitted,
  setIsCountriesHasMore,
  setIsSearchCountriesHasMore,
  setIsSearchCountriesHitted,
  setCountries,
  setSearchCountries,
  setSearchCountriesFailed,
  setSearchCountriesLoading,
  setSearchCountriesSuccess,
} from "./select-country.action";
import {
  getAppendCountriesParams,
  getFetchCountriesParams,
  getSearchCountriesParams,
} from "./select-country.selector";

export function* _getFetchCountries() {
  try {
    const fetchCountriesParams = yield select(getFetchCountriesParams);
    const parameters = getParamsWithDefault(fetchCountriesParams);

    yield put(setFetchCountriesLoading(true));

    const {
      meta: { message },
      data: countries,
    } = yield call(getCountries, parameters);

    yield put(setIsFetchCountriesHitted(true));
    yield put(setIsCountriesHasMore(Object.keys(countries).length > 0));

    if (parameters.page > 1) {
      yield put(appendCountries(countries));
    } else {
      yield put(setCountries(countries));
    }

    yield put(setFetchCountriesSuccess(message));
    yield put(setFetchCountriesLoading(false));
  } catch (error) {
    yield put(setFetchCountriesFailed(error));
    yield put(setFetchCountriesLoading(false));
  }
}
export function* _getSearchCountries() {
  try {
    const searchCountriesParams = yield select(getSearchCountriesParams);
    const parameters = getParamsWithDefault(searchCountriesParams);

    yield put(setSearchCountriesLoading(true));

    const {
      meta: { message },
      data: countries,
    } = yield call(getCountries, parameters);

    yield put(setIsSearchCountriesHitted(true));
    yield put(setIsSearchCountriesHasMore(Object.keys(countries).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchCountries(countries));
    } else {
      yield put(setSearchCountries(countries));
    }

    yield put(setSearchCountriesSuccess(message));
    yield put(setSearchCountriesLoading(false));
  } catch (error) {
    yield put(setSearchCountriesFailed(error));
    yield put(setSearchCountriesLoading(false));
  }
}
export function* _getAppendCountries() {
  try {
    const appendCountriesParams = yield select(getAppendCountriesParams);
    const parameters = getParamsWithDefault(appendCountriesParams);

    yield put(setAppendCountriesLoading(true));

    const {
      meta: { message },
      data: countries,
    } = yield call(getCountries, parameters);

    yield put(setIsAppendCountriesHitted(true));
    yield put(appendCountries(countries));

    yield put(setAppendCountriesSuccess(message));
    yield put(setAppendCountriesLoading(false));
  } catch (error) {
    yield put(setAppendCountriesFailed(error));
    yield put(setAppendCountriesLoading(false));
  }
}

export function* onFetchCountriesStart() {
  yield takeLatest(
    SELECT_COUNTRY_ACTION_TYPES.FETCH_COUNTRIES_START,
    _getFetchCountries
  );
}
export function* onSearchCountriesStart() {
  yield takeLatest(
    SELECT_COUNTRY_ACTION_TYPES.SEARCH_COUNTRIES_START,
    _getSearchCountries
  );
}
export function* onAppendCountriesStart() {
  yield takeLatest(
    SELECT_COUNTRY_ACTION_TYPES.APPEND_COUNTRIES_START,
    _getAppendCountries
  );
}

export function* selectCountrySaga() {
  yield all([
    call(onFetchCountriesStart),
    call(onSearchCountriesStart),
    call(onAppendCountriesStart),
  ]);
}
