import { LANGUAGE_DATAS, LANGUAGES } from "../../constants/common.constant";

import GLOBAL_ACTION_TYPES from "./global.type";

export const GLOBAL_INITIAL_STATE = {
  isRightToLeft: false,
  isGlobalInitialized: false,
  isGlobalLoading: false,
  currentLanguage: LANGUAGE_DATAS[LANGUAGES.EN],

  masterPermissionId: null,
  secondPermissionId: null,

  masterPermission: null,
  secondPermission: null,

  masterAccessPages: [],
  masterForbidPages: [],
  masterPermitPages: [],

  secondAccessPages: [],
  secondForbidPages: [],
  secondPermitPages: [],

  isMarketAdministrator: false,
  isCenterAdministrator: false,

  currentMarket: null,
  currentBranch: null,
  currentMerchant: null,
  currentSchedules: null,

  currentMarketId: null,
  currentBranchId: null,
  currentMerchantId: null,

  isCurrentKitchenActive: false,

  todaySchedule: null,
  isTodayInSchedule: false,

  todayAttendance: null,
  isTodayHasAttendance: false,

  marketPlans: [],
  branchPlans: [],

  isHasActivePlan: false,
  isHasActiveAdvancedPlan: false,
  isHasActiveBasicPlan: false,
  isHasActiveTrialPlan: false,

  isMarketHasPlan: false,
  isMarketHasAdvancedPlan: false,
  isMarketHasBasicPlan: false,
  isMarketHasTrialPlan: false,

  isBranchHasPlan: false,
  isBranchHasAdvancedPlan: false,
  isBranchHasBasicPlan: false,
  isBranchHasTrialPlan: false,

  planBillingCycles: [],
  globalSubscriptions: {},
  globalFeatureSubscriptions: [],

  globalFeatureSystems: {},
  globalRestrictionNotices: {},
  globalBanners: {},
  globalBannerTargets: {},

  isGlobalSubscriptionsHasMore: true,
  isGlobalFeatureSubscriptionsHasMore: true,

  isGlobalFeatureSystemsHasMore: true,
  isGlobalRestrictionNoticesHasMore: true,
  isGlobalBannersHasMore: true,
  isGlobalBannerTargetsHasMore: true,

  fetchInitializeLoading: true,
  fetchInitializeSuccess: null,
  fetchInitializeFailed: null,

  fetchMasterPermissionLoading: false,
  fetchMasterPermissionSuccess: null,
  fetchMasterPermissionFailed: null,

  fetchSecondPermissionLoading: false,
  fetchSecondPermissionSuccess: null,
  fetchSecondPermissionFailed: null,

  fetchCurrentMarketLoading: false,
  fetchCurrentMarketSuccess: null,
  fetchCurrentMarketFailed: null,

  fetchCurrentBranchLoading: false,
  fetchCurrentBranchSuccess: null,
  fetchCurrentBranchFailed: null,

  fetchCurrentMerchantLoading: false,
  fetchCurrentMerchantSuccess: null,
  fetchCurrentMerchantFailed: null,

  fetchCurrentSchedulesIncludes: null,
  fetchCurrentSchedulesFilterMarketId: null,
  fetchCurrentSchedulesFilterBranchId: null,
  fetchCurrentSchedulesLoading: false,
  fetchCurrentSchedulesSuccess: null,
  fetchCurrentSchedulesFailed: null,

  fetchTodayAttendanceMarketId: null,
  fetchTodayAttendanceBranchId: null,
  fetchTodayAttendanceLoading: false,
  fetchTodayAttendanceSuccess: null,
  fetchTodayAttendanceFailed: null,

  fetchGlobalSubscriptionsSearch: null,
  fetchGlobalSubscriptionsSort: null,
  fetchGlobalSubscriptionsKeyBy: null,
  fetchGlobalSubscriptionsPage: 1,
  fetchGlobalSubscriptionsPerPage: null,
  fetchGlobalSubscriptionsIncludes: null,
  fetchGlobalSubscriptionsLoading: false,
  fetchGlobalSubscriptionsSuccess: null,
  fetchGlobalSubscriptionsFailed: null,

  fetchGlobalFeatureSubscriptionsSearch: null,
  fetchGlobalFeatureSubscriptionsSort: null,
  fetchGlobalFeatureSubscriptionsKeyBy: null,
  fetchGlobalFeatureSubscriptionsPage: 1,
  fetchGlobalFeatureSubscriptionsPerPage: null,
  fetchGlobalFeatureSubscriptionsIncludes: null,
  fetchGlobalFeatureSubscriptionsFilterIsHighlight: null,
  fetchGlobalFeatureSubscriptionsLoading: false,
  fetchGlobalFeatureSubscriptionsSuccess: null,
  fetchGlobalFeatureSubscriptionsFailed: null,

  fetchGlobalFeatureSystemsSearch: null,
  fetchGlobalFeatureSystemsSort: null,
  fetchGlobalFeatureSystemsKeyBy: null,
  fetchGlobalFeatureSystemsPage: 1,
  fetchGlobalFeatureSystemsPerPage: null,
  fetchGlobalFeatureSystemsIncludes: null,
  fetchGlobalFeatureSystemsFilterRoles: null,
  fetchGlobalFeatureSystemsLoading: false,
  fetchGlobalFeatureSystemsSuccess: null,
  fetchGlobalFeatureSystemsFailed: null,

  fetchGlobalRestrictionNoticesSearch: null,
  fetchGlobalRestrictionNoticesSort: null,
  fetchGlobalRestrictionNoticesKeyBy: null,
  fetchGlobalRestrictionNoticesPage: 1,
  fetchGlobalRestrictionNoticesPerPage: null,
  fetchGlobalRestrictionNoticesIncludes: null,
  fetchGlobalRestrictionNoticesLoading: false,
  fetchGlobalRestrictionNoticesSuccess: null,
  fetchGlobalRestrictionNoticesFailed: null,

  fetchGlobalBannersSearch: null,
  fetchGlobalBannersSort: null,
  fetchGlobalBannersKeyBy: null,
  fetchGlobalBannersPage: 1,
  fetchGlobalBannersPerPage: null,
  fetchGlobalBannersIncludes: null,
  fetchGlobalBannersLoading: false,
  fetchGlobalBannersSuccess: null,
  fetchGlobalBannersFailed: null,

  fetchGlobalBannerTargetsSearch: null,
  fetchGlobalBannerTargetsSort: null,
  fetchGlobalBannerTargetsKeyBy: null,
  fetchGlobalBannerTargetsPage: 1,
  fetchGlobalBannerTargetsPerPage: null,
  fetchGlobalBannerTargetsIncludes: null,
  fetchGlobalBannerTargetsFilterRole: null,
  fetchGlobalBannerTargetsLoading: false,
  fetchGlobalBannerTargetsSuccess: null,
  fetchGlobalBannerTargetsFailed: null,

  isFetchInitializeHitted: false,
  isFetchMasterPermissionHitted: false,
  isFetchSecondPermissionHitted: false,
  isFetchCurrentMarketHitted: false,
  isFetchCurrentBranchHitted: false,
  isFetchCurrentMerchantHitted: false,
  isFetchCurrentSchedulesHitted: false,
  isFetchTodayAttendanceHitted: false,
  isFetchGlobalSubscriptionsHitted: false,
  isFetchGlobalFeatureSubscriptionsHitted: false,
  isFetchGlobalFeatureSystemsHitted: false,
  isFetchGlobalRestrictionNoticesHitted: false,
  isFetchGlobalBannersHitted: false,
  isFetchGlobalBannerTargetsHitted: false,
};

export const globalReducer = (state = GLOBAL_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBAL_ACTION_TYPES.SET_IS_RIGHT_TO_LEFT:
      return { ...state, isRightToLeft: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_INITIALIZED:
      return { ...state, isGlobalInitialized: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_LOADING:
      return { ...state, isGlobalLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_LANGUAGE:
      return { ...state, currentLanguage: payload };

    case GLOBAL_ACTION_TYPES.SET_MASTER_PERMISSION_ID:
      return { ...state, masterPermissionId: payload };
    case GLOBAL_ACTION_TYPES.SET_SECOND_PERMISSION_ID:
      return { ...state, secondPermissionId: payload };

    case GLOBAL_ACTION_TYPES.SET_MASTER_PERMISSION:
      return { ...state, masterPermission: payload };
    case GLOBAL_ACTION_TYPES.SET_SECOND_PERMISSION:
      return { ...state, secondPermission: payload };

    case GLOBAL_ACTION_TYPES.SET_MASTER_ACCESS_PAGES:
      return { ...state, masterAccessPages: payload };
    case GLOBAL_ACTION_TYPES.SET_MASTER_FORBID_PAGES:
      return { ...state, masterForbidPages: payload };
    case GLOBAL_ACTION_TYPES.SET_MASTER_PERMIT_PAGES:
      return { ...state, masterPermitPages: payload };

    case GLOBAL_ACTION_TYPES.SET_SECOND_ACCESS_PAGES:
      return { ...state, secondAccessPages: payload };
    case GLOBAL_ACTION_TYPES.SET_SECOND_FORBID_PAGES:
      return { ...state, secondForbidPages: payload };
    case GLOBAL_ACTION_TYPES.SET_SECOND_PERMIT_PAGES:
      return { ...state, secondPermitPages: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_ADMINISTRATOR:
      return { ...state, isMarketAdministrator: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_CENTER_ADMINISTRATOR:
      return { ...state, isCenterAdministrator: payload };

    case GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET:
      return { ...state, currentMarket: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH:
      return { ...state, currentBranch: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT:
      return { ...state, currentMerchant: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_SCHEDULES:
      return { ...state, currentSchedules: payload };

    case GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET_ID:
      return { ...state, currentMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH_ID:
      return { ...state, currentBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT_ID:
      return { ...state, currentMerchantId: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_CURRENT_KITCHEN_ACTIVE:
      return { ...state, isCurrentKitchenActive: payload };

    case GLOBAL_ACTION_TYPES.SET_TODAY_SCHEDULE:
      return { ...state, todaySchedule: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_TODAY_IN_SCHEDULE:
      return { ...state, isTodayInSchedule: payload };

    case GLOBAL_ACTION_TYPES.SET_TODAY_ATTENDANCE:
      return { ...state, todayAttendance: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_TODAY_HAS_ATTENDANCE:
      return { ...state, isTodayHasAttendance: payload };

    case GLOBAL_ACTION_TYPES.SET_MARKET_PLANS:
      return { ...state, marketPlans: payload };
    case GLOBAL_ACTION_TYPES.SET_BRANCH_PLANS:
      return { ...state, branchPlans: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_PLAN:
      return { ...state, isHasActivePlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_ADVANCED_PLAN:
      return { ...state, isHasActiveAdvancedPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_BASIC_PLAN:
      return { ...state, isHasActiveBasicPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_TRIAL_PLAN:
      return { ...state, isHasActiveTrialPlan: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_PLAN:
      return { ...state, isMarketHasPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_ADVANCED_PLAN:
      return { ...state, isMarketHasAdvancedPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_BASIC_PLAN:
      return { ...state, isMarketHasBasicPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_TRIAL_PLAN:
      return { ...state, isMarketHasTrialPlan: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_PLAN:
      return { ...state, isBranchHasPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_ADVANCED_PLAN:
      return { ...state, isBranchHasAdvancedPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_BASIC_PLAN:
      return { ...state, isBranchHasBasicPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_TRIAL_PLAN:
      return { ...state, isBranchHasTrialPlan: payload };

    case GLOBAL_ACTION_TYPES.SET_GLOBAL_SUBSCRIPTIONS:
      return { ...state, globalSubscriptions: payload };
    case GLOBAL_ACTION_TYPES.SET_GLOBAL_FEATURE_SUBSCRIPTIONS:
      return { ...state, globalFeatureSubscriptions: payload };

    case GLOBAL_ACTION_TYPES.SET_GLOBAL_FEATURE_SYSTEMS:
      return { ...state, globalFeatureSystems: payload };
    case GLOBAL_ACTION_TYPES.SET_GLOBAL_RESTRICTION_NOTICES:
      return { ...state, globalRestrictionNotices: payload };
    case GLOBAL_ACTION_TYPES.SET_GLOBAL_BANNERS:
      return { ...state, globalBanners: payload };
    case GLOBAL_ACTION_TYPES.SET_GLOBAL_BANNER_TARGETS:
      return { ...state, globalBannerTargets: payload };

    case GLOBAL_ACTION_TYPES.APPEND_GLOBAL_SUBSCRIPTIONS:
      return {
        ...state,
        globalSubscriptions: { ...state.globalSubscriptions, ...payload },
      };
    case GLOBAL_ACTION_TYPES.APPEND_GLOBAL_FEATURE_SUBSCRIPTIONS:
      return {
        ...state,
        globalFeatureSubscriptions: [
          ...state.globalFeatureSubscriptions,
          ...payload,
        ],
      };
    case GLOBAL_ACTION_TYPES.APPEND_GLOBAL_FEATURE_SYSTEMS:
      return {
        ...state,
        globalFeatureSystems: { ...state.globalFeatureSystems, ...payload },
      };
    case GLOBAL_ACTION_TYPES.APPEND_GLOBAL_RESTRICTION_NOTICES:
      return {
        ...state,
        globalRestrictionNotices: {
          ...state.globalRestrictionNotices,
          ...payload,
        },
      };
    case GLOBAL_ACTION_TYPES.APPEND_GLOBAL_BANNERS:
      return {
        ...state,
        globalBanners: { ...state.globalBanners, ...payload },
      };
    case GLOBAL_ACTION_TYPES.APPEND_GLOBAL_BANNER_TARGETS:
      return {
        ...state,
        globalBannerTargets: { ...state.globalBannerTargets, ...payload },
      };

    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_SUBSCRIPTIONS_HAS_MORE:
      return { ...state, isGlobalSubscriptionsHasMore: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_FEATURE_SUBSCRIPTIONS_HAS_MORE:
      return { ...state, isGlobalFeatureSubscriptionsHasMore: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_FEATURE_SYSTEMS_HAS_MORE:
      return { ...state, isGlobalFeatureSystemsHasMore: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_RESTRICTION_NOTICES_HAS_MORE:
      return { ...state, isGlobalRestrictionNoticesHasMore: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_BANNERS_HAS_MORE:
      return { ...state, isGlobalBannersHasMore: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_BANNER_TARGETS_HAS_MORE:
      return { ...state, isGlobalBannerTargetsHasMore: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_INITIALIZE_LOADING:
      return { ...state, fetchInitializeLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_INITIALIZE_SUCCESS:
      return { ...state, fetchInitializeSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_INITIALIZE_FAILED:
      return { ...state, fetchInitializeFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_MASTER_PERMISSION_LOADING:
      return { ...state, fetchMasterPermissionLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_MASTER_PERMISSION_SUCCESS:
      return { ...state, fetchMasterPermissionSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_MASTER_PERMISSION_FAILED:
      return { ...state, fetchMasterPermissionFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_SECOND_PERMISSION_LOADING:
      return { ...state, fetchSecondPermissionLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_SECOND_PERMISSION_SUCCESS:
      return { ...state, fetchSecondPermissionSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_SECOND_PERMISSION_FAILED:
      return { ...state, fetchSecondPermissionFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_LOADING:
      return { ...state, fetchCurrentMarketLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_SUCCESS:
      return { ...state, fetchCurrentMarketSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_FAILED:
      return { ...state, fetchCurrentMarketFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_LOADING:
      return { ...state, fetchCurrentBranchLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_SUCCESS:
      return { ...state, fetchCurrentBranchSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_FAILED:
      return { ...state, fetchCurrentBranchFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_LOADING:
      return { ...state, fetchCurrentMerchantLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_SUCCESS:
      return { ...state, fetchCurrentMerchantSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_FAILED:
      return { ...state, fetchCurrentMerchantFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_INCLUDES:
      return { ...state, fetchCurrentSchedulesIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID:
      return { ...state, fetchCurrentSchedulesFilterMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID:
      return { ...state, fetchCurrentSchedulesFilterBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_LOADING:
      return { ...state, fetchCurrentSchedulesLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_SUCCESS:
      return { ...state, fetchCurrentSchedulesSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FAILED:
      return { ...state, fetchCurrentSchedulesFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_MARKET_ID:
      return { ...state, fetchTodayAttendanceMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID:
      return { ...state, fetchTodayAttendanceBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_LOADING:
      return { ...state, fetchTodayAttendanceLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_SUCCESS:
      return { ...state, fetchTodayAttendanceSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_FAILED:
      return { ...state, fetchTodayAttendanceFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SEARCH:
      return { ...state, fetchGlobalSubscriptionsSearch: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SORT:
      return { ...state, fetchGlobalSubscriptionsSort: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_KEY_BY:
      return { ...state, fetchGlobalSubscriptionsKeyBy: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_PAGE:
      return { ...state, fetchGlobalSubscriptionsPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_PER_PAGE:
      return { ...state, fetchGlobalSubscriptionsPerPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_INCLUDES:
      return { ...state, fetchGlobalSubscriptionsIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_LOADING:
      return { ...state, fetchGlobalSubscriptionsLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SUCCESS:
      return { ...state, fetchGlobalSubscriptionsSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_FAILED:
      return { ...state, fetchGlobalSubscriptionsFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SEARCH:
      return { ...state, fetchGlobalFeatureSubscriptionsSearch: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SORT:
      return { ...state, fetchGlobalFeatureSubscriptionsSort: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_KEY_BY:
      return { ...state, fetchGlobalFeatureSubscriptionsKeyBy: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_PAGE:
      return { ...state, fetchGlobalFeatureSubscriptionsPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_PER_PAGE:
      return { ...state, fetchGlobalFeatureSubscriptionsPerPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_INCLUDES:
      return { ...state, fetchGlobalFeatureSubscriptionsIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_FILTER_IS_HIGHLIGHT:
      return {
        ...state,
        fetchGlobalFeatureSubscriptionsFilterIsHighlight: payload,
      };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_LOADING:
      return { ...state, fetchGlobalFeatureSubscriptionsLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SUCCESS:
      return { ...state, fetchGlobalFeatureSubscriptionsSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_FAILED:
      return { ...state, fetchGlobalFeatureSubscriptionsFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_SEARCH:
      return { ...state, fetchGlobalFeatureSystemsSearch: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_SORT:
      return { ...state, fetchGlobalFeatureSystemsSort: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_KEY_BY:
      return { ...state, fetchGlobalFeatureSystemsKeyBy: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_PAGE:
      return { ...state, fetchGlobalFeatureSystemsPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_PER_PAGE:
      return { ...state, fetchGlobalFeatureSystemsPerPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_INCLUDES:
      return { ...state, fetchGlobalFeatureSystemsIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_LOADING:
      return { ...state, fetchGlobalFeatureSystemsLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_SUCCESS:
      return { ...state, fetchGlobalFeatureSystemsSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_FAILED:
      return { ...state, fetchGlobalFeatureSystemsFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_SEARCH:
      return { ...state, fetchGlobalRestrictionNoticesSearch: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_SORT:
      return { ...state, fetchGlobalRestrictionNoticesSort: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_KEY_BY:
      return { ...state, fetchGlobalRestrictionNoticesKeyBy: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_PAGE:
      return { ...state, fetchGlobalRestrictionNoticesPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_PER_PAGE:
      return { ...state, fetchGlobalRestrictionNoticesPerPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_INCLUDES:
      return { ...state, fetchGlobalRestrictionNoticesIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_LOADING:
      return { ...state, fetchGlobalRestrictionNoticesLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_SUCCESS:
      return { ...state, fetchGlobalRestrictionNoticesSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_FAILED:
      return { ...state, fetchGlobalRestrictionNoticesFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_SEARCH:
      return { ...state, fetchGlobalBannersSearch: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_SORT:
      return { ...state, fetchGlobalBannersSort: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_KEY_BY:
      return { ...state, fetchGlobalBannersKeyBy: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_PAGE:
      return { ...state, fetchGlobalBannersPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_PER_PAGE:
      return { ...state, fetchGlobalBannersPerPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_INCLUDES:
      return { ...state, fetchGlobalBannersIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_LOADING:
      return { ...state, fetchGlobalBannersLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_SUCCESS:
      return { ...state, fetchGlobalBannersSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_FAILED:
      return { ...state, fetchGlobalBannersFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_SEARCH:
      return { ...state, fetchGlobalBannerTargetsSearch: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_SORT:
      return { ...state, fetchGlobalBannerTargetsSort: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_KEY_BY:
      return { ...state, fetchGlobalBannerTargetsKeyBy: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_PAGE:
      return { ...state, fetchGlobalBannerTargetsPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_PER_PAGE:
      return { ...state, fetchGlobalBannerTargetsPerPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_INCLUDES:
      return { ...state, fetchGlobalBannerTargetsIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_FILTER_ROLE:
      return {
        ...state,
        fetchGlobalBannerTargetsFilterRole: payload,
      };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_LOADING:
      return { ...state, fetchGlobalBannerTargetsLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_SUCCESS:
      return { ...state, fetchGlobalBannerTargetsSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_FAILED:
      return { ...state, fetchGlobalBannerTargetsFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_INITIALIZE_HITTED:
      return { ...state, isFetchInitializeHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_MASTER_PERMISSION_HITTED:
      return { ...state, isFetchMasterPermissionHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_SECOND_PERMISSION_HITTED:
      return { ...state, isFetchSecondPermissionHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MARKET_HITTED:
      return { ...state, isFetchCurrentMarketHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_BRANCH_HITTED:
      return { ...state, isFetchCurrentBranchHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MERCHANT_HITTED:
      return { ...state, isFetchCurrentMerchantHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_SCHEDULES_HITTED:
      return { ...state, isFetchCurrentSchedulesHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_TODAY_ATTENDANCE_HITTED:
      return { ...state, isFetchTodayAttendanceHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_SUBSCRIPTIONS_HITTED:
      return { ...state, isFetchGlobalSubscriptionsHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_HITTED:
      return { ...state, isFetchGlobalFeatureSubscriptionsHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_FEATURE_SYSTEMS_HITTED:
      return { ...state, isFetchGlobalFeatureSystemsHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_RESTRICTION_NOTICES_HITTED:
      return { ...state, isFetchGlobalRestrictionNoticesHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_BANNERS_HITTED:
      return { ...state, isFetchGlobalBannersHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_BANNER_TARGETS_HITTED:
      return { ...state, isFetchGlobalBannerTargetsHitted: payload };

    case GLOBAL_ACTION_TYPES.RESET_GLOBAL_REDUCER:
      return GLOBAL_INITIAL_STATE;
    default:
      return state;
  }
};
