const COMPONENT_ACTION_TYPES = {
  RESET_COMPONENT_REDUCER: "component/RESET_COMPONENT_REDUCER",

  SET_OVERLAY_POSITION: "component/SET_OVERLAY_POSITION",
  SET_SCREEN_WIDTH: "component/SET_SCREEN_WIDTH",
  SET_TOPNAV_HEIGHT: "component/SET_TOPNAV_HEIGHT",
  SET_SIDENAV_WIDTH: "component/SET_SIDENAV_WIDTH",

  SET_IS_SIDENAV_OPEN: "component/SET_IS_SIDENAV_OPEN",
  SET_IS_MODAL_OPEN: "component/SET_IS_MODAL_OPEN",
  SET_CURRENT_MODAL: "component/SET_CURRENT_MODAL",

  SET_CURRENT_MODALS: "component/SET_CURRENT_MODALS",
  APPEND_CURRENT_MODAL: "component/APPEND_CURRENT_MODAL",
  REMOVE_CURRENT_MODAL: "component/REMOVE_CURRENT_MODAL",

  SET_CURRENT_DIALOGS: "component/SET_CURRENT_DIALOGS",
  APPEND_CURRENT_DIALOG: "component/APPEND_CURRENT_DIALOG",
  REMOVE_CURRENT_DIALOG: "component/REMOVE_CURRENT_DIALOG",

  SET_IS_HAS_THANKS_PAGE: "component/SET_IS_HAS_THANKS_PAGE",
  SET_IS_HAS_GETTING_STARTED: "component/SET_IS_HAS_GETTING_STARTED",

  SET_ORDER_CUSTOMER_IP_ADDRESS: "component/SET_ORDER_CUSTOMER_IP_ADDRESS",
  SET_IS_ORDER_CUSTOMER_HIDE_SIDEBAR:
    "component/SET_IS_ORDER_CUSTOMER_HIDE_SIDEBAR",
  SET_IS_ORDER_CUSTOMER_HIDE_BACK_BUTTON:
    "component/SET_IS_ORDER_CUSTOMER_HIDE_BACK_BUTTON",
  SET_IS_ORDER_CUSTOMER_HIDE_TITLE:
    "component/SET_IS_ORDER_CUSTOMER_HIDE_TITLE",
  SET_IS_ORDER_CUSTOMER_HIDE_TOP_TOOLBAR:
    "component/SET_IS_ORDER_CUSTOMER_HIDE_TOP_TOOLBAR",

  SET_ORDER_WAITING_DISPLAY: "component/SET_ORDER_WAITING_DISPLAY",
  SET_IS_ORDER_WAITING_HIDE_SIDEBAR:
    "component/SET_IS_ORDER_WAITING_HIDE_SIDEBAR",
  SET_IS_ORDER_WAITING_HIDE_BACK_BUTTON:
    "component/SET_IS_ORDER_WAITING_HIDE_BACK_BUTTON",
  SET_IS_ORDER_WAITING_HIDE_TITLE: "component/SET_IS_ORDER_WAITING_HIDE_TITLE",
  SET_IS_ORDER_WAITING_HIDE_TOP_TOOLBAR:
    "component/SET_IS_ORDER_WAITING_HIDE_TOP_TOOLBAR",
};

export default COMPONENT_ACTION_TYPES;
