import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getBranches } from "../../api/branch.api";

import SELECT_BRANCH_ACTION_TYPES from "./select-branch.type";
import {
  appendBranches,
  appendSearchBranches,
  setAppendBranchesFailed,
  setAppendBranchesLoading,
  setAppendBranchesSuccess,
  setFetchBranchesFailed,
  setFetchBranchesLoading,
  setFetchBranchesSuccess,
  setIsAppendBranchesHitted,
  setIsFetchBranchesHitted,
  setIsBranchesHasMore,
  setIsSearchBranchesHasMore,
  setIsSearchBranchesHitted,
  setBranches,
  setSearchBranches,
  setSearchBranchesFailed,
  setSearchBranchesLoading,
  setSearchBranchesSuccess,
} from "./select-branch.action";
import {
  getAppendBranchesParams,
  getFetchBranchesParams,
  getSearchBranchesParams,
} from "./select-branch.selector";

export function* _getFetchBranches() {
  try {
    const fetchBranchesParams = yield select(getFetchBranchesParams);
    const parameters = getParamsWithDefault(fetchBranchesParams);

    yield put(setFetchBranchesLoading(true));

    const {
      meta: { message },
      data: branches,
    } = yield call(getBranches, parameters);

    yield put(setIsFetchBranchesHitted(true));
    yield put(setIsBranchesHasMore(Object.keys(branches).length > 0));

    if (parameters.page > 1) {
      yield put(appendBranches(branches));
    } else {
      yield put(setBranches(branches));
    }

    yield put(setFetchBranchesSuccess(message));
    yield put(setFetchBranchesLoading(false));
  } catch (error) {
    yield put(setFetchBranchesFailed(error));
    yield put(setFetchBranchesLoading(false));
  }
}
export function* _getSearchBranches() {
  try {
    const searchBranchesParams = yield select(getSearchBranchesParams);
    const parameters = getParamsWithDefault(searchBranchesParams);

    yield put(setSearchBranchesLoading(true));

    const {
      meta: { message },
      data: branches,
    } = yield call(getBranches, parameters);

    yield put(setIsSearchBranchesHitted(true));
    yield put(setIsSearchBranchesHasMore(Object.keys(branches).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchBranches(branches));
    } else {
      yield put(setSearchBranches(branches));
    }

    yield put(setSearchBranchesSuccess(message));
    yield put(setSearchBranchesLoading(false));
  } catch (error) {
    yield put(setSearchBranchesFailed(error));
    yield put(setSearchBranchesLoading(false));
  }
}
export function* _getAppendBranches() {
  try {
    const appendBranchesParams = yield select(getAppendBranchesParams);
    const parameters = getParamsWithDefault(appendBranchesParams);

    yield put(setAppendBranchesLoading(true));

    const {
      meta: { message },
      data: branches,
    } = yield call(getBranches, parameters);

    yield put(setIsAppendBranchesHitted(true));
    yield put(appendBranches(branches));

    yield put(setAppendBranchesSuccess(message));
    yield put(setAppendBranchesLoading(false));
  } catch (error) {
    yield put(setAppendBranchesFailed(error));
    yield put(setAppendBranchesLoading(false));
  }
}

export function* onFetchBranchesStart() {
  yield takeLatest(
    SELECT_BRANCH_ACTION_TYPES.FETCH_BRANCHES_START,
    _getFetchBranches
  );
}
export function* onSearchBranchesStart() {
  yield takeLatest(
    SELECT_BRANCH_ACTION_TYPES.SEARCH_BRANCHES_START,
    _getSearchBranches
  );
}
export function* onAppendBranchesStart() {
  yield takeLatest(
    SELECT_BRANCH_ACTION_TYPES.APPEND_BRANCHES_START,
    _getAppendBranches
  );
}

export function* selectBranchSaga() {
  yield all([
    call(onFetchBranchesStart),
    call(onSearchBranchesStart),
    call(onAppendBranchesStart),
  ]);
}
