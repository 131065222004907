import { createSelector } from "reselect";

const featureSystemSelector = ({ featureSystem }) => featureSystem;

export const getFeatureSystems = createSelector(
  [featureSystemSelector],
  ({ featureSystems }) => featureSystems
);
export const getFeatureSystem = createSelector(
  [featureSystemSelector],
  ({ featureSystem }) => featureSystem
);

export const getIsFeatureSystemsHasMore = createSelector(
  [featureSystemSelector],
  ({ isFeatureSystemsHasMore }) => isFeatureSystemsHasMore
);

export const getFetchFeatureSystemsSearch = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemsSearch }) => fetchFeatureSystemsSearch
);
export const getFetchFeatureSystemsSort = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemsSort }) => fetchFeatureSystemsSort
);
export const getFetchFeatureSystemsKeyBy = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemsKeyBy }) => fetchFeatureSystemsKeyBy
);
export const getFetchFeatureSystemsPage = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemsPage }) => fetchFeatureSystemsPage
);
export const getFetchFeatureSystemsPerPage = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemsPerPage }) => fetchFeatureSystemsPerPage
);
export const getFetchFeatureSystemsIncludes = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemsIncludes }) => fetchFeatureSystemsIncludes
);
export const getFetchFeatureSystemsLoading = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemsLoading }) => fetchFeatureSystemsLoading
);
export const getFetchFeatureSystemsSuccess = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemsSuccess }) => fetchFeatureSystemsSuccess
);
export const getFetchFeatureSystemsFailed = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemsFailed }) => fetchFeatureSystemsFailed
);

export const getFetchFeatureSystemLoading = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemLoading }) => fetchFeatureSystemLoading
);
export const getFetchFeatureSystemSuccess = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemSuccess }) => fetchFeatureSystemSuccess
);
export const getFetchFeatureSystemFailed = createSelector(
  [featureSystemSelector],
  ({ fetchFeatureSystemFailed }) => fetchFeatureSystemFailed
);

export const getUpdateFeatureSystemLoading = createSelector(
  [featureSystemSelector],
  ({ updateFeatureSystemLoading }) => updateFeatureSystemLoading
);
export const getUpdateFeatureSystemSuccess = createSelector(
  [featureSystemSelector],
  ({ updateFeatureSystemSuccess }) => updateFeatureSystemSuccess
);
export const getUpdateFeatureSystemFailed = createSelector(
  [featureSystemSelector],
  ({ updateFeatureSystemFailed }) => updateFeatureSystemFailed
);

export const getIsFetchFeatureSystemsHitted = createSelector(
  [featureSystemSelector],
  ({ isFetchFeatureSystemsHitted }) => isFetchFeatureSystemsHitted
);
export const getIsFetchFeatureSystemHitted = createSelector(
  [featureSystemSelector],
  ({ isFetchFeatureSystemHitted }) => isFetchFeatureSystemHitted
);
export const getIsUpdateFeatureSystemHitted = createSelector(
  [featureSystemSelector],
  ({ isUpdateFeatureSystemHitted }) => isUpdateFeatureSystemHitted
);
