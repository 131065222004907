import { createAction } from "../../utils/store.utils";

import RESTRICTION_NOTICE_ACTION_TYPES from "./restriction-notice.type";

export const setRestrictionNotices = (restrictionNotices) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_RESTRICTION_NOTICES,
    restrictionNotices
  );
export const setRestrictionNotice = (restrictionNotice) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_RESTRICTION_NOTICE,
    restrictionNotice
  );

export const setIsRestrictionNoticesHasMore = (isRestrictionNoticesHasMore) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_RESTRICTION_NOTICES_HAS_MORE,
    isRestrictionNoticesHasMore
  );

export const setFetchRestrictionNoticesSearch = (
  fetchRestrictionNoticesSearch
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_SEARCH,
    fetchRestrictionNoticesSearch
  );
export const setFetchRestrictionNoticesSort = (fetchRestrictionNoticesSort) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_SORT,
    fetchRestrictionNoticesSort
  );
export const setFetchRestrictionNoticesKeyBy = (fetchRestrictionNoticesKeyBy) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_KEY_BY,
    fetchRestrictionNoticesKeyBy
  );
export const setFetchRestrictionNoticesPage = (fetchRestrictionNoticesPage) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_PAGE,
    fetchRestrictionNoticesPage
  );
export const setFetchRestrictionNoticesPerPage = (
  fetchRestrictionNoticesPerPage
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_PER_PAGE,
    fetchRestrictionNoticesPerPage
  );
export const setFetchRestrictionNoticesIncludes = (
  fetchRestrictionNoticesIncludes
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_PER_PAGE,
    fetchRestrictionNoticesIncludes
  );
export const setFetchRestrictionNoticesLoading = (
  fetchRestrictionNoticesLoading
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_LOADING,
    fetchRestrictionNoticesLoading
  );
export const setFetchRestrictionNoticesSuccess = (
  fetchRestrictionNoticesSuccess
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_SUCCESS,
    fetchRestrictionNoticesSuccess
  );
export const setFetchRestrictionNoticesFailed = (
  fetchRestrictionNoticesFailed
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_FAILED,
    fetchRestrictionNoticesFailed
  );

export const setFetchRestrictionNoticeLoading = (
  fetchRestrictionNoticeLoading
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICE_LOADING,
    fetchRestrictionNoticeLoading
  );
export const setFetchRestrictionNoticeSuccess = (
  fetchRestrictionNoticeSuccess
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICE_SUCCESS,
    fetchRestrictionNoticeSuccess
  );
export const setFetchRestrictionNoticeFailed = (fetchRestrictionNoticeFailed) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICE_FAILED,
    fetchRestrictionNoticeFailed
  );

export const setCreateRestrictionNoticeLoading = (
  createRestrictionNoticeLoading
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_CREATE_RESTRICTION_NOTICE_LOADING,
    createRestrictionNoticeLoading
  );
export const setCreateRestrictionNoticeSuccess = (
  createRestrictionNoticeSuccess
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_CREATE_RESTRICTION_NOTICE_SUCCESS,
    createRestrictionNoticeSuccess
  );
export const setCreateRestrictionNoticeFailed = (
  createRestrictionNoticeFailed
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_CREATE_RESTRICTION_NOTICE_FAILED,
    createRestrictionNoticeFailed
  );

export const setUpdateRestrictionNoticeLoading = (
  updateRestrictionNoticeLoading
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_UPDATE_RESTRICTION_NOTICE_LOADING,
    updateRestrictionNoticeLoading
  );
export const setUpdateRestrictionNoticeSuccess = (
  updateRestrictionNoticeSuccess
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_UPDATE_RESTRICTION_NOTICE_SUCCESS,
    updateRestrictionNoticeSuccess
  );
export const setUpdateRestrictionNoticeFailed = (
  updateRestrictionNoticeFailed
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_UPDATE_RESTRICTION_NOTICE_FAILED,
    updateRestrictionNoticeFailed
  );

export const setDeleteRestrictionNoticeLoading = (
  deleteRestrictionNoticeLoading
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_DELETE_RESTRICTION_NOTICE_LOADING,
    deleteRestrictionNoticeLoading
  );
export const setDeleteRestrictionNoticeSuccess = (
  deleteRestrictionNoticeSuccess
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_DELETE_RESTRICTION_NOTICE_SUCCESS,
    deleteRestrictionNoticeSuccess
  );
export const setDeleteRestrictionNoticeFailed = (
  deleteRestrictionNoticeFailed
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_DELETE_RESTRICTION_NOTICE_FAILED,
    deleteRestrictionNoticeFailed
  );

export const appendRestrictionNotices = (restrictionNotices) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.APPEND_RESTRICTION_NOTICES,
    restrictionNotices
  );

export const setIsFetchRestrictionNoticesHitted = (
  isFetchRestrictionNoticesHitted
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_FETCH_RESTRICTION_NOTICES_HITTED,
    isFetchRestrictionNoticesHitted
  );
export const setIsFetchRestrictionNoticeHitted = (
  isFetchRestrictionNoticeHitted
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_FETCH_RESTRICTION_NOTICE_HITTED,
    isFetchRestrictionNoticeHitted
  );
export const setIsCreateRestrictionNoticeHitted = (
  isCreateRestrictionNoticeHitted
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_CREATE_RESTRICTION_NOTICE_HITTED,
    isCreateRestrictionNoticeHitted
  );
export const setIsUpdateRestrictionNoticeHitted = (
  isUpdateRestrictionNoticeHitted
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_UPDATE_RESTRICTION_NOTICE_HITTED,
    isUpdateRestrictionNoticeHitted
  );
export const setIsDeleteRestrictionNoticeHitted = (
  isDeleteRestrictionNoticeHitted
) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_DELETE_RESTRICTION_NOTICE_HITTED,
    isDeleteRestrictionNoticeHitted
  );

export const fetchRestrictionNoticesStart = () =>
  createAction(RESTRICTION_NOTICE_ACTION_TYPES.FETCH_RESTRICTION_NOTICES_START);
export const fetchRestrictionNoticeStart = (restrictionNoticeId) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.FETCH_RESTRICTION_NOTICE_START,
    restrictionNoticeId
  );
export const createRestrictionNoticeStart = (request) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.CREATE_RESTRICTION_NOTICE_START,
    request
  );
export const updateRestrictionNoticeStart = (restrictionNoticeId, request) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.UPDATE_RESTRICTION_NOTICE_START,
    {
      restrictionNoticeId,
      request,
    }
  );
export const deleteRestrictionNoticeStart = (restrictionNoticeId) =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.DELETE_RESTRICTION_NOTICE_START,
    restrictionNoticeId
  );

export const resetRestrictionNoticeReducer = () =>
  createAction(
    RESTRICTION_NOTICE_ACTION_TYPES.RESET_RESTRICTION_NOTICE_REDUCER
  );
