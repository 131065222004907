import styled, { css } from "styled-components";

import { isEmpty } from "../../utils/validation.utils";

import {
  RESPONSIVE_COLUMN_ALIGNS,
  RESPONSIVE_ROW_ALIGNS,
} from "./responsive.component";

export const ResponsiveContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const getResponsiveAlign = (align) => {
  switch (align) {
    case RESPONSIVE_ROW_ALIGNS.START:
    case RESPONSIVE_COLUMN_ALIGNS.START:
      return "flex-start";
    case RESPONSIVE_ROW_ALIGNS.END:
    case RESPONSIVE_COLUMN_ALIGNS.END:
      return "flex-end";
    case RESPONSIVE_ROW_ALIGNS.CENTER:
    case RESPONSIVE_COLUMN_ALIGNS.CENTER:
    default:
      return "center";
  }
};

export const ResponsiveRowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;

  ${({ rowGap }) => !isEmpty(rowGap) && `row-gap: ${rowGap}`};
  ${({ colGap }) => !isEmpty(colGap) && `column-gap: ${colGap}`};

  ${({ rowHorAlign }) =>
    !isEmpty(rowHorAlign) &&
    `justify-content: ${getResponsiveAlign(rowHorAlign)}`};
  ${({ rowVerAlign }) =>
    !isEmpty(rowVerAlign) && `align-items: ${getResponsiveAlign(rowVerAlign)}`};
`;

const getColumnWidth = (size) => {
  switch (size) {
    case 1:
      return "8.333333%;";
    case 2:
      return "16.666667%;";
    case 3:
      return "25%;";
    case 4:
      return "33.333333%;";
    case 5:
      return "41.666667%;";
    case 6:
      return "50%;";
    case 7:
      return "58.333333%;";
    case 8:
      return "66.666667%;";
    case 9:
      return "75%;";
    case 10:
      return "83.333333%;";
    case 11:
      return "91.666667%;";
    case 12:
    default:
      return "100%;";
  }
};

export const ResponsiveColStyled = styled.div`
  position: relative;
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  ${({ colVerAlign, colHorAlign, rowGap, colGap }) =>
    (!isEmpty(colVerAlign) ||
      !isEmpty(colHorAlign) ||
      !isEmpty(rowGap) ||
      !isEmpty(colGap)) &&
    css`
      display: inline-flex;
      flex-direction: column;
    `};

  ${({ rowGap }) => !isEmpty(rowGap) && `row-gap: ${rowGap}`};
  ${({ colGap }) => !isEmpty(colGap) && `column-gap: ${colGap}`};

  ${({ colVerAlign }) =>
    !isEmpty(colVerAlign) &&
    `justify-content: ${getResponsiveAlign(colVerAlign)}`};
  ${({ colHorAlign }) =>
    !isEmpty(colHorAlign) && `align-items: ${getResponsiveAlign(colHorAlign)}`};

  @media (min-width: 320px) {
    ${({ xs }) => !isEmpty(xs) && `width: ${getColumnWidth(xs)}`}
  }
  @media (min-width: 576px) {
    ${({ sm }) => !isEmpty(sm) && `width: ${getColumnWidth(sm)}`}
  }
  @media (min-width: 768px) {
    ${({ md }) => !isEmpty(md) && `width: ${getColumnWidth(md)}`}
  }
  @media (min-width: 992px) {
    ${({ lg }) => !isEmpty(lg) && `width: ${getColumnWidth(lg)}`}
  }
  @media (min-width: 1200px) {
    ${({ xl }) => !isEmpty(xl) && `width: ${getColumnWidth(xl)}`}
  }
  @media (min-width: 1400px) {
    ${({ xl2 }) => !isEmpty(xl2) && `width: ${getColumnWidth(xl2)}`}
  }
  @media (min-width: 1920px) {
    ${({ xl3 }) => !isEmpty(xl3) && `width: ${getColumnWidth(xl3)}`}
  }
  @media (min-width: 2560px) {
    ${({ xl4 }) => !isEmpty(xl4) && `width: ${getColumnWidth(xl4)}`}
  }
`;
