import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getProductCategories } from "../../api/product-category.api";

import SELECT_PRODUCT_CATEGORY_ACTION_TYPES from "./select-product-category.type";
import {
  appendProductCategories,
  appendSearchProductCategories,
  setAppendProductCategoriesFailed,
  setAppendProductCategoriesLoading,
  setAppendProductCategoriesSuccess,
  setFetchProductCategoriesFailed,
  setFetchProductCategoriesLoading,
  setFetchProductCategoriesSuccess,
  setIsAppendProductCategoriesHitted,
  setIsFetchProductCategoriesHitted,
  setIsProductCategoriesHasMore,
  setIsSearchProductCategoriesHasMore,
  setIsSearchProductCategoriesHitted,
  setProductCategories,
  setSearchProductCategories,
  setSearchProductCategoriesFailed,
  setSearchProductCategoriesLoading,
  setSearchProductCategoriesSuccess,
} from "./select-product-category.action";
import {
  getAppendProductCategoriesParams,
  getFetchProductCategoriesParams,
  getSearchProductCategoriesParams,
} from "./select-product-category.selector";

export function* _getFetchProductCategories() {
  try {
    const fetchProductCategoriesParams = yield select(
      getFetchProductCategoriesParams
    );
    const parameters = getParamsWithDefault(fetchProductCategoriesParams);

    yield put(setFetchProductCategoriesLoading(true));

    const {
      meta: { message },
      data: productCategories,
    } = yield call(getProductCategories, parameters);

    yield put(setIsFetchProductCategoriesHitted(true));
    yield put(
      setIsProductCategoriesHasMore(Object.keys(productCategories).length > 0)
    );

    if (parameters.page > 1) {
      yield put(appendProductCategories(productCategories));
    } else {
      yield put(setProductCategories(productCategories));
    }

    yield put(setFetchProductCategoriesSuccess(message));
    yield put(setFetchProductCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchProductCategoriesFailed(error));
    yield put(setFetchProductCategoriesLoading(false));
  }
}
export function* _getSearchProductCategories() {
  try {
    const searchProductCategoriesParams = yield select(
      getSearchProductCategoriesParams
    );
    const parameters = getParamsWithDefault(searchProductCategoriesParams);

    yield put(setSearchProductCategoriesLoading(true));

    const {
      meta: { message },
      data: productCategories,
    } = yield call(getProductCategories, parameters);

    yield put(setIsSearchProductCategoriesHitted(true));
    yield put(
      setIsSearchProductCategoriesHasMore(
        Object.keys(productCategories).length > 0
      )
    );

    if (parameters.page > 1) {
      yield put(appendSearchProductCategories(productCategories));
    } else {
      yield put(setSearchProductCategories(productCategories));
    }

    yield put(setSearchProductCategoriesSuccess(message));
    yield put(setSearchProductCategoriesLoading(false));
  } catch (error) {
    yield put(setSearchProductCategoriesFailed(error));
    yield put(setSearchProductCategoriesLoading(false));
  }
}
export function* _getAppendProductCategories() {
  try {
    const appendProductCategoriesParams = yield select(
      getAppendProductCategoriesParams
    );
    const parameters = getParamsWithDefault(appendProductCategoriesParams);

    yield put(setAppendProductCategoriesLoading(true));

    const {
      meta: { message },
      data: productCategories,
    } = yield call(getProductCategories, parameters);

    yield put(setIsAppendProductCategoriesHitted(true));
    yield put(appendProductCategories(productCategories));

    yield put(setAppendProductCategoriesSuccess(message));
    yield put(setAppendProductCategoriesLoading(false));
  } catch (error) {
    yield put(setAppendProductCategoriesFailed(error));
    yield put(setAppendProductCategoriesLoading(false));
  }
}

export function* onFetchProductCategoriesStart() {
  yield takeLatest(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_START,
    _getFetchProductCategories
  );
}
export function* onSearchProductCategoriesStart() {
  yield takeLatest(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.SEARCH_PRODUCT_CATEGORIES_START,
    _getSearchProductCategories
  );
}
export function* onAppendProductCategoriesStart() {
  yield takeLatest(
    SELECT_PRODUCT_CATEGORY_ACTION_TYPES.APPEND_PRODUCT_CATEGORIES_START,
    _getAppendProductCategories
  );
}

export function* selectProductCategorySaga() {
  yield all([
    call(onFetchProductCategoriesStart),
    call(onSearchProductCategoriesStart),
    call(onAppendProductCategoriesStart),
  ]);
}
