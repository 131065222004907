import { isValidElement } from "react";
import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import Spinner, {
  SPINNER_COLORS,
  SPINNER_SIZES,
} from "../../components/spinner/spinner.component";
import ModalDialog from "../../components/modal-dialog/modal-dialog.component";

import {
  ApplicationConnectOverlayBody,
  ApplicationConnectOverlayContainer,
  ApplicationConnectOverlayDescription,
  ApplicationConnectOverlayFoot,
  ApplicationConnectOverlayHead,
  ApplicationConnectOverlayIcon,
  ApplicationConnectOverlayLabel,
  ApplicationConnectOverlayLogo,
  ApplicationConnectOverlayLogoImage,
  ApplicationConnectOverlayProgressContainer,
  ApplicationConnectOverlayProgressLabel,
  ApplicationConnectOverlayStatusContainer,
  ApplicationConnectOverlaySwitch,
  ApplicationConnectOverlayTitle,
} from "./application-connect-overlay.style";

import {
  ArrowDataTransferHorizontalIcon,
  CheckmarkCircle04Icon,
} from "hugeicons-react";

const ApplicationConnectOverlayReady = () => {
  const { t } = useTranslation();

  return (
    <ApplicationConnectOverlayStatusContainer>
      <ApplicationConnectOverlayIcon>
        <CheckmarkCircle04Icon />
      </ApplicationConnectOverlayIcon>
      <ApplicationConnectOverlayLabel>
        {t("Ready to Connect")}
      </ApplicationConnectOverlayLabel>
    </ApplicationConnectOverlayStatusContainer>
  );
};
const ApplicationConnectOverlayConnecting = ({ name }) => {
  const { t } = useTranslation();

  return (
    <ApplicationConnectOverlayProgressContainer>
      <Spinner
        spinnerSize={SPINNER_SIZES.SMALL}
        spinnerColor={SPINNER_COLORS.DARK}
      />
      <ApplicationConnectOverlayProgressLabel>
        {t("Connecting With :integration", {
          integration: !isEmpty(name) ? name : "-",
        })}
      </ApplicationConnectOverlayProgressLabel>
    </ApplicationConnectOverlayProgressContainer>
  );
};
const ApplicationConnectOverlayConnected = () => {
  const { t } = useTranslation();

  return (
    <ApplicationConnectOverlayStatusContainer>
      <ApplicationConnectOverlayIcon>
        <CheckmarkCircle04Icon />
      </ApplicationConnectOverlayIcon>
      <ApplicationConnectOverlayLabel>
        {t("Connected")}
      </ApplicationConnectOverlayLabel>
    </ApplicationConnectOverlayStatusContainer>
  );
};

export const APPLICATION_CONNECT_STATES = {
  READY: "READY",
  CONNECTING: "CONNECTING",
  CONNECTED: "CONNECTED",
};

const getApplicationConnectStatus = (connectState) =>
  ({
    [APPLICATION_CONNECT_STATES.READY]: ApplicationConnectOverlayReady,
    [APPLICATION_CONNECT_STATES.CONNECTING]:
      ApplicationConnectOverlayConnecting,
    [APPLICATION_CONNECT_STATES.CONNECTED]: ApplicationConnectOverlayConnected,
  }?.[connectState]);

const ApplicationConnectOverlayContent = ({
  connectState,
  logoPrev,
  logoNext,
  name,
  description,
  handleApplicationConnect,
  isLoading = false,
  // handleDialogClose,
}) => {
  const { t } = useTranslation();

  const ApplicationConnectStatus = getApplicationConnectStatus(connectState);

  const handleConnectClick = () => handleApplicationConnect?.();

  return (
    <ApplicationConnectOverlayContainer>
      <ApplicationConnectOverlayHead>
        <ApplicationConnectOverlayLogo>
          <ApplicationConnectOverlayLogoImage src={logoPrev} />
        </ApplicationConnectOverlayLogo>
        <ApplicationConnectOverlaySwitch>
          <ArrowDataTransferHorizontalIcon />
        </ApplicationConnectOverlaySwitch>
        <ApplicationConnectOverlayLogo>
          <ApplicationConnectOverlayLogoImage src={logoNext} />
        </ApplicationConnectOverlayLogo>
      </ApplicationConnectOverlayHead>
      <ApplicationConnectOverlayBody>
        <ApplicationConnectOverlayTitle>
          {t("Connect With :integration", {
            integration: !isEmpty(name) ? name : "-",
          })}
        </ApplicationConnectOverlayTitle>
        <ApplicationConnectOverlayDescription>
          {!isEmpty(description) ? description : t("No description")}
        </ApplicationConnectOverlayDescription>
      </ApplicationConnectOverlayBody>
      {!isEmpty(ApplicationConnectStatus) && (
        <ApplicationConnectOverlayFoot>
          <ApplicationConnectStatus name={name} />
          {[
            APPLICATION_CONNECT_STATES.READY,
            APPLICATION_CONNECT_STATES.CONNECTING,
          ].includes(connectState) && (
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.DARK}
              buttonSize={BUTTON_SIZES.NORMAL}
              buttonType={BUTTON_TYPES.TEXT}
              onClick={handleConnectClick}
              isLoading={isLoading}
            >
              {t("Connect")}
            </Button>
          )}
        </ApplicationConnectOverlayFoot>
      )}
    </ApplicationConnectOverlayContainer>
  );
};

const ApplicationConnectOverlay = ({
  dialogKey,
  connectState,
  logoPrev,
  logoNext,
  name,
  description,
  handleApplicationConnect,
  isLoading = false,
}) => {
  return (
    <ModalDialog dialogKey={dialogKey}>
      <ApplicationConnectOverlayContent
        connectState={connectState}
        logoPrev={logoPrev}
        logoNext={logoNext}
        name={name}
        description={description}
        handleApplicationConnect={handleApplicationConnect}
        isLoading={isLoading}
      />
    </ModalDialog>
  );
};

export default ApplicationConnectOverlay;
