import { takeLatest, put, all, call, select } from "redux-saga/effects";

import INTEGRATION_ACTION_TYPES from "./integration.type";

import {
  appendIntegrations,
  setIntegration,
  setFetchIntegrationFailed,
  setFetchIntegrationLoading,
  setFetchIntegrationSuccess,
  setFetchIntegrationsFailed,
  setFetchIntegrationsLoading,
  setFetchIntegrationsPage,
  setFetchIntegrationsSuccess,
  setIsIntegrationsHasMore,
  setIsFetchIntegrationHitted,
  setIsFetchIntegrationsHitted,
  setCreateOrUpdateIntegrationLoading,
  setIsCreateOrUpdateIntegrationHitted,
  setCreateOrUpdateIntegrationSuccess,
  setCreateOrUpdateIntegrationFailed,
  setFetchQoyodStatusLoading,
  setFetchQoyodStatusSuccess,
  setFetchQoyodStatusFailed,
  setIsFetchQoyodStatusHitted,
  setQoyodStatus,
  setSyncQoyodLoading,
  setIsSyncQoyodHitted,
  setQoyodSync,
  setSyncQoyodSuccess,
  setSyncQoyodFailed,
  setFetchGrubtechStatusLoading,
  setIsFetchGrubtechStatusHitted,
  setGrubtechStatus,
  setFetchGrubtechStatusSuccess,
  setFetchGrubtechStatusFailed,
  setSyncGrubtechLoading,
  setIsSyncGrubtechHitted,
  setGrubtechSync,
  setSyncGrubtechSuccess,
  setSyncGrubtechFailed,
  setFetchBallurhStatusLoading,
  setIsFetchBallurhStatusHitted,
  setBallurhStatus,
  setFetchBallurhStatusSuccess,
  setFetchBallurhStatusFailed,
  setGenerateBallurhLoading,
  setIsGenerateBallurhHitted,
  setBallurhGenerate,
  setGenerateBallurhSuccess,
  setGenerateBallurhFailed,
  setSyncBallurhLoading,
  setIsSyncBallurhHitted,
  setBallurhSync,
  setSyncBallurhSuccess,
  setSyncBallurhFailed,
  setFetchZatcaStatusLoading,
  setIsFetchZatcaStatusHitted,
  setZatcaStatus,
  setFetchZatcaStatusSuccess,
  setFetchZatcaStatusFailed,
  setSyncZatcaLoading,
  setIsSyncZatcaHitted,
  setZatcaSync,
  setSyncZatcaSuccess,
  setSyncZatcaFailed,
  setIntegrations,
  setRegisterZatcaLoading,
  setIsRegisterZatcaHitted,
  setZatcaRegister,
  setRegisterZatcaSuccess,
  setRegisterZatcaFailed,
  setRegisterFaiLoading,
  setIsRegisterFaiHitted,
  setRegisterFaiSuccess,
  setRegisterFaiFailed,
  setSyncFaiLoading,
  setIsSyncFaiHitted,
  setFaiSync,
  setSyncFaiSuccess,
  setSyncFaiFailed,
  setFetchFaiStatusLoading,
  setIsFetchFaiStatusHitted,
  setFaiStatus,
  setFetchFaiStatusSuccess,
  setFetchFaiStatusFailed,
  setSetupZatcaLoading,
  setIsSetupZatcaHitted,
  setZatcaSetup,
  setSetupZatcaSuccess,
  setSetupZatcaFailed,
  setSetupFaiLoading,
  setIsSetupFaiHitted,
  setSetupFaiSuccess,
  setSetupFaiFailed,
  setFetchRegisterFaiStatusLoading,
  setFetchRegisterFaiStatusFailed,
  setFetchRegisterFaiStatusSuccess,
  setFaiRegisterStatus,
  setIsFetchRegisterFaiStatusHitted,
  setFaiRegister,
} from "./integration.action";
import {
  getFetchIntegrationsFilterBranchId,
  getFetchIntegrationsFilterKeys,
  getFetchIntegrationsFilterMarketId,
  getFetchIntegrationsIncludes,
  getFetchIntegrationsKeyBy,
  getFetchIntegrationsPage,
  getFetchIntegrationsPerPage,
  getFetchIntegrationsSort,
  getIsFetchIntegrationsHitted,
} from "./integration.selector";
import {
  getCurrentMarketId,
  getIsFetchCurrentMarketHitted,
} from "../global/global.selector";
import { _getCurrentMarket } from "../global/global.saga";

import {
  ballurhGenerate,
  ballurhStatus,
  ballurhSync,
  createOrUpdateIntegration,
  faiRegister,
  faiRegisterStatus,
  faiSetup,
  faiStatus,
  faiSync,
  getIntegration,
  getIntegrations,
  grubtechStatus,
  grubtechSync,
  qoyodStatus,
  qoyodSync,
  zatcaRegister,
  zatcaSetup,
  zatcaStatus,
  zatcaSync,
} from "../../api/integration.api";

export function* _getIntegrations() {
  try {
    yield put(setFetchIntegrationsLoading(true));

    const sort = yield select(getFetchIntegrationsSort);
    const key_by = yield select(getFetchIntegrationsKeyBy);
    const page = yield select(getFetchIntegrationsPage);
    const per_page = yield select(getFetchIntegrationsPerPage);
    const includes = yield select(getFetchIntegrationsIncludes);
    const market_id = yield select(getFetchIntegrationsFilterMarketId);
    const branch_id = yield select(getFetchIntegrationsFilterBranchId);
    const keys = yield select(getFetchIntegrationsFilterKeys);

    const parameters = {
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, branch_id, keys },
    };

    const {
      meta: { message },
      data: { data: integrations },
    } = yield call(getIntegrations, parameters);

    yield put(setIsFetchIntegrationsHitted(true));
    yield put(setIsIntegrationsHasMore(integrations.length > 0));

    if (page > 1) {
      yield put(appendIntegrations(integrations));
    } else {
      yield put(setIntegrations(integrations));
    }

    yield put(setFetchIntegrationsSuccess(message));
    yield put(setFetchIntegrationsLoading(false));
  } catch (error) {
    yield put(setFetchIntegrationsFailed(error));
    yield put(setFetchIntegrationsLoading(false));
  }
}
export function* _getIntegration({ payload: integrationId }) {
  try {
    yield put(setFetchIntegrationLoading(true));

    const {
      meta: { message },
      data: integration,
    } = yield call(getIntegration, integrationId);

    yield put(setIsFetchIntegrationHitted(true));
    yield put(setIntegration(integration));

    yield put(setFetchIntegrationSuccess(message));
    yield put(setFetchIntegrationLoading(false));
  } catch (error) {
    yield put(setFetchIntegrationFailed(error));
    yield put(setFetchIntegrationLoading(false));
  }
}
export function* _createOrUpdateIntegration({ payload: request }) {
  try {
    yield put(setCreateOrUpdateIntegrationLoading(true));

    const {
      meta: { message },
    } = yield call(createOrUpdateIntegration, request);

    yield put(setIsCreateOrUpdateIntegrationHitted(true));

    const isFetchIntegrationsHitted = yield select(
      getIsFetchIntegrationsHitted
    );

    if (isFetchIntegrationsHitted) {
      yield put(setFetchIntegrationsPage(1));
      yield call(_getIntegrations);
    }

    yield put(setCreateOrUpdateIntegrationSuccess(message));
    yield put(setCreateOrUpdateIntegrationLoading(false));
  } catch (error) {
    yield put(setCreateOrUpdateIntegrationFailed(error));
    yield put(setCreateOrUpdateIntegrationLoading(false));
  }
}

export function* _getQoyodStatus({ payload: parameters }) {
  try {
    yield put(setFetchQoyodStatusLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(qoyodStatus, parameters);

    yield put(setIsFetchQoyodStatusHitted(true));
    yield put(setQoyodStatus(response));

    yield put(setFetchQoyodStatusSuccess(message));
    yield put(setFetchQoyodStatusLoading(false));
  } catch (error) {
    yield put(setFetchQoyodStatusFailed(error));
    yield put(setFetchQoyodStatusLoading(false));
  }
}
export function* _getQoyodSync({ payload: request }) {
  try {
    yield put(setSyncQoyodLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(qoyodSync, request);

    yield put(setIsSyncQoyodHitted(true));
    yield put(setQoyodSync(response));

    yield put(setSyncQoyodSuccess(message));
    yield put(setSyncQoyodLoading(false));
  } catch (error) {
    yield put(setSyncQoyodFailed(error));
    yield put(setSyncQoyodLoading(false));
  }
}

export function* _getGrubtechStatus({ payload: parameters }) {
  try {
    yield put(setFetchGrubtechStatusLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(grubtechStatus, parameters);

    yield put(setIsFetchGrubtechStatusHitted(true));
    yield put(setGrubtechStatus(response));

    yield put(setFetchGrubtechStatusSuccess(message));
    yield put(setFetchGrubtechStatusLoading(false));
  } catch (error) {
    yield put(setFetchGrubtechStatusFailed(error));
    yield put(setFetchGrubtechStatusLoading(false));
  }
}
export function* _getGrubtechSync({ payload: request }) {
  try {
    yield put(setSyncGrubtechLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(grubtechSync, request);

    yield put(setIsSyncGrubtechHitted(true));
    yield put(setGrubtechSync(response));

    yield put(setSyncGrubtechSuccess(message));
    yield put(setSyncGrubtechLoading(false));
  } catch (error) {
    yield put(setSyncGrubtechFailed(error));
    yield put(setSyncGrubtechLoading(false));
  }
}

export function* _getBallurhStatus({ payload: parameters }) {
  try {
    yield put(setFetchBallurhStatusLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(ballurhStatus, parameters);

    yield put(setIsFetchBallurhStatusHitted(true));
    yield put(setBallurhStatus(response));

    yield put(setFetchBallurhStatusSuccess(message));
    yield put(setFetchBallurhStatusLoading(false));
  } catch (error) {
    yield put(setFetchBallurhStatusFailed(error));
    yield put(setFetchBallurhStatusLoading(false));
  }
}
export function* _getBallurhGenerate({ payload: request }) {
  try {
    yield put(setGenerateBallurhLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(ballurhGenerate, request);

    yield put(setIsGenerateBallurhHitted(true));
    yield put(setBallurhGenerate(response));

    yield put(setGenerateBallurhSuccess(message));
    yield put(setGenerateBallurhLoading(false));
  } catch (error) {
    yield put(setGenerateBallurhFailed(error));
    yield put(setGenerateBallurhLoading(false));
  }
}
export function* _getBallurhSync({ payload: request }) {
  try {
    yield put(setSyncBallurhLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(ballurhSync, request);

    yield put(setIsSyncBallurhHitted(true));
    yield put(setBallurhSync(response));

    yield put(setSyncBallurhSuccess(message));
    yield put(setSyncBallurhLoading(false));
  } catch (error) {
    yield put(setSyncBallurhFailed(error));
    yield put(setSyncBallurhLoading(false));
  }
}

export function* _getZatcaSetup({ payload: request }) {
  try {
    yield put(setSetupZatcaLoading(true));

    const response = yield call(zatcaSetup, request);

    yield put(setIsSetupZatcaHitted(true));
    yield put(setZatcaSetup(response));

    yield put(setSetupZatcaSuccess("Setup Success"));
    yield put(setSetupZatcaLoading(false));
  } catch (error) {
    yield put(setSetupZatcaFailed(error));
    yield put(setSetupZatcaLoading(false));
  }
}
export function* _getZatcaRegister({ payload: request }) {
  try {
    yield put(setRegisterZatcaLoading(true));

    const response = yield call(zatcaRegister, request);

    yield put(setIsRegisterZatcaHitted(true));
    yield put(setZatcaRegister(response));

    yield put(setRegisterZatcaSuccess("Register Success"));
    yield put(setRegisterZatcaLoading(false));
  } catch (error) {
    yield put(setRegisterZatcaFailed(error));
    yield put(setRegisterZatcaLoading(false));
  }
}
export function* _getZatcaStatus({ payload: parameters }) {
  try {
    yield put(setFetchZatcaStatusLoading(true));

    const response = yield call(zatcaStatus, parameters);

    yield put(setIsFetchZatcaStatusHitted(true));
    yield put(setZatcaStatus(response));

    yield put(setFetchZatcaStatusSuccess("Data Loaded"));
    yield put(setFetchZatcaStatusLoading(false));
  } catch (error) {
    yield put(setFetchZatcaStatusFailed(error));
    yield put(setFetchZatcaStatusLoading(false));
  }
}
export function* _getZatcaSync({ payload: request }) {
  try {
    yield put(setSyncZatcaLoading(true));

    const response = yield call(zatcaSync, request);

    yield put(setIsSyncZatcaHitted(true));
    yield put(setZatcaSync(response));

    yield put(setSyncZatcaSuccess("Sent Success"));
    yield put(setSyncZatcaLoading(false));
  } catch (error) {
    yield put(setSyncZatcaFailed(error));
    yield put(setSyncZatcaLoading(false));
  }
}

export function* _getFaiSetup({ payload: request }) {
  try {
    yield put(setSetupFaiLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(faiSetup, request);

    yield put(setIsSetupFaiHitted(true));
    yield put(setFaiRegisterStatus(response));

    const isFetchIntegrationsHitted = yield select(
      getIsFetchIntegrationsHitted
    );
    const isFetchCurrentMarketHitted = yield select(
      getIsFetchCurrentMarketHitted
    );
    const currentMarketId = yield select(getCurrentMarketId);

    if (isFetchIntegrationsHitted) {
      yield put(setFetchIntegrationsPage(1));
      yield call(_getIntegrations);
    }
    if (isFetchCurrentMarketHitted) {
      yield call(_getCurrentMarket, { payload: currentMarketId });
    }

    yield put(setSetupFaiSuccess(message));
    yield put(setSetupFaiLoading(false));
  } catch (error) {
    yield put(setSetupFaiFailed(error));
    yield put(setSetupFaiLoading(false));
  }
}
export function* _getFaiRegister({ payload: request }) {
  try {
    yield put(setRegisterFaiLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(faiRegister, request);

    yield put(setIsRegisterFaiHitted(true));
    yield put(setFaiRegister(response));

    const isFetchIntegrationsHitted = yield select(
      getIsFetchIntegrationsHitted
    );

    if (isFetchIntegrationsHitted) {
      yield put(setFetchIntegrationsPage(1));
      yield call(_getIntegrations);
    }

    yield put(setRegisterFaiSuccess(message));
    yield put(setRegisterFaiLoading(false));
  } catch (error) {
    yield put(setRegisterFaiFailed(error));
    yield put(setRegisterFaiLoading(false));
  }
}
export function* _getRegisterFaiStatus({ payload: parameters }) {
  try {
    yield put(setFetchRegisterFaiStatusLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(faiRegisterStatus, parameters);

    yield put(setIsFetchRegisterFaiStatusHitted(true));
    yield put(setFaiRegisterStatus(response));

    yield put(setFetchRegisterFaiStatusSuccess(message));
    yield put(setFetchRegisterFaiStatusLoading(false));
  } catch (error) {
    yield put(setFetchRegisterFaiStatusFailed(error));
    yield put(setFetchRegisterFaiStatusLoading(false));
  }
}
export function* _getFaiStatus({ payload: parameters }) {
  try {
    yield put(setFetchFaiStatusLoading(true));

    const response = yield call(faiStatus, parameters);

    yield put(setIsFetchFaiStatusHitted(true));
    yield put(setFaiStatus(response));

    yield put(setFetchFaiStatusSuccess("Data Loaded"));
    yield put(setFetchFaiStatusLoading(false));
  } catch (error) {
    yield put(setFetchFaiStatusFailed(error));
    yield put(setFetchFaiStatusLoading(false));
  }
}
export function* _getFaiSync({ payload: request }) {
  try {
    yield put(setSyncFaiLoading(true));

    const {
      meta: { message },
      data: response,
    } = yield call(faiSync, request);

    yield put(setIsSyncFaiHitted(true));
    yield put(setFaiSync(response));

    yield put(setSyncFaiSuccess(message));
    yield put(setSyncFaiLoading(false));
  } catch (error) {
    yield put(setSyncFaiFailed(error));
    yield put(setSyncFaiLoading(false));
  }
}

export function* onFetchIntegrationsStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_INTEGRATIONS_START,
    _getIntegrations
  );
}
export function* onFetchIntegrationStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_INTEGRATION_START,
    _getIntegration
  );
}
export function* onCreateOrUpdateIntegrationStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.CREATE_OR_UPDATE_INTEGRATION_START,
    _createOrUpdateIntegration
  );
}

export function* onFetchQoyodStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_QOYOD_STATUS_START,
    _getQoyodStatus
  );
}
export function* onSyncQoyodStart() {
  yield takeLatest(INTEGRATION_ACTION_TYPES.SYNC_QOYOD_START, _getQoyodSync);
}

export function* onFetchGrubtechStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_GRUBTECH_STATUS_START,
    _getGrubtechStatus
  );
}
export function* onSyncGrubtechStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.SYNC_GRUBTECH_START,
    _getGrubtechSync
  );
}

export function* onFetchBallurhStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_BALLURH_STATUS_START,
    _getBallurhStatus
  );
}
export function* onGenerateBallurhStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.GENERATE_BALLURH_START,
    _getBallurhGenerate
  );
}
export function* onSyncBallurhStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.SYNC_BALLURH_START,
    _getBallurhSync
  );
}

export function* onSetupZatcaStart() {
  yield takeLatest(INTEGRATION_ACTION_TYPES.SETUP_ZATCA_START, _getZatcaSetup);
}
export function* onRegisterZatcaStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.REGISTER_ZATCA_START,
    _getZatcaRegister
  );
}
export function* onFetchZatcaStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_ZATCA_STATUS_START,
    _getZatcaStatus
  );
}
export function* onSyncZatcaStart() {
  yield takeLatest(INTEGRATION_ACTION_TYPES.SYNC_ZATCA_START, _getZatcaSync);
}

export function* onSetupFaiStart() {
  yield takeLatest(INTEGRATION_ACTION_TYPES.SETUP_FAI_START, _getFaiSetup);
}
export function* onRegisterFaiStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.REGISTER_FAI_START,
    _getFaiRegister
  );
}
export function* onFetchRegisterFaiStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_REGISTER_FAI_STATUS_START,
    _getRegisterFaiStatus
  );
}
export function* onFetchFaiStatusStart() {
  yield takeLatest(
    INTEGRATION_ACTION_TYPES.FETCH_FAI_STATUS_START,
    _getFaiStatus
  );
}
export function* onSyncFaiStart() {
  yield takeLatest(INTEGRATION_ACTION_TYPES.SYNC_FAI_START, _getFaiSync);
}

export function* integrationSaga() {
  yield all([
    call(onFetchIntegrationsStart),
    call(onFetchIntegrationStart),
    call(onCreateOrUpdateIntegrationStart),

    call(onFetchQoyodStatusStart),
    call(onSyncQoyodStart),

    call(onFetchGrubtechStatusStart),
    call(onSyncGrubtechStart),

    call(onFetchBallurhStatusStart),
    call(onGenerateBallurhStart),
    call(onSyncBallurhStart),

    call(onSetupZatcaStart),
    call(onRegisterZatcaStart),
    call(onFetchZatcaStatusStart),
    call(onSyncZatcaStart),

    call(onSetupFaiStart),
    call(onRegisterFaiStart),
    call(onFetchRegisterFaiStatusStart),
    call(onFetchFaiStatusStart),
    call(onSyncFaiStart),
  ]);
}
