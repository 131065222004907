import RESTRICTION_NOTICE_ACTION_TYPES from "./restriction-notice.type";

export const RESTRICTION_NOTICE_INITIAL_STATE = {
  restrictionNotices: [],
  restrictionNotice: null,

  isRestrictionNoticesHasMore: true,

  fetchRestrictionNoticesSearch: null,
  fetchRestrictionNoticesSort: null,
  fetchRestrictionNoticesKeyBy: null,
  fetchRestrictionNoticesPage: 1,
  fetchRestrictionNoticesPerPage: null,
  fetchRestrictionNoticesIncludes: null,
  fetchRestrictionNoticesLoading: false,
  fetchRestrictionNoticesSuccess: null,
  fetchRestrictionNoticesFailed: null,

  fetchRestrictionNoticeLoading: false,
  fetchRestrictionNoticeSuccess: null,
  fetchRestrictionNoticeFailed: null,

  createRestrictionNoticeLoading: false,
  createRestrictionNoticeSuccess: null,
  createRestrictionNoticeFailed: null,

  updateRestrictionNoticeLoading: false,
  updateRestrictionNoticeSuccess: null,
  updateRestrictionNoticeFailed: null,

  deleteRestrictionNoticeLoading: false,
  deleteRestrictionNoticeSuccess: null,
  deleteRestrictionNoticeFailed: null,

  isFetchRestrictionNoticesHitted: false,
  isFetchRestrictionNoticeHitted: false,
  isCreateRestrictionNoticeHitted: false,
  isUpdateRestrictionNoticeHitted: false,
  isDeleteRestrictionNoticeHitted: false,
};

export const restrictionNoticeReducer = (
  state = RESTRICTION_NOTICE_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_RESTRICTION_NOTICES:
      return { ...state, restrictionNotices: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_RESTRICTION_NOTICE:
      return { ...state, restrictionNotice: payload };

    case RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_RESTRICTION_NOTICES_HAS_MORE:
      return { ...state, isRestrictionNoticesHasMore: payload };

    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_SEARCH:
      return { ...state, fetchRestrictionNoticesSearch: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_SORT:
      return { ...state, fetchRestrictionNoticesSort: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_KEY_BY:
      return { ...state, fetchRestrictionNoticesKeyBy: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_PAGE:
      return { ...state, fetchRestrictionNoticesPage: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_PER_PAGE:
      return { ...state, fetchRestrictionNoticesPerPage: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_INCLUDES:
      return { ...state, fetchRestrictionNoticesIncludes: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_LOADING:
      return { ...state, fetchRestrictionNoticesLoading: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_SUCCESS:
      return { ...state, fetchRestrictionNoticesSuccess: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICES_FAILED:
      return { ...state, fetchRestrictionNoticesFailed: payload };

    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICE_LOADING:
      return { ...state, fetchRestrictionNoticeLoading: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICE_SUCCESS:
      return { ...state, fetchRestrictionNoticeSuccess: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_FETCH_RESTRICTION_NOTICE_FAILED:
      return { ...state, fetchRestrictionNoticeFailed: payload };

    case RESTRICTION_NOTICE_ACTION_TYPES.SET_CREATE_RESTRICTION_NOTICE_LOADING:
      return { ...state, createRestrictionNoticeLoading: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_CREATE_RESTRICTION_NOTICE_SUCCESS:
      return { ...state, createRestrictionNoticeSuccess: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_CREATE_RESTRICTION_NOTICE_FAILED:
      return { ...state, createRestrictionNoticeFailed: payload };

    case RESTRICTION_NOTICE_ACTION_TYPES.SET_UPDATE_RESTRICTION_NOTICE_LOADING:
      return { ...state, updateRestrictionNoticeLoading: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_UPDATE_RESTRICTION_NOTICE_SUCCESS:
      return { ...state, updateRestrictionNoticeSuccess: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_UPDATE_RESTRICTION_NOTICE_FAILED:
      return { ...state, updateRestrictionNoticeFailed: payload };

    case RESTRICTION_NOTICE_ACTION_TYPES.SET_DELETE_RESTRICTION_NOTICE_LOADING:
      return { ...state, deleteRestrictionNoticeLoading: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_DELETE_RESTRICTION_NOTICE_SUCCESS:
      return { ...state, deleteRestrictionNoticeSuccess: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_DELETE_RESTRICTION_NOTICE_FAILED:
      return { ...state, deleteRestrictionNoticeFailed: payload };

    case RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_FETCH_RESTRICTION_NOTICES_HITTED:
      return { ...state, isFetchRestrictionNoticesHitted: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_FETCH_RESTRICTION_NOTICE_HITTED:
      return { ...state, isFetchRestrictionNoticeHitted: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_CREATE_RESTRICTION_NOTICE_HITTED:
      return { ...state, isCreateRestrictionNoticeHitted: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_UPDATE_RESTRICTION_NOTICE_HITTED:
      return { ...state, isUpdateRestrictionNoticeHitted: payload };
    case RESTRICTION_NOTICE_ACTION_TYPES.SET_IS_DELETE_RESTRICTION_NOTICE_HITTED:
      return { ...state, isDeleteRestrictionNoticeHitted: payload };

    case RESTRICTION_NOTICE_ACTION_TYPES.APPEND_RESTRICTION_NOTICES:
      return {
        ...state,
        restrictionNotices: [...state.restrictionNotices, ...payload],
      };

    case RESTRICTION_NOTICE_ACTION_TYPES.RESET_RESTRICTION_NOTICE_REDUCER:
      return RESTRICTION_NOTICE_INITIAL_STATE;
    default:
      return state;
  }
};
