import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { parseToBoolean } from "../../utils/parser.utils";
import { getPageDetails } from "../../utils/permission-subscriber.utils";
import { setIntegrationCompletionType } from "../../utils/storage.utils";
import { isEmpty } from "../../utils/validation.utils";

import {
  getFaiCompletionInfo,
  INTEGRATION_COMPETION_TYPES,
} from "../../constants/integration-completion.constant";

import {
  getCurrentMarketId,
  getIsMarketAdministrator,
  getIsRightToLeft,
  getMasterPermitPages,
  getSecondPermissionId,
  getSecondPermitPages,
} from "../../store/global/global.selector";

import Shimmer, {
  SHIMMER_RADIUS,
} from "../../components/shimmer/shimmer.component";

import {
  ApplicationCompletionTrackItemContainer,
  ApplicationCompletionTrackItemIcon,
  ApplicationCompletionTrackItemInfo,
  ApplicationCompletionTrackItemLabel,
  ApplicationCompletionTrackItemLink,
  ApplicationCompletionTrackListContainer,
} from "./application-completion-track-list.style";

import { LinkSquare02Icon } from "hugeicons-react";
import { ReactComponent as ConfirmSvg } from "../../assets/icons/stroke/Confirm.svg";

const ApplicationCompletionTrackItemLoading = () => {
  return (
    <ApplicationCompletionTrackItemContainer>
      <Shimmer
        radius={SHIMMER_RADIUS.CIRCLE}
        width="1.5rem"
        minWidth="1.5rem"
        maxWidth="1.5rem"
        height="1.5rem"
        minHeight="1.5rem"
        maxHeight="1.5rem"
        marginRight="0.25rem"
      />
      <Shimmer
        radius={SHIMMER_RADIUS.PX12}
        width="164px"
        maxWidth="100%"
        height="18px"
      />
      <Shimmer
        radius={SHIMMER_RADIUS.PX12}
        width="84px"
        minWidth="84px"
        maxWidth="84px"
        height="34px"
      />
      <Shimmer
        radius={SHIMMER_RADIUS.PX8}
        width="1.75rem"
        minWidth="1.75rem"
        maxWidth="1.75rem"
        height="1.75rem"
        minHeight="1.75rem"
        maxHeight="1.75rem"
        marginLeft="auto"
      />
    </ApplicationCompletionTrackItemContainer>
  );
};

const ApplicationCompletionTrackItemData = ({ completion }) => {
  const { i18n, t } = useTranslation();

  const isMarketAdministrator = useSelector(getIsMarketAdministrator);
  const secondPermissionId = useSelector(getSecondPermissionId);
  const masterPermitPages = useSelector(getMasterPermitPages);
  const secondPermitPages = useSelector(getSecondPermitPages);
  const currentMarketId = useSelector(getCurrentMarketId);

  const [pageUrlPath, setPageUrlPath] = useState(null);

  const { status_key, is_configured, is_required } = completion ?? {};
  const { permission_key, label } = getFaiCompletionInfo(status_key) ?? {};
  const pageDetails = getPageDetails(permission_key);

  const handleCompletionShow = () =>
    setIntegrationCompletionType(INTEGRATION_COMPETION_TYPES.COMPLETION_FAI);

  useEffect(() => {
    let pageUrlPath = null;

    if (isMarketAdministrator) {
      pageUrlPath =
        isEmpty(secondPermissionId) ||
        secondPermitPages.includes(permission_key)
          ? `/subscriber/${currentMarketId}${pageDetails.route}`
          : null;
    } else {
      pageUrlPath = masterPermitPages.includes(permission_key)
        ? pageDetails.route
        : null;
    }

    setPageUrlPath(pageUrlPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMarketAdministrator,
    secondPermissionId,
    masterPermitPages,
    secondPermitPages,
    currentMarketId,
  ]);

  return (
    <ApplicationCompletionTrackItemContainer>
      <ApplicationCompletionTrackItemIcon
        isActive={parseToBoolean(is_configured)}
      >
        <ConfirmSvg />
      </ApplicationCompletionTrackItemIcon>
      <ApplicationCompletionTrackItemInfo>
        {!isEmpty(label) && i18n.exists(label) ? label : t("No information")}
      </ApplicationCompletionTrackItemInfo>
      <ApplicationCompletionTrackItemLabel
        isRequired={parseToBoolean(is_required)}
      >
        {parseToBoolean(is_required) ? t("Required") : t("Optional")}
      </ApplicationCompletionTrackItemLabel>
      {!parseToBoolean(is_configured) && !isEmpty(pageUrlPath) && (
        <ApplicationCompletionTrackItemLink
          to={pageUrlPath}
          onClick={handleCompletionShow}
        >
          <LinkSquare02Icon />
        </ApplicationCompletionTrackItemLink>
      )}
    </ApplicationCompletionTrackItemContainer>
  );
};

const ApplicationCompletionTrackList = ({
  completions = [],
  isLoading = false,
}) => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  return (
    <ApplicationCompletionTrackListContainer isRightToLeft={isRightToLeft}>
      {isLoading
        ? Array.from(Array(4).keys()).map((index) => (
            <ApplicationCompletionTrackItemLoading key={index} />
          ))
        : completions.map((completion, index) => (
            <ApplicationCompletionTrackItemData
              key={index}
              completion={completion}
            />
          ))}
    </ApplicationCompletionTrackListContainer>
  );
};

export default ApplicationCompletionTrackList;
