import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateRestrictionNoticeFailed,
  setCreateRestrictionNoticeSuccess,
  setDeleteRestrictionNoticeFailed,
  setDeleteRestrictionNoticeSuccess,
  setFetchRestrictionNoticesFailed,
  setFetchRestrictionNoticeFailed,
  setUpdateRestrictionNoticeFailed,
  setUpdateRestrictionNoticeSuccess,
} from "../../store/restriction-notice/restriction-notice.action";
import {
  getCreateRestrictionNoticeFailed,
  getCreateRestrictionNoticeSuccess,
  getDeleteRestrictionNoticeFailed,
  getDeleteRestrictionNoticeSuccess,
  getFetchRestrictionNoticesFailed,
  getFetchRestrictionNoticeFailed,
  getUpdateRestrictionNoticeFailed,
  getUpdateRestrictionNoticeSuccess,
} from "../../store/restriction-notice/restriction-notice.selector";

const RestrictionNoticePopup = () => {
  const dispatch = useDispatch();

  const createRestrictionNoticeSuccess = useSelector(
    getCreateRestrictionNoticeSuccess
  );
  const updateRestrictionNoticeSuccess = useSelector(
    getUpdateRestrictionNoticeSuccess
  );
  const deleteRestrictionNoticeSuccess = useSelector(
    getDeleteRestrictionNoticeSuccess
  );

  const fetchRestrictionNoticesFailed = useSelector(
    getFetchRestrictionNoticesFailed
  );
  const fetchRestrictionNoticeFailed = useSelector(
    getFetchRestrictionNoticeFailed
  );
  const createRestrictionNoticeFailed = useSelector(
    getCreateRestrictionNoticeFailed
  );
  const updateRestrictionNoticeFailed = useSelector(
    getUpdateRestrictionNoticeFailed
  );
  const deleteRestrictionNoticeFailed = useSelector(
    getDeleteRestrictionNoticeFailed
  );

  useEffect(() => {
    if (
      createRestrictionNoticeSuccess !== null ||
      updateRestrictionNoticeSuccess !== null ||
      deleteRestrictionNoticeSuccess !== null
    ) {
      showSuccessMessage(
        createRestrictionNoticeSuccess ??
          updateRestrictionNoticeSuccess ??
          deleteRestrictionNoticeSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createRestrictionNoticeSuccess !== null)
        dispatch(setCreateRestrictionNoticeSuccess(null));
      if (updateRestrictionNoticeSuccess !== null)
        dispatch(setUpdateRestrictionNoticeSuccess(null));
      if (deleteRestrictionNoticeSuccess !== null)
        dispatch(setDeleteRestrictionNoticeSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createRestrictionNoticeSuccess,
    updateRestrictionNoticeSuccess,
    deleteRestrictionNoticeSuccess,
  ]);

  useEffect(() => {
    if (
      fetchRestrictionNoticesFailed !== null ||
      fetchRestrictionNoticeFailed !== null ||
      createRestrictionNoticeFailed !== null ||
      updateRestrictionNoticeFailed !== null ||
      deleteRestrictionNoticeFailed !== null
    ) {
      showErrorMessage(
        fetchRestrictionNoticesFailed ??
          fetchRestrictionNoticeFailed ??
          createRestrictionNoticeFailed ??
          updateRestrictionNoticeFailed ??
          deleteRestrictionNoticeFailed
      );

      if (fetchRestrictionNoticesFailed !== null)
        dispatch(setFetchRestrictionNoticesFailed(null));
      if (fetchRestrictionNoticeFailed !== null)
        dispatch(setFetchRestrictionNoticeFailed(null));
      if (createRestrictionNoticeFailed !== null)
        dispatch(setCreateRestrictionNoticeFailed(null));
      if (updateRestrictionNoticeFailed !== null)
        dispatch(setUpdateRestrictionNoticeFailed(null));
      if (deleteRestrictionNoticeFailed !== null)
        dispatch(setDeleteRestrictionNoticeFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRestrictionNoticesFailed,
    fetchRestrictionNoticeFailed,
    createRestrictionNoticeFailed,
    updateRestrictionNoticeFailed,
    deleteRestrictionNoticeFailed,
  ]);

  return <></>;
};

export default RestrictionNoticePopup;
