import { createSelector } from "reselect";

const restrictionNoticeSelector = ({ restrictionNotice }) => restrictionNotice;

export const getRestrictionNotices = createSelector(
  [restrictionNoticeSelector],
  ({ restrictionNotices }) => restrictionNotices
);
export const getRestrictionNotice = createSelector(
  [restrictionNoticeSelector],
  ({ restrictionNotice }) => restrictionNotice
);

export const getIsRestrictionNoticesHasMore = createSelector(
  [restrictionNoticeSelector],
  ({ isRestrictionNoticesHasMore }) => isRestrictionNoticesHasMore
);

export const getFetchRestrictionNoticesSearch = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticesSearch }) => fetchRestrictionNoticesSearch
);
export const getFetchRestrictionNoticesSort = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticesSort }) => fetchRestrictionNoticesSort
);
export const getFetchRestrictionNoticesKeyBy = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticesKeyBy }) => fetchRestrictionNoticesKeyBy
);
export const getFetchRestrictionNoticesPage = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticesPage }) => fetchRestrictionNoticesPage
);
export const getFetchRestrictionNoticesPerPage = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticesPerPage }) => fetchRestrictionNoticesPerPage
);
export const getFetchRestrictionNoticesIncludes = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticesIncludes }) => fetchRestrictionNoticesIncludes
);
export const getFetchRestrictionNoticesLoading = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticesLoading }) => fetchRestrictionNoticesLoading
);
export const getFetchRestrictionNoticesSuccess = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticesSuccess }) => fetchRestrictionNoticesSuccess
);
export const getFetchRestrictionNoticesFailed = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticesFailed }) => fetchRestrictionNoticesFailed
);

export const getFetchRestrictionNoticeLoading = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticeLoading }) => fetchRestrictionNoticeLoading
);
export const getFetchRestrictionNoticeSuccess = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticeSuccess }) => fetchRestrictionNoticeSuccess
);
export const getFetchRestrictionNoticeFailed = createSelector(
  [restrictionNoticeSelector],
  ({ fetchRestrictionNoticeFailed }) => fetchRestrictionNoticeFailed
);

export const getCreateRestrictionNoticeLoading = createSelector(
  [restrictionNoticeSelector],
  ({ createRestrictionNoticeLoading }) => createRestrictionNoticeLoading
);
export const getCreateRestrictionNoticeSuccess = createSelector(
  [restrictionNoticeSelector],
  ({ createRestrictionNoticeSuccess }) => createRestrictionNoticeSuccess
);
export const getCreateRestrictionNoticeFailed = createSelector(
  [restrictionNoticeSelector],
  ({ createRestrictionNoticeFailed }) => createRestrictionNoticeFailed
);

export const getUpdateRestrictionNoticeLoading = createSelector(
  [restrictionNoticeSelector],
  ({ updateRestrictionNoticeLoading }) => updateRestrictionNoticeLoading
);
export const getUpdateRestrictionNoticeSuccess = createSelector(
  [restrictionNoticeSelector],
  ({ updateRestrictionNoticeSuccess }) => updateRestrictionNoticeSuccess
);
export const getUpdateRestrictionNoticeFailed = createSelector(
  [restrictionNoticeSelector],
  ({ updateRestrictionNoticeFailed }) => updateRestrictionNoticeFailed
);

export const getDeleteRestrictionNoticeLoading = createSelector(
  [restrictionNoticeSelector],
  ({ deleteRestrictionNoticeLoading }) => deleteRestrictionNoticeLoading
);
export const getDeleteRestrictionNoticeSuccess = createSelector(
  [restrictionNoticeSelector],
  ({ deleteRestrictionNoticeSuccess }) => deleteRestrictionNoticeSuccess
);
export const getDeleteRestrictionNoticeFailed = createSelector(
  [restrictionNoticeSelector],
  ({ deleteRestrictionNoticeFailed }) => deleteRestrictionNoticeFailed
);

export const getIsFetchRestrictionNoticesHitted = createSelector(
  [restrictionNoticeSelector],
  ({ isFetchRestrictionNoticesHitted }) => isFetchRestrictionNoticesHitted
);
export const getIsFetchRestrictionNoticeHitted = createSelector(
  [restrictionNoticeSelector],
  ({ isFetchRestrictionNoticeHitted }) => isFetchRestrictionNoticeHitted
);
export const getIsCreateRestrictionNoticeHitted = createSelector(
  [restrictionNoticeSelector],
  ({ isCreateRestrictionNoticeHitted }) => isCreateRestrictionNoticeHitted
);
export const getIsUpdateRestrictionNoticeHitted = createSelector(
  [restrictionNoticeSelector],
  ({ isUpdateRestrictionNoticeHitted }) => isUpdateRestrictionNoticeHitted
);
export const getIsDeleteRestrictionNoticeHitted = createSelector(
  [restrictionNoticeSelector],
  ({ isDeleteRestrictionNoticeHitted }) => isDeleteRestrictionNoticeHitted
);
