import { createAction } from "../../utils/store.utils";

import FEATURE_SYSTEM_ACTION_TYPES from "./feature-system.type";

export const setFeatureSystems = (featureSystems) =>
  createAction(FEATURE_SYSTEM_ACTION_TYPES.SET_FEATURE_SYSTEMS, featureSystems);
export const setFeatureSystem = (featureSystem) =>
  createAction(FEATURE_SYSTEM_ACTION_TYPES.SET_FEATURE_SYSTEM, featureSystem);

export const setIsFeatureSystemsHasMore = (isFeatureSystemsHasMore) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FEATURE_SYSTEMS_HAS_MORE,
    isFeatureSystemsHasMore
  );

export const setFetchFeatureSystemsSearch = (fetchFeatureSystemsSearch) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_SEARCH,
    fetchFeatureSystemsSearch
  );
export const setFetchFeatureSystemsSort = (fetchFeatureSystemsSort) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_SORT,
    fetchFeatureSystemsSort
  );
export const setFetchFeatureSystemsKeyBy = (fetchFeatureSystemsKeyBy) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_KEY_BY,
    fetchFeatureSystemsKeyBy
  );
export const setFetchFeatureSystemsPage = (fetchFeatureSystemsPage) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_PAGE,
    fetchFeatureSystemsPage
  );
export const setFetchFeatureSystemsPerPage = (fetchFeatureSystemsPerPage) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_PER_PAGE,
    fetchFeatureSystemsPerPage
  );
export const setFetchFeatureSystemsIncludes = (fetchFeatureSystemsIncludes) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_PER_PAGE,
    fetchFeatureSystemsIncludes
  );
export const setFetchFeatureSystemsLoading = (fetchFeatureSystemsLoading) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_LOADING,
    fetchFeatureSystemsLoading
  );
export const setFetchFeatureSystemsSuccess = (fetchFeatureSystemsSuccess) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_SUCCESS,
    fetchFeatureSystemsSuccess
  );
export const setFetchFeatureSystemsFailed = (fetchFeatureSystemsFailed) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_FAILED,
    fetchFeatureSystemsFailed
  );

export const setFetchFeatureSystemLoading = (fetchFeatureSystemLoading) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEM_LOADING,
    fetchFeatureSystemLoading
  );
export const setFetchFeatureSystemSuccess = (fetchFeatureSystemSuccess) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEM_SUCCESS,
    fetchFeatureSystemSuccess
  );
export const setFetchFeatureSystemFailed = (fetchFeatureSystemFailed) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEM_FAILED,
    fetchFeatureSystemFailed
  );

export const setUpdateFeatureSystemLoading = (updateFeatureSystemLoading) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_UPDATE_FEATURE_SYSTEM_LOADING,
    updateFeatureSystemLoading
  );
export const setUpdateFeatureSystemSuccess = (updateFeatureSystemSuccess) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_UPDATE_FEATURE_SYSTEM_SUCCESS,
    updateFeatureSystemSuccess
  );
export const setUpdateFeatureSystemFailed = (updateFeatureSystemFailed) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_UPDATE_FEATURE_SYSTEM_FAILED,
    updateFeatureSystemFailed
  );

export const appendFeatureSystems = (featureSystems) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.APPEND_FEATURE_SYSTEMS,
    featureSystems
  );

export const setIsFetchFeatureSystemsHitted = (isFetchFeatureSystemsHitted) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FETCH_FEATURE_SYSTEMS_HITTED,
    isFetchFeatureSystemsHitted
  );
export const setIsFetchFeatureSystemHitted = (isFetchFeatureSystemHitted) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FETCH_FEATURE_SYSTEM_HITTED,
    isFetchFeatureSystemHitted
  );
export const setIsUpdateFeatureSystemHitted = (isUpdateFeatureSystemHitted) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.SET_IS_UPDATE_FEATURE_SYSTEM_HITTED,
    isUpdateFeatureSystemHitted
  );

export const fetchFeatureSystemsStart = () =>
  createAction(FEATURE_SYSTEM_ACTION_TYPES.FETCH_FEATURE_SYSTEMS_START);
export const fetchFeatureSystemStart = (featureKey) =>
  createAction(
    FEATURE_SYSTEM_ACTION_TYPES.FETCH_FEATURE_SYSTEM_START,
    featureKey
  );
export const updateFeatureSystemStart = (featureKey, request) =>
  createAction(FEATURE_SYSTEM_ACTION_TYPES.UPDATE_FEATURE_SYSTEM_START, {
    featureKey,
    request,
  });

export const resetFeatureSystemReducer = () =>
  createAction(FEATURE_SYSTEM_ACTION_TYPES.RESET_FEATURE_SYSTEM_REDUCER);
