import GLOBAL_ACTION_TYPES from "./global.type";
import { createAction } from "../../utils/store.utils";

export const setIsRightToLeft = (isRightToLeft) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_RIGHT_TO_LEFT, isRightToLeft);
export const setIsGlobalInitialized = (isGlobalInitialized) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_INITIALIZED,
    isGlobalInitialized
  );
export const setIsGlobalLoading = (isGlobalLoading) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_LOADING, isGlobalLoading);
export const setCurrentLanguage = (currentLanguage) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_LANGUAGE, currentLanguage);

export const setMasterPermissionId = (masterPermissionId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_MASTER_PERMISSION_ID,
    masterPermissionId
  );
export const setSecondPermissionId = (secondPermissionId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_SECOND_PERMISSION_ID,
    secondPermissionId
  );

export const setMasterPermission = (masterPermission) =>
  createAction(GLOBAL_ACTION_TYPES.SET_MASTER_PERMISSION, masterPermission);
export const setSecondPermission = (secondPermission) =>
  createAction(GLOBAL_ACTION_TYPES.SET_SECOND_PERMISSION, secondPermission);

export const setMasterAccessPages = (masterAccessPages) =>
  createAction(GLOBAL_ACTION_TYPES.SET_MASTER_ACCESS_PAGES, masterAccessPages);
export const setMasterForbidPages = (masterForbidPages) =>
  createAction(GLOBAL_ACTION_TYPES.SET_MASTER_FORBID_PAGES, masterForbidPages);
export const setMasterPermitPages = (masterPermitPages) =>
  createAction(GLOBAL_ACTION_TYPES.SET_MASTER_PERMIT_PAGES, masterPermitPages);

export const setSecondAccessPages = (secondAccessPages) =>
  createAction(GLOBAL_ACTION_TYPES.SET_SECOND_ACCESS_PAGES, secondAccessPages);
export const setSecondForbidPages = (secondForbidPages) =>
  createAction(GLOBAL_ACTION_TYPES.SET_SECOND_FORBID_PAGES, secondForbidPages);
export const setSecondPermitPages = (secondPermitPages) =>
  createAction(GLOBAL_ACTION_TYPES.SET_SECOND_PERMIT_PAGES, secondPermitPages);

export const setIsMarketAdministrator = (isMarketAdministrator) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_MARKET_ADMINISTRATOR,
    isMarketAdministrator
  );
export const setIsCenterAdministrator = (isCenterAdministrator) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_CENTER_ADMINISTRATOR,
    isCenterAdministrator
  );

export const setCurrentMarket = (currentMarket) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET, currentMarket);
export const setCurrentBranch = (currentBranch) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH, currentBranch);
export const setCurrentMerchant = (currentMerchant) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT, currentMerchant);
export const setCurrentSchedules = (currentSchedules) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_SCHEDULES, currentSchedules);

export const setCurrentMarketId = (currentMarketId) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET_ID, currentMarketId);
export const setCurrentBranchId = (currentBranchId) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH_ID, currentBranchId);
export const setCurrentMerchantId = (currentMerchantId) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT_ID, currentMerchantId);

export const setIsCurrentKitchenActive = (isCurrentKitchenActive) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_CURRENT_KITCHEN_ACTIVE,
    isCurrentKitchenActive
  );

export const setTodaySchedule = (todaySchedule) =>
  createAction(GLOBAL_ACTION_TYPES.SET_TODAY_SCHEDULE, todaySchedule);
export const setIsTodayInSchedule = (isTodayInSchedule) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_TODAY_IN_SCHEDULE, isTodayInSchedule);

export const setTodayAttendance = (todayAttendance) =>
  createAction(GLOBAL_ACTION_TYPES.SET_TODAY_ATTENDANCE, todayAttendance);
export const setIsTodayHasAttendance = (isTodayHasAttendance) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_TODAY_HAS_ATTENDANCE,
    isTodayHasAttendance
  );

export const setMarketPlans = (marketPlans) =>
  createAction(GLOBAL_ACTION_TYPES.SET_MARKET_PLANS, marketPlans);
export const setBranchPlans = (branchPlans) =>
  createAction(GLOBAL_ACTION_TYPES.SET_BRANCH_PLANS, branchPlans);

export const setIsHasActivePlan = (isHasActivePlan) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_PLAN, isHasActivePlan);
export const setIsHasActiveAdvancedPlan = (isHasActiveAdvancedPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_ADVANCED_PLAN,
    isHasActiveAdvancedPlan
  );
export const setIsHasActiveBasicPlan = (isHasActiveBasicPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_BASIC_PLAN,
    isHasActiveBasicPlan
  );
export const setIsHasActiveTrialPlan = (isHasActiveTrialPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_TRIAL_PLAN,
    isHasActiveTrialPlan
  );

export const setIsMarketHasPlan = (isMarketHasPlan) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_PLAN, isMarketHasPlan);
export const setIsMarketHasAdvancedPlan = (isMarketHasAdvancedPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_ADVANCED_PLAN,
    isMarketHasAdvancedPlan
  );
export const setIsMarketHasBasicPlan = (isMarketHasBasicPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_BASIC_PLAN,
    isMarketHasBasicPlan
  );
export const setIsMarketHasTrialPlan = (isMarketHasTrialPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_TRIAL_PLAN,
    isMarketHasTrialPlan
  );

export const setIsBranchHasPlan = (isBranchHasPlan) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_PLAN, isBranchHasPlan);
export const setIsBranchHasAdvancedPlan = (isBranchHasAdvancedPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_ADVANCED_PLAN,
    isBranchHasAdvancedPlan
  );
export const setIsBranchHasBasicPlan = (isBranchHasBasicPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_BASIC_PLAN,
    isBranchHasBasicPlan
  );
export const setIsBranchHasTrialPlan = (isBranchHasTrialPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_TRIAL_PLAN,
    isBranchHasTrialPlan
  );

export const setPlanBillingCycles = (planBillingCycles) =>
  createAction(GLOBAL_ACTION_TYPES.SET_PLAN_BILLING_CYCLES, planBillingCycles);
export const setGlobalSubscriptions = (globalSubscriptions) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_GLOBAL_SUBSCRIPTIONS,
    globalSubscriptions
  );
export const setGlobalFeatureSubscriptions = (globalFeatureSubscriptions) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_GLOBAL_FEATURE_SUBSCRIPTIONS,
    globalFeatureSubscriptions
  );

export const setGlobalFeatureSystems = (globalFeatureSystems) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_GLOBAL_FEATURE_SYSTEMS,
    globalFeatureSystems
  );
export const setGlobalRestrictionNotices = (globalRestrictionNotices) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_GLOBAL_RESTRICTION_NOTICES,
    globalRestrictionNotices
  );
export const setGlobalBanners = (globalBanners) =>
  createAction(GLOBAL_ACTION_TYPES.SET_GLOBAL_BANNERS, globalBanners);
export const setGlobalBannerTargets = (globalBannerTargets) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_GLOBAL_BANNER_TARGETS,
    globalBannerTargets
  );

export const appendGlobalSubscriptions = (globalSubscriptions) =>
  createAction(
    GLOBAL_ACTION_TYPES.APPEND_GLOBAL_SUBSCRIPTIONS,
    globalSubscriptions
  );
export const appendGlobalFeatureSubscriptions = (globalFeatureSubscriptions) =>
  createAction(
    GLOBAL_ACTION_TYPES.APPEND_GLOBAL_FEATURE_SUBSCRIPTIONS,
    globalFeatureSubscriptions
  );

export const appendGlobalFeatureSystems = (globalFeatureSystems) =>
  createAction(
    GLOBAL_ACTION_TYPES.APPEND_GLOBAL_FEATURE_SYSTEMS,
    globalFeatureSystems
  );
export const appendGlobalRestrictionNotices = (globalRestrictionNotices) =>
  createAction(
    GLOBAL_ACTION_TYPES.APPEND_GLOBAL_RESTRICTION_NOTICES,
    globalRestrictionNotices
  );
export const appendGlobalBanners = (globalBanners) =>
  createAction(GLOBAL_ACTION_TYPES.APPEND_GLOBAL_BANNERS, globalBanners);
export const appendGlobalBannerTargets = (globalBannerTargets) =>
  createAction(
    GLOBAL_ACTION_TYPES.APPEND_GLOBAL_BANNER_TARGETS,
    globalBannerTargets
  );

export const setIsGlobalSubscriptionsHasMore = (isGlobalSubscriptionsHasMore) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_SUBSCRIPTIONS_HAS_MORE,
    isGlobalSubscriptionsHasMore
  );
export const setIsGlobalFeatureSubscriptionsHasMore = (
  isGlobalFeatureSubscriptionsHasMore
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_FEATURE_SUBSCRIPTIONS_HAS_MORE,
    isGlobalFeatureSubscriptionsHasMore
  );

export const setIsGlobalFeatureSystemsHasMore = (
  isGlobalFeatureSystemsHasMore
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_FEATURE_SYSTEMS_HAS_MORE,
    isGlobalFeatureSystemsHasMore
  );
export const setIsGlobalRestrictionNoticesHasMore = (
  isGlobalRestrictionNoticesHasMore
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_RESTRICTION_NOTICES_HAS_MORE,
    isGlobalRestrictionNoticesHasMore
  );
export const setIsGlobalBannersHasMore = (isGlobalBannersHasMore) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_BANNERS_HAS_MORE,
    isGlobalBannersHasMore
  );
export const setIsGlobalBannerTargetsHasMore = (isGlobalBannerTargetsHasMore) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_BANNER_TARGETS_HAS_MORE,
    isGlobalBannerTargetsHasMore
  );

export const setFetchInitializeLoading = (initializeLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_INITIALIZE_LOADING,
    initializeLoading
  );
export const setFetchInitializeSuccess = (initializeSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_INITIALIZE_SUCCESS,
    initializeSuccess
  );
export const setFetchInitializeFailed = (initializeFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_INITIALIZE_FAILED,
    initializeFailed
  );

export const setFetchMasterPermissionLoading = (fetchMasterPermissionLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_MASTER_PERMISSION_LOADING,
    fetchMasterPermissionLoading
  );
export const setFetchMasterPermissionSuccess = (fetchMasterPermissionSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_MASTER_PERMISSION_SUCCESS,
    fetchMasterPermissionSuccess
  );
export const setFetchMasterPermissionFailed = (fetchMasterPermissionFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_MASTER_PERMISSION_FAILED,
    fetchMasterPermissionFailed
  );

export const setFetchSecondPermissionLoading = (fetchSecondPermissionLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_SECOND_PERMISSION_LOADING,
    fetchSecondPermissionLoading
  );
export const setFetchSecondPermissionSuccess = (fetchSecondPermissionSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_SECOND_PERMISSION_SUCCESS,
    fetchSecondPermissionSuccess
  );
export const setFetchSecondPermissionFailed = (fetchSecondPermissionFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_SECOND_PERMISSION_FAILED,
    fetchSecondPermissionFailed
  );

export const setFetchCurrentMarketLoading = (fetchCurrentMarketLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_LOADING,
    fetchCurrentMarketLoading
  );
export const setFetchCurrentMarketSuccess = (fetchCurrentMarketSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_SUCCESS,
    fetchCurrentMarketSuccess
  );
export const setFetchCurrentMarketFailed = (fetchCurrentMarketFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_FAILED,
    fetchCurrentMarketFailed
  );

export const setFetchCurrentBranchLoading = (fetchCurrentBranchLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_LOADING,
    fetchCurrentBranchLoading
  );
export const setFetchCurrentBranchSuccess = (fetchCurrentBranchSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_SUCCESS,
    fetchCurrentBranchSuccess
  );
export const setFetchCurrentBranchFailed = (fetchCurrentBranchFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_FAILED,
    fetchCurrentBranchFailed
  );

export const setFetchCurrentMerchantLoading = (fetchCurrentMerchantLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_LOADING,
    fetchCurrentMerchantLoading
  );
export const setFetchCurrentMerchantSuccess = (fetchCurrentMerchantSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_SUCCESS,
    fetchCurrentMerchantSuccess
  );
export const setFetchCurrentMerchantFailed = (fetchCurrentMerchantFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_FAILED,
    fetchCurrentMerchantFailed
  );

export const setFetchCurrentSchedulesIncludes = (
  fetchCurrentSchedulesIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_INCLUDES,
    fetchCurrentSchedulesIncludes
  );
export const setFetchCurrentSchedulesFilterMarketId = (
  fetchCurrentSchedulesFilterMarketId
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID,
    fetchCurrentSchedulesFilterMarketId
  );
export const setFetchCurrentSchedulesFilterBranchId = (
  fetchCurrentSchedulesFilterBranchId
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID,
    fetchCurrentSchedulesFilterBranchId
  );
export const setFetchCurrentSchedulesLoading = (fetchCurrentSchedulesLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_LOADING,
    fetchCurrentSchedulesLoading
  );
export const setFetchCurrentSchedulesSuccess = (fetchCurrentSchedulesSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_SUCCESS,
    fetchCurrentSchedulesSuccess
  );
export const setFetchCurrentSchedulesFailed = (fetchCurrentSchedulesFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FAILED,
    fetchCurrentSchedulesFailed
  );

export const setFetchTodayAttendanceMarketId = (fetchTodayAttendanceMarketId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_MARKET_ID,
    fetchTodayAttendanceMarketId
  );
export const setFetchTodayAttendanceBranchId = (fetchTodayAttendanceBranchId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID,
    fetchTodayAttendanceBranchId
  );
export const setFetchTodayAttendanceLoading = (fetchTodayAttendanceLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_LOADING,
    fetchTodayAttendanceLoading
  );
export const setFetchTodayAttendanceSuccess = (fetchTodayAttendanceSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_SUCCESS,
    fetchTodayAttendanceSuccess
  );
export const setFetchTodayAttendanceFailed = (fetchTodayAttendanceFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_FAILED,
    fetchTodayAttendanceFailed
  );

export const setFetchGlobalSubscriptionsSearch = (
  fetchGlobalSubscriptionsSearch
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SEARCH,
    fetchGlobalSubscriptionsSearch
  );
export const setFetchGlobalSubscriptionsSort = (fetchGlobalSubscriptionsSort) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SORT,
    fetchGlobalSubscriptionsSort
  );
export const setFetchGlobalSubscriptionsKeyBy = (
  fetchGlobalSubscriptionsKeyBy
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_KEY_BY,
    fetchGlobalSubscriptionsKeyBy
  );
export const setFetchGlobalSubscriptionsPage = (fetchGlobalSubscriptionsPage) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_PAGE,
    fetchGlobalSubscriptionsPage
  );
export const setFetchGlobalSubscriptionsPerPage = (
  fetchGlobalSubscriptionsPerPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_PER_PAGE,
    fetchGlobalSubscriptionsPerPage
  );
export const setFetchGlobalSubscriptionsIncludes = (
  fetchGlobalSubscriptionsIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_INCLUDES,
    fetchGlobalSubscriptionsIncludes
  );
export const setFetchGlobalSubscriptionsLoading = (
  fetchGlobalSubscriptionsLoading
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_LOADING,
    fetchGlobalSubscriptionsLoading
  );
export const setFetchGlobalSubscriptionsSuccess = (
  fetchGlobalSubscriptionsSuccess
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SUCCESS,
    fetchGlobalSubscriptionsSuccess
  );
export const setFetchGlobalSubscriptionsFailed = (
  fetchGlobalSubscriptionsFailed
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_FAILED,
    fetchGlobalSubscriptionsFailed
  );

export const setFetchGlobalFeatureSubscriptionsSearch = (
  fetchGlobalFeatureSubscriptionsSearch
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SEARCH,
    fetchGlobalFeatureSubscriptionsSearch
  );
export const setFetchGlobalFeatureSubscriptionsSort = (
  fetchGlobalFeatureSubscriptionsSort
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SORT,
    fetchGlobalFeatureSubscriptionsSort
  );
export const setFetchGlobalFeatureSubscriptionsKeyBy = (
  fetchGlobalFeatureSubscriptionsKeyBy
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_KEY_BY,
    fetchGlobalFeatureSubscriptionsKeyBy
  );
export const setFetchGlobalFeatureSubscriptionsPage = (
  fetchGlobalFeatureSubscriptionsPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_PAGE,
    fetchGlobalFeatureSubscriptionsPage
  );
export const setFetchGlobalFeatureSubscriptionsPerPage = (
  fetchGlobalFeatureSubscriptionsPerPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_PER_PAGE,
    fetchGlobalFeatureSubscriptionsPerPage
  );
export const setFetchGlobalFeatureSubscriptionsIncludes = (
  fetchGlobalFeatureSubscriptionsIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_INCLUDES,
    fetchGlobalFeatureSubscriptionsIncludes
  );
export const setFetchGlobalFeatureSubscriptionsFilterIsHighlight = (
  fetchGlobalFeatureSubscriptionsFilterIsHighlight
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_FILTER_IS_HIGHLIGHT,
    fetchGlobalFeatureSubscriptionsFilterIsHighlight
  );
export const setFetchGlobalFeatureSubscriptionsLoading = (
  fetchGlobalFeatureSubscriptionsLoading
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_LOADING,
    fetchGlobalFeatureSubscriptionsLoading
  );
export const setFetchGlobalFeatureSubscriptionsSuccess = (
  fetchGlobalFeatureSubscriptionsSuccess
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_SUCCESS,
    fetchGlobalFeatureSubscriptionsSuccess
  );
export const setFetchGlobalFeatureSubscriptionsFailed = (
  fetchGlobalFeatureSubscriptionsFailed
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_FAILED,
    fetchGlobalFeatureSubscriptionsFailed
  );

export const setFetchGlobalFeatureSystemsSearch = (
  fetchGlobalFeatureSystemsSearch
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_SEARCH,
    fetchGlobalFeatureSystemsSearch
  );
export const setFetchGlobalFeatureSystemsSort = (
  fetchGlobalFeatureSystemsSort
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_SORT,
    fetchGlobalFeatureSystemsSort
  );
export const setFetchGlobalFeatureSystemsKeyBy = (
  fetchGlobalFeatureSystemsKeyBy
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_KEY_BY,
    fetchGlobalFeatureSystemsKeyBy
  );
export const setFetchGlobalFeatureSystemsPage = (
  fetchGlobalFeatureSystemsPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_PAGE,
    fetchGlobalFeatureSystemsPage
  );
export const setFetchGlobalFeatureSystemsPerPage = (
  fetchGlobalFeatureSystemsPerPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_PER_PAGE,
    fetchGlobalFeatureSystemsPerPage
  );
export const setFetchGlobalFeatureSystemsIncludes = (
  fetchGlobalFeatureSystemsIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_INCLUDES,
    fetchGlobalFeatureSystemsIncludes
  );
export const setFetchGlobalFeatureSystemsFilterRoles = (
  fetchGlobalFeatureSystemsFilterRoles
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_FILTER_ROLES,
    fetchGlobalFeatureSystemsFilterRoles
  );
export const setFetchGlobalFeatureSystemsLoading = (
  fetchGlobalFeatureSystemsLoading
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_LOADING,
    fetchGlobalFeatureSystemsLoading
  );
export const setFetchGlobalFeatureSystemsSuccess = (
  fetchGlobalFeatureSystemsSuccess
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_SUCCESS,
    fetchGlobalFeatureSystemsSuccess
  );
export const setFetchGlobalFeatureSystemsFailed = (
  fetchGlobalFeatureSystemsFailed
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_FEATURE_SYSTEMS_FAILED,
    fetchGlobalFeatureSystemsFailed
  );

export const setFetchGlobalRestrictionNoticesSearch = (
  fetchGlobalRestrictionNoticesSearch
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_SEARCH,
    fetchGlobalRestrictionNoticesSearch
  );
export const setFetchGlobalRestrictionNoticesSort = (
  fetchGlobalRestrictionNoticesSort
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_SORT,
    fetchGlobalRestrictionNoticesSort
  );
export const setFetchGlobalRestrictionNoticesKeyBy = (
  fetchGlobalRestrictionNoticesKeyBy
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_KEY_BY,
    fetchGlobalRestrictionNoticesKeyBy
  );
export const setFetchGlobalRestrictionNoticesPage = (
  fetchGlobalRestrictionNoticesPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_PAGE,
    fetchGlobalRestrictionNoticesPage
  );
export const setFetchGlobalRestrictionNoticesPerPage = (
  fetchGlobalRestrictionNoticesPerPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_PER_PAGE,
    fetchGlobalRestrictionNoticesPerPage
  );
export const setFetchGlobalRestrictionNoticesIncludes = (
  fetchGlobalRestrictionNoticesIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_INCLUDES,
    fetchGlobalRestrictionNoticesIncludes
  );
export const setFetchGlobalRestrictionNoticesLoading = (
  fetchGlobalRestrictionNoticesLoading
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_LOADING,
    fetchGlobalRestrictionNoticesLoading
  );
export const setFetchGlobalRestrictionNoticesSuccess = (
  fetchGlobalRestrictionNoticesSuccess
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_SUCCESS,
    fetchGlobalRestrictionNoticesSuccess
  );
export const setFetchGlobalRestrictionNoticesFailed = (
  fetchGlobalRestrictionNoticesFailed
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_RESTRICTION_NOTICES_FAILED,
    fetchGlobalRestrictionNoticesFailed
  );

export const setFetchGlobalBannersSearch = (fetchGlobalBannersSearch) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_SEARCH,
    fetchGlobalBannersSearch
  );
export const setFetchGlobalBannersSort = (fetchGlobalBannersSort) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_SORT,
    fetchGlobalBannersSort
  );
export const setFetchGlobalBannersKeyBy = (fetchGlobalBannersKeyBy) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_KEY_BY,
    fetchGlobalBannersKeyBy
  );
export const setFetchGlobalBannersPage = (fetchGlobalBannersPage) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_PAGE,
    fetchGlobalBannersPage
  );
export const setFetchGlobalBannersPerPage = (fetchGlobalBannersPerPage) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_PER_PAGE,
    fetchGlobalBannersPerPage
  );
export const setFetchGlobalBannersIncludes = (fetchGlobalBannersIncludes) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_INCLUDES,
    fetchGlobalBannersIncludes
  );
export const setFetchGlobalBannersLoading = (fetchGlobalBannersLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_LOADING,
    fetchGlobalBannersLoading
  );
export const setFetchGlobalBannersSuccess = (fetchGlobalBannersSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_SUCCESS,
    fetchGlobalBannersSuccess
  );
export const setFetchGlobalBannersFailed = (fetchGlobalBannersFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNERS_FAILED,
    fetchGlobalBannersFailed
  );

export const setFetchGlobalBannerTargetsSearch = (
  fetchGlobalBannerTargetsSearch
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_SEARCH,
    fetchGlobalBannerTargetsSearch
  );
export const setFetchGlobalBannerTargetsSort = (fetchGlobalBannerTargetsSort) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_SORT,
    fetchGlobalBannerTargetsSort
  );
export const setFetchGlobalBannerTargetsKeyBy = (
  fetchGlobalBannerTargetsKeyBy
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_KEY_BY,
    fetchGlobalBannerTargetsKeyBy
  );
export const setFetchGlobalBannerTargetsPage = (fetchGlobalBannerTargetsPage) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_PAGE,
    fetchGlobalBannerTargetsPage
  );
export const setFetchGlobalBannerTargetsPerPage = (
  fetchGlobalBannerTargetsPerPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_PER_PAGE,
    fetchGlobalBannerTargetsPerPage
  );
export const setFetchGlobalBannerTargetsIncludes = (
  fetchGlobalBannerTargetsIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_INCLUDES,
    fetchGlobalBannerTargetsIncludes
  );
export const setFetchGlobalBannerTargetsFilterRole = (
  fetchGlobalBannerTargetsFilterRole
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_FILTER_ROLE,
    fetchGlobalBannerTargetsFilterRole
  );
export const setFetchGlobalBannerTargetsLoading = (
  fetchGlobalBannerTargetsLoading
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_LOADING,
    fetchGlobalBannerTargetsLoading
  );
export const setFetchGlobalBannerTargetsSuccess = (
  fetchGlobalBannerTargetsSuccess
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_SUCCESS,
    fetchGlobalBannerTargetsSuccess
  );
export const setFetchGlobalBannerTargetsFailed = (
  fetchGlobalBannerTargetsFailed
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_BANNER_TARGETS_FAILED,
    fetchGlobalBannerTargetsFailed
  );

export const setIsFetchInitializeHitted = (isFetchInitializeHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_INITIALIZE_HITTED,
    isFetchInitializeHitted
  );
export const setIsFetchMasterPermissionHitted = (
  isFetchMasterPermissionHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_MASTER_PERMISSION_HITTED,
    isFetchMasterPermissionHitted
  );
export const setIsFetchSecondPermissionHitted = (
  isFetchSecondPermissionHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_SECOND_PERMISSION_HITTED,
    isFetchSecondPermissionHitted
  );
export const setIsFetchCurrentMarketHitted = (isFetchCurrentMarketHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MARKET_HITTED,
    isFetchCurrentMarketHitted
  );
export const setIsFetchCurrentBranchHitted = (isFetchCurrentBranchHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_BRANCH_HITTED,
    isFetchCurrentBranchHitted
  );
export const setIsFetchCurrentMerchantHitted = (isFetchCurrentMerchantHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MERCHANT_HITTED,
    isFetchCurrentMerchantHitted
  );
export const setIsFetchCurrentSchedulesHitted = (
  isFetchCurrentSchedulesHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_SCHEDULES_HITTED,
    isFetchCurrentSchedulesHitted
  );
export const setIsFetchTodayAttendanceHitted = (isFetchTodayAttendanceHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_TODAY_ATTENDANCE_HITTED,
    isFetchTodayAttendanceHitted
  );
export const setIsFetchGlobalSubscriptionsHitted = (
  isFetchGlobalSubscriptionsHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_SUBSCRIPTIONS_HITTED,
    isFetchGlobalSubscriptionsHitted
  );
export const setIsFetchGlobalFeatureSubscriptionsHitted = (
  isFetchGlobalFeatureSubscriptionsHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_HITTED,
    isFetchGlobalFeatureSubscriptionsHitted
  );
export const setIsFetchGlobalFeatureSystemsHitted = (
  isFetchGlobalFeatureSystemsHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_FEATURE_SYSTEMS_HITTED,
    isFetchGlobalFeatureSystemsHitted
  );
export const setIsFetchGlobalRestrictionNoticesHitted = (
  isFetchGlobalRestrictionNoticesHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_RESTRICTION_NOTICES_HITTED,
    isFetchGlobalRestrictionNoticesHitted
  );
export const setIsFetchGlobalBannersHitted = (isFetchGlobalBannersHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_BANNERS_HITTED,
    isFetchGlobalBannersHitted
  );
export const setIsFetchGlobalBannerTargetsHitted = (
  isFetchGlobalBannerTargetsHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_BANNER_TARGETS_HITTED,
    isFetchGlobalBannerTargetsHitted
  );

export const fetchInitializeStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_INITIALIZE_START);
export const fetchMasterPermissionStart = (role, permissionId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_MASTER_PERMISSION_START, {
    role,
    permissionId,
  });
export const fetchSecondPermissionStart = (role, permissionId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_SECOND_PERMISSION_START, {
    role,
    permissionId,
  });
export const fetchCurrentMarketStart = (marketId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_MARKET_START, marketId);
export const fetchCurrentBranchStart = (branchId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_BRANCH_START, branchId);
export const fetchCurrentMerchantStart = (merchantId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_MERCHANT_START, merchantId);
export const fetchCurrentSchedulesStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_SCHEDULES_START);
export const fetchTodayAttendanceStart = (userId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_TODAY_ATTENDANCE_START, userId);
export const fetchGlobalSubscriptionsStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_GLOBAL_SUBSCRIPTIONS_START);
export const fetchGlobalFeatureSubscriptionsStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_START);
export const fetchGlobalFeatureSystemsStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_GLOBAL_FEATURE_SYSTEMS_START);
export const fetchGlobalRestrictionNoticesStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_GLOBAL_RESTRICTION_NOTICES_START);
export const fetchGlobalBannersStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_GLOBAL_BANNERS_START);
export const fetchGlobalBannerTargetsStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_GLOBAL_BANNER_TARGETS_START);

export const resetGlobalReducer = () =>
  createAction(GLOBAL_ACTION_TYPES.RESET_GLOBAL_REDUCER);
