import styled, { css } from "styled-components";

import {
  BORDER_RADIUS,
  COLORS,
  FONT_SIZES,
  FONT_WEIGHTS,
} from "../../utils/styles.utils";

import { BUTTON_COLORS, BUTTON_SIZES } from "./button.component";

const buttonPrefixSuffixStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonPrefix = styled.div`
  ${buttonPrefixSuffixStyles}
`;

export const ButtonSuffix = styled.div`
  ${buttonPrefixSuffixStyles}
`;

export const ButtonLoading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  ${({ isHide }) =>
    isHide &&
    css`
      opacity: 0;
    `}
`;

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  ${({ isHide }) =>
    isHide &&
    css`
      opacity: 0;
    `}
`;

export const BaseButton = styled.button`
  user-select: none;
  cursor: pointer;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: ${BORDER_RADIUS.NORMAL};
  background-color: ${COLORS.WHITE};
  transition: all 0.3s;

  font-size: ${FONT_SIZES.PARAGRAPH_1};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
  text-align: center;

  &:disabled {
    opacity: 0.75;
  }

  &:hover {
    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.PRIMARY &&
      css`
        background-color: ${COLORS.PRIMARY};
        color: ${COLORS.WHITE};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.WHITE};
            }
            &[stroke] {
              stroke: ${COLORS.WHITE};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.SUCCESS &&
      css`
        background-color: ${COLORS.SUCCESS};
        color: ${COLORS.WHITE};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.WHITE};
            }
            &[stroke] {
              stroke: ${COLORS.WHITE};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.DANGER &&
      css`
        background-color: ${COLORS.DANGER};
        color: ${COLORS.WHITE};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.WHITE};
            }
            &[stroke] {
              stroke: ${COLORS.WHITE};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.WARNING &&
      css`
        background-color: ${COLORS.WARNING};
        color: ${COLORS.WHITE};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.WHITE};
            }
            &[stroke] {
              stroke: ${COLORS.WHITE};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.SECONDARY &&
      css`
        background-color: ${COLORS.SECONDARY};
        color: ${COLORS.DARK};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.DARK};
            }
            &[stroke] {
              stroke: ${COLORS.DARK};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.WHITE &&
      css`
        background-color: ${COLORS.WHITE};
        color: ${COLORS.DARK};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.DARK};
            }
            &[stroke] {
              stroke: ${COLORS.DARK};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.LIGHT &&
      css`
        background-color: ${COLORS.LIGHT};
        color: ${COLORS.SEMI_DARK};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.SEMI_DARK};
            }
            &[stroke] {
              stroke: ${COLORS.SEMI_DARK};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.DARK &&
      css`
        background-color: ${COLORS.DARK};
        color: ${COLORS.WHITE};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.WHITE};
            }
            &[stroke] {
              stroke: ${COLORS.WHITE};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.LIGHT_PRIMARY &&
      css`
        background-color: ${COLORS.LIGHT_PRIMARY};
        color: ${COLORS.PRIMARY};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.PRIMARY};
            }
            &[stroke] {
              stroke: ${COLORS.PRIMARY};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.LIGHT_SUCCESS &&
      css`
        background-color: ${COLORS.LIGHT_SUCCESS};
        color: ${COLORS.SUCCESS};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.SUCCESS};
            }
            &[stroke] {
              stroke: ${COLORS.SUCCESS};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.LIGHT_DANGER &&
      css`
        background-color: ${COLORS.LIGHT_DANGER};
        color: ${COLORS.DANGER};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.DANGER};
            }
            &[stroke] {
              stroke: ${COLORS.DANGER};
            }
          }
        }
      `}

    ${({ buttonHoverColor }) =>
      buttonHoverColor === BUTTON_COLORS.LIGHT_WARNING &&
      css`
        background-color: ${COLORS.LIGHT_WARNING};
        color: ${COLORS.WARNING};

        svg {
          path,
          polyline,
          line,
          circle {
            &[fill] {
              fill: ${COLORS.WARNING};
            }
            &[stroke] {
              stroke: ${COLORS.WARNING};
            }
          }
        }
      `}
  }

  ${ButtonContent} {
    ${({ isBetween }) =>
      isBetween &&
      css`
        justify-content: space-between;
      `}

    ${({ isNoWrap }) =>
      isNoWrap &&
      css`
        white-space: nowrap;
      `}
  }

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}

  ${({ isCircle }) =>
    isCircle &&
    css`
      border-radius: ${BORDER_RADIUS.CIRCLE} !important;
    `}

  ${({ isRoundedSm, isLeftNotRounded, isRightNotRounded, isRightToLeft }) =>
    isRoundedSm &&
    ((isLeftNotRounded && !isRightToLeft) ||
    (isRightNotRounded && isRightToLeft)
      ? css`
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-top-right-radius: ${BORDER_RADIUS.SMALL} !important;
          border-bottom-right-radius: ${BORDER_RADIUS.SMALL} !important;
        `
      : (isRightNotRounded && !isRightToLeft) ||
        (isLeftNotRounded && isRightToLeft)
      ? css`
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-top-left-radius: ${BORDER_RADIUS.SMALL} !important;
          border-bottom-left-radius: ${BORDER_RADIUS.SMALL} !important;
        `
      : css`
          border-radius: ${BORDER_RADIUS.SMALL} !important;
        `)}

  ${({ isRoundedMd, isLeftNotRounded, isRightNotRounded, isRightToLeft }) =>
    isRoundedMd &&
    ((isLeftNotRounded && !isRightToLeft) ||
    (isRightNotRounded && isRightToLeft)
      ? css`
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-top-right-radius: ${BORDER_RADIUS.MEDIUM} !important;
          border-bottom-right-radius: ${BORDER_RADIUS.MEDIUM} !important;
        `
      : (isRightNotRounded && !isRightToLeft) ||
        (isLeftNotRounded && isRightToLeft)
      ? css`
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-top-left-radius: ${BORDER_RADIUS.MEDIUM} !important;
          border-bottom-left-radius: ${BORDER_RADIUS.MEDIUM} !important;
        `
      : css`
          border-radius: ${BORDER_RADIUS.MEDIUM} !important;
        `)}

  ${({ isRoundedLg, isLeftNotRounded, isRightNotRounded, isRightToLeft }) =>
    isRoundedLg &&
    ((isLeftNotRounded && !isRightToLeft) ||
    (isRightNotRounded && isRightToLeft)
      ? css`
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-top-right-radius: ${BORDER_RADIUS.LARGE} !important;
          border-bottom-right-radius: ${BORDER_RADIUS.LARGE} !important;
        `
      : (isRightNotRounded && !isRightToLeft) ||
        (isLeftNotRounded && isRightToLeft)
      ? css`
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-top-left-radius: ${BORDER_RADIUS.LARGE} !important;
          border-bottom-left-radius: ${BORDER_RADIUS.LARGE} !important;
        `
      : css`
          border-radius: ${BORDER_RADIUS.LARGE} !important;
        `)}

  ${({ isLeftNotRounded, isRightNotRounded, isRightToLeft }) =>
    ((isLeftNotRounded && !isRightToLeft) ||
      (isRightNotRounded && isRightToLeft)) &&
    css`
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    `}
  
  ${({ isRightNotRounded, isLeftNotRounded, isRightToLeft }) =>
    ((isRightNotRounded && !isRightToLeft) ||
      (isLeftNotRounded && isRightToLeft)) &&
    css`
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.PRIMARY &&
    css`
      background-color: ${COLORS.PRIMARY};
      color: ${COLORS.WHITE};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.WHITE};
          }
          &[stroke] {
            stroke: ${COLORS.WHITE};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.PRIMARY_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.SUCCESS &&
    css`
      background-color: ${COLORS.SUCCESS};
      color: ${COLORS.WHITE};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.WHITE};
          }
          &[stroke] {
            stroke: ${COLORS.WHITE};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.SUCCESS_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.DANGER &&
    css`
      background-color: ${COLORS.DANGER};
      color: ${COLORS.WHITE};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.WHITE};
          }
          &[stroke] {
            stroke: ${COLORS.WHITE};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.DANGER_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.WARNING &&
    css`
      background-color: ${COLORS.WARNING};
      color: ${COLORS.WHITE};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.WHITE};
          }
          &[stroke] {
            stroke: ${COLORS.WHITE};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.WARNING_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.SECONDARY &&
    css`
      background-color: ${COLORS.SECONDARY};
      color: ${COLORS.DARK};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.DARK};
          }
          &[stroke] {
            stroke: ${COLORS.DARK};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.SECONDARY_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.WHITE &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.DARK};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.DARK};
          }
          &[stroke] {
            stroke: ${COLORS.DARK};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.WHITE_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.LIGHT &&
    css`
      background-color: ${COLORS.LIGHT};
      color: ${COLORS.SEMI_DARK};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.SEMI_DARK};
          }
          &[stroke] {
            stroke: ${COLORS.SEMI_DARK};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.LIGHT_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.DARK &&
    css`
      background-color: ${COLORS.DARK};
      color: ${COLORS.WHITE};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.WHITE};
          }
          &[stroke] {
            stroke: ${COLORS.WHITE};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.DARK_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.LIGHT_PRIMARY &&
    css`
      background-color: ${COLORS.LIGHT_PRIMARY};
      color: ${COLORS.PRIMARY};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.PRIMARY};
          }
          &[stroke] {
            stroke: ${COLORS.PRIMARY};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.LIGHT_PRIMARY_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.LIGHT_SUCCESS &&
    css`
      background-color: ${COLORS.LIGHT_SUCCESS};
      color: ${COLORS.SUCCESS};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.SUCCESS};
          }
          &[stroke] {
            stroke: ${COLORS.SUCCESS};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.LIGHT_SUCCESS_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.LIGHT_DANGER &&
    css`
      background-color: ${COLORS.LIGHT_DANGER};
      color: ${COLORS.DANGER};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.DANGER};
          }
          &[stroke] {
            stroke: ${COLORS.DANGER};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.LIGHT_DANGER_HOVER};
      }
    `}

  ${({ buttonColor }) =>
    buttonColor === BUTTON_COLORS.LIGHT_WARNING &&
    css`
      background-color: ${COLORS.LIGHT_WARNING};
      color: ${COLORS.WARNING};

      svg {
        path,
        polyline,
        line,
        circle {
          &[fill] {
            fill: ${COLORS.WARNING};
          }
          &[stroke] {
            stroke: ${COLORS.WARNING};
          }
        }
      }

      &:hover {
        background-color: ${COLORS.LIGHT_WARNING_HOVER};
      }
    `}
`;

export const IconButton = styled(BaseButton)`
  height: 3rem;
  min-height: 3rem;
  max-height: 3rem;

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;
    margin: 0 !important;

    &[stroke-width] {
      stroke-width: 2;
    }
  }

  ${({ isFullWidth }) =>
    !isFullWidth &&
    css`
      width: 3rem;
      min-width: 3rem;
      max-width: 3rem;
    `}

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZES.PX32 &&
    css`
      width: 2rem;
      min-width: 2rem;
      max-width: 2rem;
      height: 2rem;
      min-height: 2rem;
      max-height: 2rem;
      border-radius: ${BORDER_RADIUS.EXTRA_SMALL};

      svg {
        width: 1rem;
        min-width: 1rem;
        max-width: 1rem;
        height: 1rem;
        min-height: 1rem;
        max-height: 1rem;
      }
    `}

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZES.PX40 &&
    css`
      width: 2.5rem;
      min-width: 2.5rem;
      max-width: 2.5rem;
      height: 2.5rem;
      min-height: 2.5rem;
      max-height: 2.5rem;
      border-radius: ${BORDER_RADIUS.SMALL};

      svg {
        width: 1.25rem;
        min-width: 1.25rem;
        max-width: 1.25rem;
        height: 1.25rem;
        min-height: 1.25rem;
        max-height: 1.25rem;
      }
    `}

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZES.PX48 &&
    css`
      width: 3rem;
      min-width: 3rem;
      max-width: 3rem;
      height: 3rem;
      min-height: 3rem;
      max-height: 3rem;

      svg {
        width: 1.75rem;
        min-width: 1.75rem;
        max-width: 1.75rem;
        height: 1.75rem;
        min-height: 1.75rem;
        max-height: 1.75rem;
      }
    `}
`;

export const TextButton = styled(BaseButton)`
  height: 3rem;
  padding: 0 1.5rem;

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZES.SMALL &&
    css`
      height: 2.5rem;
      padding: 0 1.25rem;
      font-size: ${FONT_SIZES.PARAGRAPH_2};
    `}
`;

export const TextIconButton = styled(BaseButton)`
  height: 3rem;
  padding: 0 1.5rem;
  border-radius: ${BORDER_RADIUS.NORMAL};
  white-space: nowrap;

  ${({ buttonSize }) =>
    buttonSize === BUTTON_SIZES.SMALL &&
    css`
      height: 2.5rem;
      padding: 0 1.25rem;
      font-size: ${FONT_SIZES.PARAGRAPH_2};
      border-radius: 0.875rem;
    `}

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;

    &[stroke-width] {
      stroke-width: 2;
    }

    ${({ buttonSize }) =>
      buttonSize === BUTTON_SIZES.SMALL &&
      css`
        width: 1.25rem;
        min-width: 1.25rem;
        max-width: 1.25rem;
        height: 1.25rem;
        min-height: 1.25rem;
        max-height: 1.25rem;
      `}
  }

  ${ButtonContent} {
    column-gap: 0.65rem;
  }
  ${ButtonPrefix} {
    margin-left: ${({ isRightToLeft }) => (!isRightToLeft ? "-0.25rem" : 0)};
    margin-right: ${({ isRightToLeft }) => (isRightToLeft ? "-0.25rem" : 0)};
  }
  ${ButtonSuffix} {
    margin-left: ${({ isRightToLeft }) => (isRightToLeft ? "-0.25rem" : 0)};
    margin-right: ${({ isRightToLeft }) => (!isRightToLeft ? "-0.25rem" : 0)};
  }
`;
