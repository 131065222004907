import { createAction } from "../../utils/store.utils";

import SELECT_SUBSCRIBER_ACTION_TYPES from "./select-subscriber.type";

export const setSubscribers = (subscribers) =>
  createAction(SELECT_SUBSCRIBER_ACTION_TYPES.SET_SUBSCRIBERS, subscribers);
export const setSearchSubscribers = (searchSubscribers) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS,
    searchSubscribers
  );

export const appendSubscribers = (subscribers) =>
  createAction(SELECT_SUBSCRIBER_ACTION_TYPES.APPEND_SUBSCRIBERS, subscribers);
export const appendSearchSubscribers = (searchSubscribers) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.APPEND_SEARCH_SUBSCRIBERS,
    searchSubscribers
  );

export const setIsSubscribersHasMore = (isSubscribersHasMore) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_SUBSCRIBERS_HAS_MORE,
    isSubscribersHasMore
  );
export const setIsSearchSubscribersHasMore = (isSearchSubscribersHasMore) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_SEARCH_SUBSCRIBERS_HAS_MORE,
    isSearchSubscribersHasMore
  );

export const setFetchSubscribersParams = (fetchSubscribersParams) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_PARAMS,
    fetchSubscribersParams
  );
export const setFetchSubscribersLoading = (fetchSubscribersLoading) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_LOADING,
    fetchSubscribersLoading
  );
export const setFetchSubscribersSuccess = (fetchSubscribersSuccess) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_SUCCESS,
    fetchSubscribersSuccess
  );
export const setFetchSubscribersFailed = (fetchSubscribersFailed) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_FETCH_SUBSCRIBERS_FAILED,
    fetchSubscribersFailed
  );

export const setSearchSubscribersParams = (searchSubscribersParams) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS_PARAMS,
    searchSubscribersParams
  );
export const setSearchSubscribersLoading = (searchSubscribersLoading) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS_LOADING,
    searchSubscribersLoading
  );
export const setSearchSubscribersSuccess = (searchSubscribersSuccess) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS_SUCCESS,
    searchSubscribersSuccess
  );
export const setSearchSubscribersFailed = (searchSubscribersFailed) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_SEARCH_SUBSCRIBERS_FAILED,
    searchSubscribersFailed
  );

export const setAppendSubscribersParams = (appendSubscribersParams) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SUBSCRIBERS_PARAMS,
    appendSubscribersParams
  );
export const setAppendSubscribersLoading = (appendSubscribersLoading) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SUBSCRIBERS_LOADING,
    appendSubscribersLoading
  );
export const setAppendSubscribersSuccess = (appendSubscribersSuccess) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SUBSCRIBERS_SUCCESS,
    appendSubscribersSuccess
  );
export const setAppendSubscribersFailed = (appendSubscribersFailed) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_APPEND_SUBSCRIBERS_FAILED,
    appendSubscribersFailed
  );

export const setIsFetchSubscribersHitted = (isFetchSubscribersHitted) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_SUBSCRIBERS_HITTED,
    isFetchSubscribersHitted
  );
export const setIsSearchSubscribersHitted = (isSearchSubscribersHitted) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_SEARCH_SUBSCRIBERS_HITTED,
    isSearchSubscribersHitted
  );
export const setIsAppendSubscribersHitted = (isAppendSubscribersHitted) =>
  createAction(
    SELECT_SUBSCRIBER_ACTION_TYPES.SET_IS_APPEND_SUBSCRIBERS_HITTED,
    isAppendSubscribersHitted
  );

export const fetchSubscribersStart = () =>
  createAction(SELECT_SUBSCRIBER_ACTION_TYPES.FETCH_SUBSCRIBERS_START);
export const searchSubscribersStart = () =>
  createAction(SELECT_SUBSCRIBER_ACTION_TYPES.SEARCH_SUBSCRIBERS_START);
export const appendSubscribersStart = () =>
  createAction(SELECT_SUBSCRIBER_ACTION_TYPES.APPEND_SUBSCRIBERS_START);

export const resetSubscriberReducer = () =>
  createAction(SELECT_SUBSCRIBER_ACTION_TYPES.RESET_SELECT_SUBSCRIBER_REDUCER);
