import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getProducts } from "../../api/product.api";

import SELECT_PRODUCT_ACTION_TYPES from "./select-product.type";
import {
  appendProducts,
  appendSearchProducts,
  setAppendProductsFailed,
  setAppendProductsLoading,
  setAppendProductsSuccess,
  setFetchProductsFailed,
  setFetchProductsLoading,
  setFetchProductsSuccess,
  setIsAppendProductsHitted,
  setIsFetchProductsHitted,
  setIsProductsHasMore,
  setIsSearchProductsHasMore,
  setIsSearchProductsHitted,
  setProducts,
  setSearchProducts,
  setSearchProductsFailed,
  setSearchProductsLoading,
  setSearchProductsSuccess,
} from "./select-product.action";
import {
  getAppendProductsParams,
  getFetchProductsParams,
  getSearchProductsParams,
} from "./select-product.selector";

export function* _getFetchProducts() {
  try {
    const fetchProductsParams = yield select(getFetchProductsParams);
    const parameters = getParamsWithDefault(fetchProductsParams);

    yield put(setFetchProductsLoading(true));

    const {
      meta: { message },
      data: products,
    } = yield call(getProducts, parameters);

    yield put(setIsFetchProductsHitted(true));
    yield put(setIsProductsHasMore(Object.keys(products).length > 0));

    if (parameters.page > 1) {
      yield put(appendProducts(products));
    } else {
      yield put(setProducts(products));
    }

    yield put(setFetchProductsSuccess(message));
    yield put(setFetchProductsLoading(false));
  } catch (error) {
    yield put(setFetchProductsFailed(error));
    yield put(setFetchProductsLoading(false));
  }
}
export function* _getSearchProducts() {
  try {
    const searchProductsParams = yield select(getSearchProductsParams);
    const parameters = getParamsWithDefault(searchProductsParams);

    yield put(setSearchProductsLoading(true));

    const {
      meta: { message },
      data: products,
    } = yield call(getProducts, parameters);

    yield put(setIsSearchProductsHitted(true));
    yield put(setIsSearchProductsHasMore(Object.keys(products).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchProducts(products));
    } else {
      yield put(setSearchProducts(products));
    }

    yield put(setSearchProductsSuccess(message));
    yield put(setSearchProductsLoading(false));
  } catch (error) {
    yield put(setSearchProductsFailed(error));
    yield put(setSearchProductsLoading(false));
  }
}
export function* _getAppendProducts() {
  try {
    const appendProductsParams = yield select(getAppendProductsParams);
    const parameters = getParamsWithDefault(appendProductsParams);

    yield put(setAppendProductsLoading(true));

    const {
      meta: { message },
      data: products,
    } = yield call(getProducts, parameters);

    yield put(setIsAppendProductsHitted(true));
    yield put(appendProducts(products));

    yield put(setAppendProductsSuccess(message));
    yield put(setAppendProductsLoading(false));
  } catch (error) {
    yield put(setAppendProductsFailed(error));
    yield put(setAppendProductsLoading(false));
  }
}

export function* onFetchProductsStart() {
  yield takeLatest(
    SELECT_PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START,
    _getFetchProducts
  );
}
export function* onSearchProductsStart() {
  yield takeLatest(
    SELECT_PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS_START,
    _getSearchProducts
  );
}
export function* onAppendProductsStart() {
  yield takeLatest(
    SELECT_PRODUCT_ACTION_TYPES.APPEND_PRODUCTS_START,
    _getAppendProducts
  );
}

export function* selectProductSaga() {
  yield all([
    call(onFetchProductsStart),
    call(onSearchProductsStart),
    call(onAppendProductsStart),
  ]);
}
