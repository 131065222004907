import { cloneElement, isValidElement } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { parseToBoolean } from "../../utils/parser.utils";

import { removeCurrentDialog } from "../../store/component/component.action";
import { getCurrentDialogs } from "../../store/component/component.selector";

import {
  ModalDialogBody,
  ModalDialogClose,
  ModalDialogContainer,
  ModalDialogHead,
} from "./modal-dialog.style";

import { Cancel01Icon } from "hugeicons-react";

const ModalDialog = ({ dialogKey, children }) => {
  const dispatch = useDispatch();

  const currentDialogs = useSelector(getCurrentDialogs);

  const handleDialogClose = () => dispatch(removeCurrentDialog(dialogKey));

  if (!parseToBoolean(currentDialogs?.[dialogKey])) return null;

  return createPortal(
    <ModalDialogContainer>
      <ModalDialogHead>
        <ModalDialogClose onClick={handleDialogClose}>
          <Cancel01Icon />
        </ModalDialogClose>
      </ModalDialogHead>
      <ModalDialogBody>
        {isValidElement(children) &&
          cloneElement(children, { handleDialogClose })}
      </ModalDialogBody>
    </ModalDialogContainer>,
    document.body
  );
};

export default ModalDialog;
