import { createAction } from "../../utils/store.utils";

import SELECT_FEATURE_SYSTEM_ACTION_TYPES from "./select-feature-system.type";

export const setFeatureSystems = (featureSystems) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FEATURE_SYSTEMS,
    featureSystems
  );
export const setSearchFeatureSystems = (searchFeatureSystems) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS,
    searchFeatureSystems
  );

export const appendFeatureSystems = (featureSystems) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.APPEND_FEATURE_SYSTEMS,
    featureSystems
  );
export const appendSearchFeatureSystems = (searchFeatureSystems) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.APPEND_SEARCH_FEATURE_SYSTEMS,
    searchFeatureSystems
  );

export const setIsFeatureSystemsHasMore = (isFeatureSystemsHasMore) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FEATURE_SYSTEMS_HAS_MORE,
    isFeatureSystemsHasMore
  );
export const setIsSearchFeatureSystemsHasMore = (
  isSearchFeatureSystemsHasMore
) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_SEARCH_FEATURE_SYSTEMS_HAS_MORE,
    isSearchFeatureSystemsHasMore
  );

export const setFetchFeatureSystemsParams = (fetchFeatureSystemsParams) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_PARAMS,
    fetchFeatureSystemsParams
  );
export const setFetchFeatureSystemsLoading = (fetchFeatureSystemsLoading) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_LOADING,
    fetchFeatureSystemsLoading
  );
export const setFetchFeatureSystemsSuccess = (fetchFeatureSystemsSuccess) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_SUCCESS,
    fetchFeatureSystemsSuccess
  );
export const setFetchFeatureSystemsFailed = (fetchFeatureSystemsFailed) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_FETCH_FEATURE_SYSTEMS_FAILED,
    fetchFeatureSystemsFailed
  );

export const setSearchFeatureSystemsParams = (searchFeatureSystemsParams) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS_PARAMS,
    searchFeatureSystemsParams
  );
export const setSearchFeatureSystemsLoading = (searchFeatureSystemsLoading) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS_LOADING,
    searchFeatureSystemsLoading
  );
export const setSearchFeatureSystemsSuccess = (searchFeatureSystemsSuccess) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS_SUCCESS,
    searchFeatureSystemsSuccess
  );
export const setSearchFeatureSystemsFailed = (searchFeatureSystemsFailed) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_SEARCH_FEATURE_SYSTEMS_FAILED,
    searchFeatureSystemsFailed
  );

export const setAppendFeatureSystemsParams = (appendFeatureSystemsParams) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_APPEND_FEATURE_SYSTEMS_PARAMS,
    appendFeatureSystemsParams
  );
export const setAppendFeatureSystemsLoading = (appendFeatureSystemsLoading) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_APPEND_FEATURE_SYSTEMS_LOADING,
    appendFeatureSystemsLoading
  );
export const setAppendFeatureSystemsSuccess = (appendFeatureSystemsSuccess) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_APPEND_FEATURE_SYSTEMS_SUCCESS,
    appendFeatureSystemsSuccess
  );
export const setAppendFeatureSystemsFailed = (appendFeatureSystemsFailed) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_APPEND_FEATURE_SYSTEMS_FAILED,
    appendFeatureSystemsFailed
  );

export const setIsFetchFeatureSystemsHitted = (isFetchFeatureSystemsHitted) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_FETCH_FEATURE_SYSTEMS_HITTED,
    isFetchFeatureSystemsHitted
  );
export const setIsSearchFeatureSystemsHitted = (isSearchFeatureSystemsHitted) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_SEARCH_FEATURE_SYSTEMS_HITTED,
    isSearchFeatureSystemsHitted
  );
export const setIsAppendFeatureSystemsHitted = (isAppendFeatureSystemsHitted) =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.SET_IS_APPEND_FEATURE_SYSTEMS_HITTED,
    isAppendFeatureSystemsHitted
  );

export const fetchFeatureSystemsStart = () =>
  createAction(SELECT_FEATURE_SYSTEM_ACTION_TYPES.FETCH_FEATURE_SYSTEMS_START);
export const searchFeatureSystemsStart = () =>
  createAction(SELECT_FEATURE_SYSTEM_ACTION_TYPES.SEARCH_FEATURE_SYSTEMS_START);
export const appendFeatureSystemsStart = () =>
  createAction(SELECT_FEATURE_SYSTEM_ACTION_TYPES.APPEND_FEATURE_SYSTEMS_START);

export const resetFeatureSystemReducer = () =>
  createAction(
    SELECT_FEATURE_SYSTEM_ACTION_TYPES.RESET_SELECT_FEATURE_SYSTEM_REDUCER
  );
